import Vue from 'vue'
import { SourceFileDropzone } from "./components/SourceFileDropzone.js";
import { LogoModal } from "../logos/LogoModal.js";
import { ThemeVersionsService } from "../common/api.service.js";
import { store } from '../store/ItemsetsStore.js'
import { ItemSearchFilterPanel } from '../ItemSearchFilterPanel.js';
import { ItemSearchMixin } from "../mixins/ItemSearchMixin.js";
import { ThemePreviewIframe } from "./components/ThemePreviewIframe.js"

let ThemePreview = {
    props: {
        website: String,
        website_id: String,
        preset_image_thumbnail: String,
        filterSchema: Array,
        previewDetails: Object,
        themePreviewStore: Object
    },
    mixins: [ItemSearchMixin],
    data: function () {
        return ({
            store: store(),
            themeName: '',
            pageName: 'themePreview',
            isLoadingPage: true,
            isLoadingAd: false, // Related refresh button,
            isShowConfirmModal: false, // Confirm quitting this page
            isSaving: false,
            isValidatingThemeName: false,
            isDisableThemeNameEdit: false,
            fetchItemCounts: 0, // To handle when first loaded with itemCount != 0, ignore itemCount becomes 0 after applied filter
        })
    },
    components: {
        sourceFileDropzone: SourceFileDropzone,
        logoModal: LogoModal,
        itemFilterPanel: ItemSearchFilterPanel,
        themePreviewIframe: ThemePreviewIframe
    },
    computed: {
        dropzoneComponentProps: function () {
            return {
                website: this.website,
                collapsible: false,
                pageName: 'themePreview',
                themeId: this.themeId,
                isShowHelp: false
            }
        },
        button_size: function () {
            // If we have a logo then we get the size props from the image to allow for scaling
            return this.themePreviewStore.getLogo() ? "" : "logo-button-size"
        },
        firstTemplate: function () {
            return this.theme.templates && this.theme.templates[0] ? this.theme.templates[0].html : null;
        },
        isThemeNameValid: function () {
            return Boolean(this.themePreviewStore.getName());
        }
    },
    watch: {
        'themePreviewStore.logo.id'(newValue, oldValue) {
            if (!newValue) return
            this.getThemeById()
        },
        'store.state.filter': {
            handler() {
                this.fetchItems()
            },
            deep: true
        },
    },
    methods: {
        getThemeById() {
            if (!this.themePreviewStore.previewItems.items.length) return
            let logoUrl = this.themePreviewStore.getLogo() ? this.themePreviewStore.getLogo().asset : ''
            let logoId = this.themePreviewStore.getLogo() ? this.themePreviewStore.getLogo().id : ''
            ThemeVersionsService.get(this.themePreviewStore.getId(), {
                item_ids: this.themePreviewStore.getCurrentItemId(),
                website_name: this.website,
                multiple_templates: true,
                dynamic_templates: true,
                logo_url: logoUrl,
                logo_id: logoId,
                headline: this.themePreviewStore.getCustomFieldValueByKey('headline'),
                call_to_action: this.themePreviewStore.getCustomFieldValueByKey('call_to_action'),
                is_temporary: true,
                default_custom_fields: true,
            }).then(response => {
                // Redirect back to theme list if not a temporary theme anymore to prevent save the same theme twice
                if(!response.id || response.temporary === false) this.$router.push({ name: 'theme-versions-list' })

                // Lock theme name input if not adding a new theme
                if(response.number !== 1) this.isDisableThemeNameEdit = true
                this.themePreviewStore.updateTheme(response)
            })
        },
        closeThemePreview() {
            ThemeVersionsService.destroy(this.themePreviewStore.getId())
            .then(response => {
                this.$router.push({ name: 'theme-versions-list' })
            })
        },
        goToImportItem() {
            ThemeVersionsService.destroy(this.themePreviewStore.getId())
            .then(response => {
                const baseUrl = window.location.origin;
                window.location.href = baseUrl + "/" + this.website + '/settings#/data-source/'
            })
        },
        uploadSourceFileCallback(response) {
            this.themePreviewStore.updateSourceFile(response.source_file)
        },
        forceRefresh() {
            this.themePreviewStore.updateCurrentItemIdx();
            this.$nextTick(() => this.getThemeById())
        },
        /** Filter items related */
        fetchItems: function () {
            // Use the mixin for the basic fetch, then handle pinned items
            ItemSearchMixin.methods.fetchItems.apply(this)
                .then(this.fetchItemsCallback)
                .catch(this.handleNotFoundWithPageFilter)
        },
        fetchItemsCallback(response) {
            this.fetchItemCounts++;
            this.isLoadingPage = false
            this.themePreviewStore.updatePreviewItemsByKey('items', this.items)
            this.themePreviewStore.updatePreviewItemsByKey('itemCount', this.itemCount)
            this.themePreviewStore.updatePreviewItemsByKey('totalPages', this.totalPages)
            // Wait for store update
            this.$nextTick(() => this.getThemeById())
        },
        handleNotFoundWithPageFilter(error) {
            // Handle cases where we're using a page offset and we change the filter so that we have fewer pages
            if (error?.response?.status === 404 && this.store.state.filter.page > 1) {
                // Go back to the first page
                this.store.setPageFilter(1);
            }
        },
        saveTheme() {
            this.isSaving = this.isValidatingThemeName = true
            let themeName = this.themePreviewStore.getName()
            if(!this.isDisableThemeNameEdit) {
                if(!this.isThemeNameValid) {
                    this.isSaving = false
                    return
                }
            }

            ThemeVersionsService.publishThemeVersion(this.themePreviewStore.getId(), {name : themeName, website_id: this.website_id})
            .then(response => {
                this.isSaving = false
                this.$router.push({ name: 'version-details', params: { themeId: response.data.theme_id, versionId: response.data.id } })
            })
        },
    },
    mounted() {
        this.themePreviewStore.init(this.website_id)
        this.fetchItems()
        this.themePreviewStore.updateId(this.$route.params.id ? this.$route.params.id : '')
    },
    template: `
    <div class="theme-preview-container bg-white position-fixed m-0 px-0 py-4">
        <div v-if="!itemCount && !isLoadingPage && fetchItemCounts === 1">
            <b-modal visible hide-footer hide-header no-close-on-esc no-close-on-backdrop hide-header-close centered body-class="p-5">
                <h5 class="text-center mb-5">You do not have any items to proceed. Would you like to import items?</h5>
                <b-button class="mt-3" variant="primary" block @click="goToImportItem">Import item</b-button>
                <b-button class="mt-3" variant="secondary" block @click="closeThemePreview">Leave</b-button>
            </b-modal>
        </div>
        <div class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white" :class="isSaving ? 'show' : 'hide'">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <div class="container-fluid h-100">
            <div class="row align-items-baseline w-100 px-5 page-header">
                <button type="button" v-b-modal="'confirmQuit'" class="btn-close h-100 border-0 bg-white pr-4" aria-label="Close">
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>
                <b-modal id="confirmQuit" ref="confirmQuit" hide-footer title="Are you sure?">
                    <div class="d-block text-center">
                        <p>Unsaved theme will be lost</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click="closeThemePreview">Quit Anyway</button>
                        <button type="button" class="btn btn-primary" @click="$bvModal.hide('confirmQuit')">Stay</button>
                    </div>
                </b-modal>
                <h4>Create theme</h4>
            </div>
            <hr class="my-0 w-100">

            <div class="row page-content">
                <div class="settings-container col-4 bg-white py-4">
                    <div class="row theme-name-section pr-5 mb-5">
                        <h5 class="m-0">Theme name</h5>
                        <hr class="w-100">
                        <b-form-input
                        placeholder="Theme name"
                        class="form-control editable-name-control h1 mb-0 eggplant-color"
                        v-model="themePreviewStore.name"
                        :disabled="isDisableThemeNameEdit"
                        />
                        <b-form-invalid-feedback :state="isValidatingThemeName ? isThemeNameValid : null" class="mt-0">Invalid theme name.</b-form-invalid-feedback>
                    </div>

                    <div class="row source-file-section pr-5 mb-5">
                        <h5 class="m-0">Source file</h5>
                        <b-button v-b-tooltip.hover title="The original file you created in Google Web Designer. It includes all the design elements and layout." class="bg-transparent border-0 p-0 ml-2">
                            <font-awesome-icon class="text-dark fa-lg" :icon="['fas', 'circle-question']" size="lg" />
                        </b-button>
                        <hr class="w-100">
                        <a v-if="themePreviewStore.getFile('source')" class="mb-2" :href="themePreviewStore.getFile('source').url">{{ themePreviewStore.getFile('source').name }}</a>
                        <sourceFileDropzone
                        v-bind="{...dropzoneComponentProps}"
                        :themeVersionId="themePreviewStore.getId()"
                        v-on:uploadSourceFileCallback="uploadSourceFileCallback"
                        />
                    </div>
                    <div class="row preview-setting-section pr-5 mb-5">
                        <h5 class="m-0">Preview</h5>
                        <hr class="w-100">
                        <div class="col">
                            <div class="row logo-section mb-2">
                                <h6 class="col-12 p-0">Logo</h6>
                                <b-button class="logo-upload-button w-75" :class="['icon-button', button_size]" variant="light" v-b-modal.modal-logo>
                                    <img v-if="themePreviewStore.getLogo()" :src="themePreviewStore.getLogo().asset" alt="logo image" height="100" width="200" class="img-fluid">
                                    <font-awesome-icon v-else :icon="['fas', 'image']" />
                                </b-button>
                                <logoModal :website_id="website_id" :preset_image_thumbnail="preset_image_thumbnail" :website="website" :store="themePreviewStore"></logoModal>
                            </div>
                            <div class="row filter-section mt-5">
                                <h6 class="col-12 p-0">Items filter</h6>
                                <p>Showing <b>{{ themePreviewStore.getPreviewItemsLength() }}</b> items</p>
                                <div class="item-filter-panel-container">
                                    <itemFilterPanel
                                    :filterSchema="filterSchema"
                                    :store="store"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="preview-container col-8 p-0 pt-3">
                    <h5 class="m-0 pl-4 d-inline-block">Theme Preview</h5>
                    <button type="button" class="btn refresh-button p-0 mb-2 ml-3" @click="forceRefresh()" :disabled="isLoadingAd || !themePreviewStore.getPreviewItemsLength()">
                        <font-awesome-icon class="eggplant-color" :icon="['fas', 'arrows-rotate']" />
                    </button>

                    <div class="theme_iframe-wrapper p-2 pr-5 pl-4">
                            <themePreviewIframe v-for="template in themePreviewStore.templates"
                            :key="template.size" :template="template" />
                    </div>
                </div>
            </div>
        </div>

        <div class="save-btn-container col-4 footer position-fixed border w-100 d-flex justify-content-center bg-white">
            <button class="btn btn-primary my-3 w-50 mx-1" v-on:click="saveTheme()" :disabled="isSaving">Save</button>
            <button type="button" v-b-modal="'confirmQuit'" class="btn-close btn btn-secondary my-3 w-50 mx-1" aria-label="Close">Cancel</button>
        </div>
    </div>
    `
}

Vue.filter("capitalize", function (input = "") {
    let text = input.toString();
    return text.charAt(0).toUpperCase() + text.slice(1);
});

export { ThemePreview }
