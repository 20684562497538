import {ItemService} from "../common/api.service.js";

const ItemSearchMixin = {
    data: function () {
        return {
            items: null,
            itemCount: 0,
            totalPages: 0
        }
    },
    methods: {
        fetchItems: function () {
            return ItemService.init(this.website).query(this.store.getQueryParams()).then(
                response => this.handleItemsResponse.apply(this, [response])
            );
        },
        handleItemsResponse: function (response) {
            this.items = response.results;
            this.totalPages = response.total_pages;
            this.itemCount = response.count;
            return response;
        }
    }
};
export {ItemSearchMixin};