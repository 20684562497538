const getEmptyFilter = () => {
    return {
        search: undefined,
        attributes: [],
        price__gte: undefined,
        price__lte: undefined,
        year__gte: undefined,
        year__lte: undefined,
        start_time__gte: undefined,
        end_time__lte: undefined,
        latitude__gte: undefined,
        latitude__lte: undefined,
        longitude__gte: undefined,
        longitude__lte: undefined,
        item_ids: undefined,
        page: 1,
        page_size: 24,
        itemset_id: undefined,
        item_count: undefined
    }
};

const store = () => { return {
    state: {
        filter: getEmptyFilter(),
        itemset: null,
    },

    getQueryParams() {
        const filter = this.state.filter;
        let params = {};

        if (filter.search) {
            params['search'] = filter.search;
        }

        if (filter.price__gte !== undefined && filter.price__gte >= 0) {
            params['price__gte'] = filter.price__gte;
        }

        if (filter.price__lte !== undefined && filter.price__lte >= 0) {
           params['price__lte'] = filter.price__lte;
        }

        if (filter.year__gte !== undefined && filter.year__gte > 0) {
            params['year__gte'] = filter.year__gte;
        }

        if (filter.year__lte !== undefined && filter.year__lte > 0) {
            params['year__lte'] = filter.year__lte;
        }

        if (filter.start_time__gte) {
            params['start_time__gte'] = filter.start_time__gte;
        }
        if (filter.end_time__lte) {
            params['end_time__lte'] = filter.end_time__lte;
        }

        if (filter.latitude__gte !== undefined && filter.latitude__gte >= 0) {
            params['latitude__gte'] = filter.latitude__gte;
        }
        if (filter.latitude__lte !== undefined && filter.latitude__lte >= 0) {
            params['latitude__lte'] = filter.latitude__lte;
        }
        if (filter.longitude__gte !== undefined && filter.longitude__gte >= 0) {
            params['longitude__gte'] = filter.longitude__gte;
        }
        if (filter.longitude__lte !== undefined && filter.longitude__lte >= 0) {
            params['longitude__lte'] = filter.longitude__lte;
        }

        if (filter.item_ids && filter.item_ids.length > 0) {
            params['item_ids'] = filter.item_ids;
            params['separate_item_ids'] = true;
        }

        if (filter.attributes && filter.attributes.length) {
            params['attributes'] = JSON.stringify(filter.attributes);
        }

        // This is a little funny for Learning Sets
        // If we have both an itemset in the state and item_count (the limit) in the filter, we'll put together a
        // special filter for Learning Sets:
        if (this.state.itemset && filter.item_count) {
            params = {
                itemset_id: this.state.itemset.id,
                item_count: filter.item_count
            }
        }

        if (filter.page_size) {
            params['page_size'] = filter.page_size;
        }

        if (filter.page) {
            params['page'] = filter.page
        }

        return params;
    },

    getFilterKeys() {
        let exclude = new Set(['page', 'page_size']);
        let filter_keys = new Set();
        Object.keys(this.state.filter).forEach(item => {
            if (!exclude.has(item)) {
                filter_keys.add(item)
            }
        });
        return filter_keys
    },

    // Including pinned filter
    isAppliedAnyFilters() {
        let filterKeys = this.getFilterKeys();
        let result = false
        Object.keys(this.state.filter).map(filterKey => {
            if (filterKeys.has(filterKey)) {
                if (filterKey === 'attributes') {
                    if (this.state.filter[filterKey].length) {
                        result = true
                    }
                } else {
                    if (this.state.filter[filterKey] !== undefined) {
                        result = true
                    }
                }
            }
        })
        return result
    },

    isOnlyPinnedFilterActive() {
        let filterKeys = this.getFilterKeys();
        filterKeys.delete('item_ids');
        let itemIds = this.state.filter.item_ids;
        let isPinnedFilterActive = (typeof itemIds !== "undefined" && itemIds !== '');
        let hasOtherFiltersActive = Object.keys(this.state.filter).some(item => {
            if (filterKeys.has(item)) {
                let filter_item = this.state.filter[item];
                return filter_item !== undefined && filter_item !== "" && (!Array.isArray(filter_item) || (Array.isArray(filter_item) && filter_item.length))
            }
        });
        return isPinnedFilterActive && !hasOtherFiltersActive
    },

    updateFilter(filter) {
        Object.assign(this.state.filter, getEmptyFilter(), filter);
    },

    _findAttribute: function(key, value) {
        let attributes = this.state.filter.attributes;
        let index = null;
        for (let i = 0; i < attributes.length; i++) {
            if (attributes[i].attribute === key && attributes[i].value === value) {
                index = i;
                break;
            }
        }
        return index;
    },
    addAttributeFilter: function(key, value) {
        let newAttribute = {attribute: key, value: value};
        let index = this._findAttribute(key, value);
        // Only add a new key-value attribute entry if it's not already in the list
        if (index === null) {
            this.state.filter.attributes.push(newAttribute);
        }
    },
    removeAttributeFilter: function (key, value) {
        let index = this._findAttribute(key, value);
        if (index !== null) {
            this.state.filter.attributes.splice(index, 1);
        }
    },
    getSelectedAttributeValues(attribute) {
        const values = new Set();
        this.state.filter.attributes.forEach(attrValue => {
            if (attrValue.attribute === attribute) {
                values.add(attrValue.value);
            }
        });
        return values;
    },

    setSearchFilter(searchValue) {
        let validatedSearch = this._validateString(searchValue);
        this.state.filter.search = validatedSearch ? validatedSearch : undefined;
    },
    clearSearchFilter() {
        this.state.filter.search = undefined;
    },

    setPriceFilter(min, max) {
        for (const [argName, stateKey, value] of [
            ['min', 'price__gte', min],
            ['max', 'price__lte', max]
        ]) {
            this._validateNumber(value, argName);
            this._setFilter(stateKey, value);
        }
    },

    setYearFilter(min, max) {
        for (const [argName, stateKey, value] of [
            ['min', 'year__gte', min],
            ['max', 'year__lte', max]
        ]) {
            this._validateNumber(value, argName);
            this._setFilter(stateKey, value);
        }
    },

    setLatitudeFilter(min, max) {
        for (const [argName, stateKey, value] of [
            ['min', 'latitude__gte', min],
            ['max', 'latitude__lte', max]
        ]) {
            this._validateNumber(value, argName);
            this._setFilter(stateKey, value);
        }
    },

    setLongitudeFilter(min, max) {
        for (const [argName, stateKey, value] of [
            ['min', 'longitude__gte', min],
            ['max', 'longitude__lte', max]
        ]) {
            this._validateNumber(value, argName);
            this._setFilter(stateKey, value);
        }
    },
    setDurationFilter(start, end) {
        for (const [argName, stateKey, value] of [
            ['start', 'start_time__gte', start],
            ['end', 'end_time__lte', end]
        ]) {
            let validatedValue = this._validateString(value, argName);
            this._setFilter(stateKey, validatedValue);
        }
    },
    addItemIdFilter(id){
        const item_ids = this.state.filter.item_ids = (this.state.filter.item_ids || "");
        if(item_ids === "") {
            this.state.filter['item_ids'] += id
        } else if (!item_ids.includes(id)) {
            this.state.filter['item_ids'] += `,${id}`
        }
    },
    removeItemIdFilter(id){
        const item_ids = this.state.filter.item_ids = (this.state.filter.item_ids);
        if(item_ids.includes(id)) {
            let newIds = item_ids.includes(`${id},`) ? item_ids.replace(`${id},`,"") : item_ids.replace(id,"");
            if(newIds.endsWith(",")) {
                newIds = newIds.slice(0, -1)
            }
            this.state.filter['item_ids'] = newIds;
        }
    },
    resetItemIdFilter(ids) {
        this.state.filter['item_ids'] = ids
    },
    getItemIdFilterCount: function() {
        let ids = this.state.filter.item_ids;
        return ids ? ids.split(',').length : 0;
    },

    setPageFilter(page) {
        this._validateNumber(page);
        this._setFilter('page', page);
    },
    setItemLimit(itemCount) {
        this._validateNumber(itemCount);
        this._setFilter('item_count', itemCount);
    },
    updateItemset(itemset) {
        this.state.itemset = itemset;
    },
    _validateString: function (value, argName, allowUndefined = true, emptyIsUndefined = true) {
        if (value === undefined && allowUndefined) {
            return value;
        }
        argName = argName === undefined ? 'Value' : argName;
        // Only allow null or string values
        if (value !== null && typeof value !== "string") {
            throw new Error(`${argName} must be a string`);
        }
        // Empty string or null
        if (!value) {
            if (emptyIsUndefined) {
                // Consider empty string undefined
                value = undefined;
            }
            else {
                throw new Error(`${argName} must not be empty`)
            }
        }
        return value;
    },
    _validateNumber: function (value, argName, allowUndefined = true) {
        if (value === undefined && allowUndefined) {
            return;
        }
        // Only allow number values
        if (typeof value !== "number") {
            argName = argName === undefined ? 'Value' : argName;
            throw new Error(`${argName} must be a number`);
        }
    },
    _setFilter(key, value) {
        this.state.filter[key] = value
    },

    getPageSize() {
        return this.state.filter.page_size
    }

}};

export { store }