// Common component can be reused to get users to confirm when they perform certain tasks (e.g. deleting a collaborator)
let ConfirmationModal = {
    name: "ConfirmationModal",
    data() {
        return {
            localShow: this.show
        }
    },
    methods: {
        confirm() {
            this.$emit('confirmed');
        },
        cancel() {
            this.$emit('cancelled');
        }
    },
    watch: {
        // Update localShow value if props changed
        show(newVal) {
            this.localShow = newVal;
        }
    },
    props: {
        show: Boolean,
        title: String,
        message: String
    },
    template: `
    <b-modal v-model="localShow" class="confirmation-modal" ok-title="Confirm" :title="title" @ok="confirm" @cancel="cancel">
        <div class="d-block">{{ message }}</div>
    </b-modal>
    `,
}

export { ConfirmationModal }

