import { WebsitesService } from '../common/api.service.js'
import { ScrapeData } from "../datasource/ScrapeData.js";
import { ExcludedItemList } from "../datasource/ExcludedItemList.js";
import { RecentInventory } from "../datasource/RecentInventory.js";
import { InventorySchedule } from "../datasource/InventorySchedule.js";
import { usePermissionsStore } from '../store/PermissionStore.js';
import { mapState } from 'pinia';

let DataSource = {
    name: "DataSource",
    props: {
        dataSourceTypeDict: Object,
        // For child components
        intervalSelector: Object,
        scrapeData: Object,
        excludedItemList: Object,
        recentInventory: Object,
        accountId: String,
        // Basic
        websiteName: String,
        hasMetaTesterRole: Boolean,
        staticUrl: String,
    },
    data() {
        return {
            isDataReady: false,
            websiteDetails: {
                importType: null,
                dataSourceParams: null,
                websiteUrl: null
            },
            importTypeInfos: {
                feed: {
                    dataSourceParams: 'feed_url',
                    importedAlertPrefix: "Imported from Feed"
                },
                file:{
                    dataSourceParams: 'import_file',
                    importedAlertPrefix: "Imported from CSV File"
                },
                sheets:{
                    dataSourceParams: 'sheets_url',
                    importedAlertPrefix: "Importing from Google Sheets"
                },
                shopify: {
                    dataSourceParams: 'shop_name',
                    importedAlertPrefix: "Shop"
                },
                spider:{
                    dataSourceParams: '', // spider take the website_url from website details, not from dataSourceParams
                    importedAlertPrefix: "Website URL"
                },
                woocommerce: {
                    dataSourceParams: 'website_url',
                    importedAlertPrefix: "Domain"
                }
            },
            // Matching website's import_type, we should always refer to this enum instead of using the types itself in case the types from backend changed
            importTypesEnum: { 
                feed: 'feed',
                file: 'file',
                sheets: 'sheets',
                shopify: 'shopify',
                spider: 'spider',
                woocommerce: 'woocommerce'
            },
            isShowEditBtn: true,
            permissionsStore : usePermissionsStore(),
        }
    },
    mounted() {
        this.getWebsiteData();
    },
    methods: {
        getWebsiteData() {
            let params = {
                expand: 'datasource_params',
            }

            WebsitesService.get(this.websiteName, params).then(response => {
                const { import_type, website_url, datasource_params } = response;
                this.websiteDetails = {
                    ...this.websiteDetails,
                     importType: import_type,
                     websiteUrl: website_url,
                     dataSourceParams: datasource_params,
                };
                this.isDataReady = true

                if (!this.websiteDetails.importType) {
                  window.location.href = this.getChooseDataSourceUrl();
                }
            })
        },
        getChooseDataSourceUrl(setup=false) {
            if (this.hasMetaTesterRole) {
                return this.getDataSourceSettingPageUrl(this.importTypesEnum.file, setup)
            } else {
                return this.getDataSourceSettingPageUrl(this.importTypesEnum.shopify, setup)
            }
        },
        updateDisplayOfEditBtn(isImporting) {
            this.isShowEditBtn = !isImporting
        },
        getDataSourceSettingPageUrl(importType, setup=false){
            const baseUrl = `./settings/data_source/${importType}`;
            if (setup) {
                // Flag to include some copy to help with initial setup
                return baseUrl + "?setup"
            }
            return baseUrl
        }
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions']),
        editDataSourceUrl(){
            if(!this.websiteDetails.importType) return null
            return `./settings/data_source/${this.websiteDetails.importType}`
        },
        importFromValue() {
            if(!this.websiteDetails.dataSourceParams) return null
            if(this.websiteDetails.importType === this.importTypesEnum.spider) return this.websiteDetails.websiteUrl

            const dataSourceParamsKey =  this.importTypeInfos[this.websiteDetails.importType].dataSourceParams
            const returnValue = this.websiteDetails.dataSourceParams[dataSourceParamsKey]
            if(this.websiteDetails.importType === this.importTypesEnum.shopify) return `https://${returnValue}.myshopify.com`

            return returnValue
        },
        connectShopifyUrl(){
            return `/shopify/${this.websiteName}/app_install`
        },
        isShowShopifyConnectMsg() {
            return (this.websiteDetails.importType === this.importTypesEnum.shopify && !this.websiteDetails.dataSourceParams.access_token) 
        },
        isShowSpiderNotConfiguredMsg() {
            return (this.websiteDetails.importType === this.importTypesEnum.spider && !this.websiteDetails.dataSourceParams.is_configured) 
        },
        isShowInventorySchedule(){
            return this.websiteDetails.dataSourceParams.is_configured && this.websiteDetails.dataSourceParams.can_schedule
        },
        isShowUpgradeWarning(){
            return this.websiteDetails.importType === this.importTypesEnum.spider && this.permissions['tiered_custom_import_start'] === false
        },
        billingPageUrl() {
            return `../account/${this.accountId}/billing`
        },
    },
    components: {
        ScrapeData,
        ExcludedItemList,
        RecentInventory,
        InventorySchedule
    },
    template: `
    <div class="data-source-container mb-5">
        <b-alert :show="isShowUpgradeWarning" variant="warning" class="eggplant-color" >
            <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />     
            Your current plan does not support the custom site walk as a data source. Please <a class="eggplant-color font-weight-bold" :href="billingPageUrl"><u>upgrade</u></a> your plan or <a class="eggplant-color font-weight-bold" :href="getDataSourceSettingPageUrl(websiteDetails.importType)"><u>update</u></a> your data source to continue.
        </b-alert>
        <h5>Data Source</h5>
        <div v-if="!isDataReady" class="spinner-container text-center">
            <b-spinner></b-spinner>
        </div>
        <div v-else class="data-source-content">
            <div class="data-source-summary-container container p-0">
                <div class="row no-gutters justify-content-between">
                    <div class="col-2">
                        <img class="img-thumbnail" :src="staticUrl + 'img/data_source/' + websiteDetails.importType +'.png'">
                        <p class="text-center">{{ dataSourceTypeDict[websiteDetails.importType] }}</p>
                    </div>

                    <div class="d-flex flex-column" :class="!hasMetaTesterRole && isShowEditBtn ? 'col-8' : 'col-9'">
                        <b-alert v-if="importFromValue" variant="success" show class="text-break">
                            <b>{{ importTypeInfos[websiteDetails.importType].importedAlertPrefix }} :</b>
                            </br>{{ importFromValue }}
                        </b-alert>
                    </div>
                    <div v-if="!hasMetaTesterRole && isShowEditBtn && isDataReady" class="col-1">
                        <a :href="editDataSourceUrl" class="btn btn-secondary align-self-end position-absolute edit-btn">Edit</a>
                    </div>
                </div>
                <div v-if="isShowShopifyConnectMsg" class="row no-gutters">
                    <div class="col-11 d-flex border bg-light rounded p-3 align-items-center justify-content-between mb-4">
                        <b>You're almost ready to import from Shopify...</b>                    
                        <a :href="connectShopifyUrl" class="btn btn-primary">Connect</a>
                    </div>
                </div>

                <div v-if="isShowSpiderNotConfiguredMsg" class="row no-gutters">
                    <div class="col-11">
                        <b-alert show variant="warning">
                            <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />
                            AdRetriever Site Walk is not fully configured for this advertiser. Please <a href="/contact_us/" target=”_blank”>contact support</a> to complete the setup.
                        </b-alert>
                    </div>
                </div>
                <ScrapeData v-bind="{ ...scrapeData }" :staticUrl="staticUrl" :accountId="accountId" @updateDisplayOfEditBtn=updateDisplayOfEditBtn />
                <ExcludedItemList v-bind={...excludedItemList} />
                <RecentInventory v-bind={...recentInventory} />
                <InventorySchedule v-if="isShowInventorySchedule" :intervalSelector=intervalSelector />
            </div>
        </div>
    </div>
    `
}

export { DataSource };
