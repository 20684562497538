import Vue from "vue"
import Cookies from "js-cookie";

let CsvInput = {
    data: function () {
        return ({
            file: null,
            fileName: null,
            addressList: null,
            uploadingFile: false

        })
    },
    props: {
        processUrl: String,
    },
    methods: {
        getAddressList(csv) {
            let formData = new FormData()
            formData.append('csv', csv)
            Vue.axios.post(this.processUrl, formData, {
                    headers: {
                        "X-CSRFToken": Cookies.get('csrftoken'),
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(response => {
                let addressList = response.data.address_list
                this.updateAddressList(addressList)
            }).catch(response => {
                console.log(response)
            })

        },
        handleFile(event) {
            this.file = event.target.files[0]
            this.fileName = this.file.name
            this.uploadFile()
        },
        uploadFile(){
            if (this.file != null) {
                this.uploadingFile = true
                this.getAddressList(this.file)
            }
        },
        updateAddressList(addressList) {
            this.addressList = addressList
            this.$emit('updateAddressList', this.addressList)
        }
    },
    computed: {
        inputMessage: function () {
            if(this.file === null) {
                return "To begin, upload your address CSV / XLS / XLSX File"
            }
            else {
                return this.file.name
            }
        }
    },
    template:
        `
            <form>
                <div class="input-group">
                    <div class="custom-file">
                        <label for="addressCsv" class="custom-file-label">{{ inputMessage }}</label>
                        <input type="file" class="custom-file-input" id="addressCsv" @change="handleFile($event)">
                    </div>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" disabled v-if="uploadingFile">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
              </div>
            </form>
        `
}

export { CsvInput }
