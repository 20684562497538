import { RecentInventoryItem } from './RecentInventoryItem.js'
import { ModalReportDetails } from './ModalReportDetails.js'
import {ImportService, ReportService} from '../common/api.service.js'

let RecentInventory = {
    name: "RecentInventory",
    props: {
        website_id: String
    },
    data: function() {
        return ({
            expand: false,
            selectedItem: null,
            hasErrors: false,
            hasWarnings: false,
            dataReady: false,
            noData: false,
            items: [],
            modalKey: '-recent-inventory'
        })
    },
    methods: {
        handleExpand() {
            this.expand = !this.expand
        },
        selectItem(item) {
            this.selectedItem = item.errors;
            this.hasErrors = this.selectedItem?.errors?.count > 0 ? true : false;
            this.hasWarnings = this.selectedItem?.warnings?.count > 0 ? true : false;
            this.$bvModal.show('modal-report-details' + this.modalKey)
        },
        getReportData() {
            ImportService.query({website: this.website_id, ordering: '-end_time', page_size: '11', updating: true}).then(response => {
                this.items = response.results.slice(1)
                if(this.items.length === 0) {
                    this.noData = true
                }
                this.dataReady = true
            })
        }
    },     
    mounted: function() {
        this.getReportData();
    },
    computed: {
        widgetIcon: function() {
            return this.expand ? ['fas', 'angle-up'] : ['fas', 'angle-down']
        },
    },
    components: {
        'recent-inventory-item': RecentInventoryItem,
        'report-modal': ModalReportDetails
    }, 
    template: `
    <div class="recent-inventory card-body border mb-3 p-3 bg-light default-theme-border-radius shadow">
        <div class="border-bottom d-flex justify-content-between align-items-center">
            <h5>Previous Inventory Updates</h5>
            <font-awesome-icon :icon="widgetIcon" class="float-right mr-2 data-source-expand" @click="handleExpand" />
        </div>
        <div v-if="expand">
            <div v-if="!dataReady" class="d-flex justify-content-center">
                <b-spinner class="" variant="secondary" label="Loading..."></b-spinner>
            </div>
            <div v-else-if="noData">
                <h5 class="my-5 no-data text-center">No Data</h5>
            </div>
            <component
            v-else
            :is="'recent-inventory-item'"
            v-for="item in items"
            :key="item.start_time"
            :item="item"
            v-on:selectItem="selectItem"
            />
        </div>
        <component
        :is="'report-modal'"
        :modalKey="modalKey"
        :hasErrors="hasErrors"
        :hasWarnings="hasWarnings"
        :errors="selectedItem?.errors"
        :warnings="selectedItem?.warnings"
        />
    </div>
    `
}

export {RecentInventory}