import Vue from 'vue';
import {ItemDetailsModal} from "../ItemDetails/ItemDetailsModal.js";

let ExcludedItemList = {
    name: "ExcludedItemList",
    data(){
        return {
            items: [],
            itemDetailModalKey: 'excluded-item-list'
        }
    },
    props: {
        website: String,
        websiteType: String,
        websiteId: String,
        excludedItemURL: String,
        isVideoAdUser: String,
        presetAssetThumbnail: String,
        presetImageThumbnail: String
    },
    mounted: function (){
        this.getExcludedItems()
    },
    components: {
        "item-modal": ItemDetailsModal
    },
    methods: {
        getExcludedItems() {
            let url = window.location.origin+this.excludedItemURL
            Vue.axios.get(url).then(response => {this.items = response.data['results']})
        }
    },
    template: `
        <div v-if="items.length" class="card-body border mb-3 p-3 bg-light default-theme-border-radius shadow">
            <div class="border-bottom d-flex justify-content-between align-items-center">
                <h5>Excluded Items
                     <font-awesome-icon icon="question-circle" class="text-muted ml-2" v-b-popover.hover.right="'These items have been manually excluded and will not appear in inventory, sets, or ads.'" />
                 </h5>
            </div>
            <div>
                <div v-for="item in items" class="mt-2">
                    <a class="text-danger" href="#" v-b-modal="item.id + itemDetailModalKey" >{{item.name}}</a>
                    <item-modal 
                        :item="item"
                        :item_type="websiteType"
                        :website="website"
                        :websiteId="websiteId"
                        :presetAssetThumbnail="presetAssetThumbnail"
                        :presetImageThumbnail="presetImageThumbnail"
                        :isVideoAdUser="isVideoAdUser"
                        v-on:onUpdateItemVisibility="getExcludedItems"
                        :modalKey="itemDetailModalKey"
                    ></item-modal>
                </div>
            </div>
        </div>
    `
}

export { ExcludedItemList }