import Vue from 'vue';
import ApiService, { ReportService } from "../common/api.service.js"

let TopAdsByConversionWidget = {
    data: function () {
        return ({
            ads: [],
            items: [], // items of selected ads
            itemConversions: [],
            fields: {
                'ads': [{ key: 'ad_name', label: 'Ad' }, { key: 'total_conversions', label: 'Conversions' }],
                'items': [{ key: 'item_name', label: 'Item' }, { key: 'conversions', label: 'Conversions' }],
                'conversion': [{ key: 'conversion_type_name', label: 'Conversion Type' }, { key: 'created', label: 'Date' }],
            },
            busy: false,
            selectedAd: null,
            selectedAdUrl: null,
            selectedItem: null,
            selectedItemUrl: null
        })
    },
    props: {
        website: String,
        websiteName: String,
    },
    watch: {
        selectedAd: {
            handler() {
                if (this.selectedAd) this.getData('item');
            },
            deep: true
        },
        selectedItem: {
            handler() {
                if (this.selectedItem) this.getData('conversion');
            },
            deep: true
        }
    },
    methods: {
        formatUrl(url) {
            return url.replace('/api/', '')
        },
        getData (type) {
            let url = type === 'item' ? this.selectedAdUrl : this.selectedItemUrl
            this.busy = true
            if(type === 'ad') {
                ReportService.get("ADS_CONVERSIONS", this.website).then(response => {
                    this.ads = response.data
                    this.busy = false
                }).catch(err => {
                    this.busy = false
                })
            }else{
                ApiService.query2(this.formatUrl(url)).then(response => {
                    let dataStorageKeyName = type === 'item' ? 'items' : 'itemConversions'
                    this[dataStorageKeyName] = response.data
                    this.busy = false
                }).catch(err => {
                    this.busy = false
                })
            }
        },
        onNameClick(name, link, type) {
            if (type === 'ad') {
                this.selectedAd = name
                this.selectedAdUrl = link
            } else if (type === 'item') {
                this.selectedItem = name
                this.selectedItemUrl = link
            }
        },
        resetSelectedAd() {
            this.selectedAd = null
            this.selectedAdUrl = null
        },
        resetSelectedItem() {
            this.selectedItem = null
            this.selectedItemUrl = null
        },
        goToPixelAndConversion() {
            window.location.href = window.location.origin + "/" + this.websiteName +  "/settings#/pixel-and-conversions";
        }
    },
    mounted: function () {
        this.getData('ad');
    },
    template: `
        <div class="dashboard-component widget-height w-50 top-ads-by-conversions shadow-sm bg-white">
            <div class="panel-header container">
                <h5 class="widget-title mb-3">
                    <b-row class="align-items-baseline">
                        <div v-if="selectedAd && !selectedItem" class="d-flex">
                            <p @click="resetSelectedAd" class="border-0 p-0 pr-3 pl-0">
                                <font-awesome-icon :icon="['fas', 'chevron-left']" />
                            </p>
                            <p>{{ selectedAd }}</p>
                        </div>
                        <div v-if="selectedAd && selectedItem" class="d-flex">
                            <p @click="resetSelectedItem" class="border-0 p-0 pr-3 pl-0">
                                <font-awesome-icon :icon="['fas', 'chevron-left']" />
                            </p>
                            <p>{{ selectedItem }}</p>
                        </div>
                        <p v-if="!selectedAd && !selectedItem">Top ads by conversions <span class="opacity-50">(30 days)</span></p>
                    </b-row>
                </h5>
            </div>
            <div v-if="!selectedAd" class="ads-panel">
                <b-table class="stacked-data-source-label table-max-height" responsive stacked="xl" borderless hover :items="ads" :fields="fields['ads']" :busy="busy" thead-class="data-source-label" show-empty>
                        <template #table-busy>
                            <div class="d-flex justify-content-center my-5">
                                <b-spinner variant="secondary" label="Loading..."></b-spinner>
                            </div>
                        </template>
                        <template #empty="scope">
                            <h4 class="mt-5 no-data text-center">No Data</h4>
                            <div class="text-center">
                                <a href="#" v-on:click.prevent.stop="goToPixelAndConversion()">Did you install the pixel?</a>
                            </div>
                        </template>
                        <template #cell()="data">
                            <top-row-conversion :field="data.field" :value="data.value" rowType="ad" :websiteName="websiteName" :rowData="data.item" v-on:onClick="onNameClick"></top-row-conversion>
                        </template>
                </b-table>
            </div>
            <div v-if="selectedAd && !selectedItem" class="items-panel">
                <b-table class="stacked-data-source-label table-max-height" responsive stacked="xl" borderless hover :items="items" :fields="fields['items']" :busy="busy" thead-class="data-source-label" show-empty>
                    <template #table-busy>
                        <div class="d-flex justify-content-center my-5">
                            <b-spinner variant="secondary" label="Loading..."></b-spinner>
                        </div>
                    </template>
                    <template #empty="scope">
                    <h4 class="my-5 no-data text-center">No Data</h4>
                    </template>
                    <template #cell()="data">
                        <top-row-conversion :field="data.field" :value="data.value" rowType="item" :websiteName="websiteName" :rowData="data.item" v-on:onClick="onNameClick"></top-row-conversion>
                    </template>
                </b-table>
            </div>
            <div v-if="selectedItem" class="conversion-panel">
                <b-table class="stacked-data-source-label table-max-height" responsive stacked="xl" borderless hover :items="itemConversions" :fields="fields['conversion']" :busy="busy" thead-class="data-source-label" show-empty>
                    <template #table-busy>
                        <div class="d-flex justify-content-center my-5">
                            <b-spinner variant="secondary" label="Loading..."></b-spinner>
                        </div>
                    </template>
                    <template #empty="scope">
                    <h4 class="my-5 no-data text-center">No Data</h4>
                    </template>
                    <template #cell()="data">
                        <top-row-conversion :field="data.field" :value="data.value" rowType="conversion" :websiteName="websiteName" :rowData="data.item"></top-row-conversion >
                    </template>
                </b-table>
            </div>
          </div>
    </div>
    `
}

Vue.component("top-row-conversion", {
    props: {
        rowData: Object,
        rowType: String,
        websiteName: String,
        field: Object,
        value: [Number, String],
    },
    methods: {
        formatToTwoDecimal(val) {
            return val != 0 ? parseFloat(val).toFixed(2) : val
        },
        formatCtrPercent(val) {
            return val !== 0 && Math.abs(val) !== Infinity ? Math.round(Math.abs(val) * 100) + "%" : ""
        },
        formatDate(val) {
            var options = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(val).toLocaleDateString("en-US", options)
        },
        getBadgeColor(val) {
            if (val > 0) {
                return "data-source-pill-positive"
            } else if (val < 0) {
                return "data-source-pill-negative"
            } else {
                return "data-source-pill-neutral"
            }
        },
        getPercentIcon(val) {
            let iconName, angle;

            if (val > 0) {
                iconName = 'long-arrow-alt-up';
                angle =  45;
            } else if (val < 0) {
                iconName = 'long-arrow-alt-down';
                angle =  -45;
            } else {
                iconName = 'slash';
                angle =  -38;
            }

            return { icon: ['fas', iconName], angle };
        },
        onNameClick(name) {
            if (this.rowType === 'item') {
                this.$emit('onClick', name, this.url, 'item')
            } else if (this.rowType === 'ad') {
                this.$emit('onClick', name, this.url, 'ad')
            }
        }
    },
    computed: {
        key_name: function () {
            return this.field['key']
        },
        improvement: function () {
            return this.rowData.improvement
        },
        badgeColor: function () {
            return this.isConversion ? this.getBadgeColor(this.improvement) : null;
        },
        percentIcon: function () {
            return this.isConversion ? this.getPercentIcon(this.improvement) : null;
        },
        ctrPercent: function () {
            return this.isConversion ? this.formatCtrPercent(this.improvement) : null;
        },
        isConversion: function () {
            return this.key_name == 'total_conversions' || this.key_name == 'conversions'
        },
        isDate: function () {
            return this.key_name == 'created'
        },
        url: function () {
            let keyName = this.rowType === 'ad' ? 'ad_url' : 'item_url'
            return this.rowData[keyName] ? this.rowData[keyName] : ''
        },
        name_key_name: function () {
            return this.rowType === 'ad' ? 'ad_name' : this.rowType === 'item' ? 'item_name' : "conversion_type_name"
        },
        createdDate: function () {
            return this.isDate ? this.formatDate(this.rowData.created) : null;
        }
    },
    template: `
        <div class="topColumn" :class="key_name === name_key_name ? 'name' : ''">
            <div v-if="key_name === name_key_name" >
                <p v-if="rowType === 'conversion'">{{ rowData[name_key_name] }}</p>
                <a v-else href="#/" @click="onNameClick(rowData[name_key_name])" class="ellipsis border-0 text-left" :data-text="rowData[name_key_name]">{{ rowData[name_key_name] }}</a>
            </div>
            <div v-else-if="isConversion">{{ formatToTwoDecimal(rowData[key_name]) }} 
                <b-badge pill :class="badgeColor">{{ ctrPercent }} 
                    <font-awesome-icon :icon="percentIcon.icon" :style="{ transform: 'rotate(' + percentIcon.angle + 'deg)' }"></font-awesome-icon>
                </b-badge>
            </div>
            <div v-else-if="isDate"> {{ createdDate }}</div>
            <div v-else> {{ value }}</div>
        </div>
    `
})

export { TopAdsByConversionWidget }