import { ReportItemList } from "./ReportItemList.js";

let ModalReportDetails = {
    props: {
        hasErrors: Boolean,
        hasWarnings: Boolean,
        errors: Object,
        warnings: Object,
        modalKey: String,
        staticUrl: String,
        accountId: String
    },
    components: {
        'report-items': ReportItemList
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('modal-report-details' + this.modalKey)
        }
    },
    template: `
    <b-modal 
    :id="'modal-report-details' + modalKey"
    scrollable
    size="xl"
    title="Report Details">
        <div class="container">
        <component
        v-if="hasErrors"
        :is="'report-items'"
        :type="'error'"
        :issues="errors.issues"
        :staticUrl="staticUrl"
        :accountId="accountId"
        />
        <component
        v-if="hasWarnings"
        :is="'report-items'"
        :type="'warning'"
        :issues="warnings.issues"
        :staticUrl="staticUrl"
        :accountId="accountId"
        />
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="closeModal()"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    `
}

export {ModalReportDetails}