
let ImpressionLimitAlert = {

data: function() {
    return {
    };
  },
    props: {
        refreshDate: String,
    },
    template: `
        <div class="alert alert-warning" role="alert">
            <h4 class="alert-heading">Impression Limit Reached</h4>
            You have reached the monthly impression limit for your account. Your ads are paused and will automatically
            resume at the beginning of the next month {{ refreshDate }}
        </div>
    `
}

export {ImpressionLimitAlert}