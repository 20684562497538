import { TierUpgradeButton } from "../tiers/TierUpgradeButton";

let ReportItem = {
    props: {
        type: String,
        issue: Object,
        staticUrl: String,
        accountId: String
    },
    components: {
        TierUpgradeButton
    },
    computed: {
       icon: function() {
           return this.type === 'error' ? { prefix: 'fas', iconName: 'exclamation-circle', classes: ['text-danger'] } : { prefix: 'fas', iconName: 'exclamation-triangle', classes: ['text-warning'] }
       },
       itemsText: function() {
           return this.issue.totalItems > 1 ? 'items were' : 'item was'
       },
       formattedFields: function() {
            let temp =  [];
            this.fields.forEach((item, index) => {
                if(item === this.issue.type) {
                    temp.push({key: item, label: item, variant: this.type === 'error' ? 'danger' : 'warning'})
                } else {
                    temp.push({key: item, label: item})
                }
            });
            return temp
       },
        truncationNote: function() {
            return this.issue.totalItems > this.issue.maxLogItem ? 'These are only a few examples. Check your file to see and correct each instance' : ''
        }
    },
    data: function() {
        return ({
            fields: Object.keys(this.issue.items[0])
        })
    },
    methods: {
        formatIssueType(issueType) {
            if (["non_field_errors", "item_limit_exceeded"].includes(issueType)) {
                return "";
            }
            else {
                return issueType + ":"
            }
        }
    },
    template: `
    <div class="mb-5">
        <div>
            <TierUpgradeButton
            v-if="issue.type === 'item_limit_exceeded'"
            buttonText="Upgrade Your Account"
            modalKey="RetrieveMoreItems"
            :staticUrl="staticUrl"
            :accountId="accountId"
            class="float-right"
            />
            <div class="d-inline font-weight-bold">    
                <font-awesome-icon class="pr-1" :icon="icon" :class="icon.classes"></font-awesome-icon> {{ formatIssueType(issue.type) }} {{ issue.description }}
            </div>
            <p class="font-weight-light pl-4 smaller-font-size">{{ issue.totalItems }} {{ itemsText }} not updated or added</p>
        </div>
        <b-table

        responsive
        :items="issue.items"
        :bordered="true"
        :fields="formattedFields"
        :head-row-variant="'light'"
        show-empty
        >
            <template #cell(name)="data">
                <b-col :style="{ width: '200px' }">{{ data.item.name }}</b-col>
            </template>

            <template #cell(description)="data">
                <b-col :style="{ width: '400px' }">{{ data.item.description }}</b-col>
            </template>

            <template #cell(image_urls)="data">
                <b-col :style="{ width: '800px' }">{{ data.item.image_urls }}</b-col>
            </template>

        </b-table>
        <p class="font-weight-light smaller-font-size">{{ truncationNote }} </p>
    </div>
    `
};

export {ReportItem}