import { ThemeVersionsService, AdsService } from "../common/api.service.js";
import { convertDatetime } from "../utils/date_utils.js";

let VersionDetails = {
    name: "VersionDetails",
    props: {
        website: String,
        previewDetails: Object
    },
    data: function () {
        return ({
            versionDetails: null,
            ads: [], // The ads using this version
            isFileUploading: false, // Any file uploading
            urlTemplate: '',
            data: undefined,
            themeId: this.$route.params.themeId ? this.$route.params.themeId : '',
            versionId: this.$route.params.versionId ? this.$route.params.versionId : '',
            isNameEditable: this.$route.query.from === 'upload',
            isEditingThemeName: false,
            isSavingThemeName: false,
            isValidatingThemeName: false,
            themeName: '',
            // Storing selected file in file input
            files:{
                sourceFile: {
                    file: null,
                    isValidating: false,
                    isUploading: false,
                    isError: false,
                    errorMsg: '',
                    uploadSuccess: false
                },
                thumbnail: {
                    file: null,
                    isValidating: false,
                    isUploading: false,
                    isError: false,
                    errorMsg: '',
                    uploadSuccess: false
                },
            },
        })
    },
    mounted: function () {
        this.getVersionDetails()
    },
    watch: {
        uploadedFiles: {
            deep: true,
            handler(newValues, oldValues) {
                // Ignore reset input file case
                if (newValues.every(uploadedFile => uploadedFile === null)) return

                // Check to see which file is getting updated
                let updatedFile = {
                    file: null,
                    type: ''
                }

                let _this = this
                newValues.forEach(function (value, index) {
                    if (value !== oldValues[index]) {
                        updatedFile.type = Object.keys(_this.files)[index]
                        updatedFile.file = value
                    }
                });

                // Send the updated type and file to upload file
                this.uploadFile(updatedFile)
            }
        },
    },
    methods: {
        getVersionDetails() {
            ThemeVersionsService.get(this.versionId, {}).then(response => {
                this.versionDetails = response
                this.themeName = response.name
                this.updatePageTitle(response.name, this.versionId)
                this.getAdsDetails();
            });
        },
        getAdsDetails() {
            AdsService.init(this.website).query({
                theme_version_id: this.versionId,
                expand: 'itemset,theme_config',
                only: 'id,name,theme_config__url_params,itemset_id,type',
            }).then(response => {
                this.ads = response.results
            });
        },
        updatePageTitle() {
            let pageTitle = 'Edit ' + this.generatePageTitle()
            this.$emit('updatePageTitle', pageTitle)
        },
        generatePageTitle(){
            return this.versionDetails.name + ' - Version ' +  this.versionDetails.number
        },
        generateVersionName() {
            return ' - Version ' +  this.versionDetails.number
        },
        getAdEditUrl(adId, adType) {
            return '../ad/' + adId + '/edit?type=' + adType
        },
        buildIFrameUrl(ad) {
            if (!this.ads.length) return
            if (this.versionDetails.theme_type === 'video') {
                return this.buildVideoUrl({ website: this.website, id: ad.id }, "/{website}/ad/{id}/preview")
            } else {
                return `${window.location.origin}/${this.website}/template/preset/preview?${ad.theme_config.url_params}&itemset_id=${ad.itemset_id}&theme_id=${this.versionId}`
            }
        },
        buildVideoUrl(adData, urlTemplate = this.urlTemplate) {
            urlTemplate = window.location.origin + urlTemplate;
            const combinedData = {...adData, ...this.data};
            Object.keys(combinedData).forEach((key) => {
                const value = encodeURIComponent(combinedData[key]);
                const pattern = new RegExp(`\\{${key}\\}`);
                urlTemplate = urlTemplate.replace(pattern, value);
            });
            return urlTemplate;
        },
        convertTimestamp(timestamp) {
            return convertDatetime(timestamp)
        },
        convertCamelToSnake(data){
            return data.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join('_')
        },
        getFileNameFromUrl (url){
            return url.substring(url.lastIndexOf('/')+1)
        },
        uploadFile(updatedFile) {
            this.resetUploadInputStatus(updatedFile.type)
            this.resetUploadInputError(updatedFile.type)

            // Start uploading
            this.files[updatedFile.type].isUploading = true
            this.files[updatedFile.type].isValidating = true
            this.isFileUploading = true

            const formData = new FormData();
            formData.append([this.convertCamelToSnake(updatedFile.type)], updatedFile.file);

            ThemeVersionsService.update(this.versionId, formData).then(response => {
                this.isFileUploading = false
                this.files[updatedFile.type].file = null
                this.resetUploadInputStatus(updatedFile.type)

                // Display success status for few seconds
                this.files[updatedFile.type].uploadSuccess = true
                this.getVersionDetails()
                setTimeout(() => { 
                    this.files[updatedFile.type].uploadSuccess = false
                }, 1500);
            }).catch(error => {
                this.resetUploadInputStatus(updatedFile.type)
                this.files[updatedFile.type].isError = true
                this.files[updatedFile.type].errorMsg = error.response.data[this.convertCamelToSnake(updatedFile.type)][0]
            });
        },
        resetUploadInputError(fileType) {
            this.files[fileType].isError = false
            this.files[fileType].errorMsg = ''
        },
        resetUploadInputStatus(fileType) {
            this.files[fileType].isUploading = false
            this.files[fileType].isValidating = false
        },
        editThemeName() {
            this.isEditingThemeName = true
            this.$nextTick(() => {
                this.$refs.themeNameInput.focus()
            })
        },        
        cancelEditThemeName() {
            this.isEditingThemeName = false
            // Reset name
            this.themeName = this.themeDetails.name
            this.isSavingThemeName = this.isValidatingThemeName = false
        },
        saveThemeName() {
            this.isSavingThemeName = this.isValidatingThemeName = true;

            if (!this.isThemeNameValid) {
                this.isSavingThemeName = false;
                return;
            }

            ThemeVersionsService.patchTheme(this.themeId, this.themeName).then(response => {
                this.isEditingThemeName = false
                this.getVersionDetails()
            });
        },
    },
    computed: {
        uploadedFiles() {
            return [this.files.sourceFile.file, this.files.thumbnail.file];
        },
        templateTableFields() {
            return this.versionDetails.theme_type === 'html' ?
                [
                    {
                        key: 'dimensions',
                        label: 'Size',
                        sortable: false
                    },
                ] :
                [
                    {
                        key: 'dimensions',
                        label: 'Size',
                        sortable: false
                    },
                    {
                        key: 'duration',
                        label: "Duration",
                        sortable: false,
                        formatter: value => { return value + 's' }
                    },
                ]
        },
        isThemeNameValid: function () {
            console.log(this.themeName)
            return Boolean(this.themeName);
        }
    },
    template: `
    <div v-if="versionDetails" class="version-details-container">
        <div v-if="!versionDetails" class="loading-overlay page-loading-overlay position-absolute d-flex justify-content-center align-items-center">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else>
            <div class="d-flex">
            
                <div v-if="isEditingThemeName" class="d-flex flex-column w-75 mb-3">
                    <div class="mb-2">

                        <b-input-group>
                            <b-form-input
                            placeholder="Theme name"
                            class="form-control editable-name-control h1 mb-0 border-right-0"
                            :state="isValidatingThemeName ? isThemeNameValid : null"
                            v-model="themeName"
                            ref="themeNameInput" />

                            <b-input-group-append>
                                <b-input-group-text class="bg-transparent h1">
                                    {{ generateVersionName() }}
                                </b-input-group-text>
                            </b-input-group-append>
                            
                            <b-form-invalid-feedback :state="isValidatingThemeName ? isThemeNameValid : null" class="mt-0">Invalid theme name.</b-form-invalid-feedback>
                        </b-input-group>
                        
                    </div>
    
                    <div class="align-self-start">
                        <b-button class="mr-1" variant="primary" @click="saveThemeName">Save</b-button>
                        <b-button variant="secondary" @click="cancelEditThemeName">Cancel</b-button>
                    </div>
                </div>
                <div v-if="isNameEditable && !isEditingThemeName">
                    <h1 class="d-inline m-0">{{ versionDetails.name }}</h1>
                    <h1 class="d-inline m-0">{{ generateVersionName() }}</h1>
                    <div class="d-inline align-text-bottom mx-2" @click="editThemeName">
                        <font-awesome-icon icon="pencil-alt" size="lg" class="pb-1" />
                    </div>
                </div>

                <div v-if="!isNameEditable">
                    <h1 class="d-inline m-0">{{ generatePageTitle() }}</h1>
                </div>
            </div>
            <p class="m-1">
                <span>{{ versionDetails.ads_using?.length || 0 }}</span>
                <span v-if="(versionDetails.ads_using?.length || 0) > 1">ads</span>
                <span v-else>ad</span>
                <span>in use</span>
                <span class="badge rounded-pill border border-secondary text-secondary px-2 ml-2 align-self-center">
                    <font-awesome-icon v-if="versionDetails.type === 'html'" :icon="['fas', 'code']" />
                    <font-awesome-icon v-else :icon="['fas', 'video-camera']" />
                </span>
            </p>
            <p class="m-1 text-muted">Last updated: {{ convertTimestamp(versionDetails.last_updated) }}</p>
            <div class="source-file-container mt-4">
                <div>
                    <h5>Source file</h5>
                    <a v-if="versionDetails?.source_file" target="_new_blank" :href="versionDetails?.source_file">{{ getFileNameFromUrl(versionDetails?.source_file) }}</a>
                </div>
                <div class="position-relative">
                    <div v-if="files.sourceFile.isUploading" class="loading-overlay position-absolute d-flex justify-content-center align-items-center w-50 bg-white opacity-50">
                        <b-spinner label="Loading..."></b-spinner>
                    </div>
                    <div v-if="files.sourceFile.uploadSuccess" class="loading-overlay position-absolute d-flex justify-content-center align-items-center w-50 bg-white">
                        <font-awesome-icon class="text-success" :icon="['fas', 'circle-check']" />
                    </div>
                    <b-form-file
                        :disabled="isFileUploading"
                        accept=".zip, .html"
                        class="w-50 mt-1"
                        v-model="files.sourceFile.file"
                        placeholder="---"
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                </div>
                <b-form-invalid-feedback :state="!files.sourceFile.isError" class="mt-0">{{ files.sourceFile.errorMsg }}</b-form-invalid-feedback>
            </div>
            <div class="thumbnail-container mt-5">
                <div>
                    <h5>Thumbnail</h5>
                    <div v-if="versionDetails?.thumbnail_url">
                        <a target="_new_blank" :href="versionDetails?.thumbnail_url">{{ getFileNameFromUrl(versionDetails?.thumbnail_url) }}</a>
                        <b-dropdown dropright variant="link" boundary="window" no-caret menu-class="p-1 w-50" toggle-class="p-0 ml-1">
                            <template #button-content>
                                <font-awesome-icon class="primary" :icon="['fas', 'eye']" />
                            </template>
                            <b-dropdown-item href="#" link-class="p-0 m-0">
                                <img class="w-100" :src="versionDetails?.thumbnail_url" />
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="position-relative">
                    <div v-if="files.thumbnail.isUploading" class="loading-overlay position-absolute d-flex justify-content-center align-items-center w-50 bg-white opacity-50">
                        <b-spinner label="Loading..."></b-spinner>
                    </div>
                    <div v-if="files.thumbnail.uploadSuccess" class="loading-overlay position-absolute d-flex justify-content-center align-items-center w-50 bg-white">
                        <font-awesome-icon class="text-success" :icon="['fas', 'circle-check']" />
                    </div>
                    <b-form-file
                        :disabled="isFileUploading"
                        accept="image/*"
                        class="w-50 mt-1"
                        v-model="files.thumbnail.file"
                        placeholder="---"
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                </div>
                <b-form-invalid-feedback :state="!files.thumbnail.isError" class="mt-0">{{ files.thumbnail.errorMsg }}</b-form-invalid-feedback>
            </div>
            <div class="templates-container mt-5">
                <h5>Templates</h5>
                <b-table class="table-rounded default-theme-border-radius" hover table-variant="light" thead-class="default-theme-border-radius border-0 forrest-background text-white" :class="versionDetails.theme_type === 'html' ? 'w-25' : 'w-50'" :items="versionDetails?.sizes" :fields="templateTableFields"></b-table>
            </div>
            <div class="ads-container my-5">
                <h5>Ads in use</h5>
                <div class="ads-list-container bg-white w-75 p-3 overflow-auto shadow">
                    <ul v-if="ads.length" class="list-unstyled">
                        <li v-for="ad in ads" :key="ad.id" class="my-1">
                            <b-dropdown dropup variant="link" boundary="window" no-caret menu-class="p-1">
                                <template #button-content>
                                    <font-awesome-icon class="primary p-0 ml-1" :icon="['fas', 'eye']" />
                                </template>
                                <b-dropdown-item href="#" link-class="p-0 m-0">
                                    <iframe class='loading' loading="lazy" id='ad_iframe' width="300" height="250" :src="buildIFrameUrl(ad)" scrolling="no" frameborder="0"/>
                                </b-dropdown-item>
                            </b-dropdown>
                            <a target="_new_blank" :href="getAdEditUrl(ad.id, ad.type)">{{ ad.name }}</a>
                        </li>
                    </ul>
                    <p v-else>No ads using this theme version</p>
                </div>

            </div>
        </div>
    </div>
    `
}

export { VersionDetails }

