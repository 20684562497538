// List of temporary stored videos in frontend that user selected
let TempUploadedVideoList = {
    data(){
        return {
            maxProgressValue: 100
        }
    },
    props: {
        tempAttachments: Array,
        statusList: Object,
        supportedVideoDimensions: Array
    },
    methods: {
        checkProgress(attachment) {
            return attachment.progress === null ? false : true;
        },
        removeFile(file){
            this.$emit('removeFileMethod', file);
        },
        getProgressBarVariant(status) {
            switch (status) {
                case this.statusList.success:
                  return 'success'
                case this.statusList.fail:
                    return 'danger'
                default:
                    return 'info'
            } 
        },
    },
    template: `
    <div class="col temp-uploaded-video-list w-100 p-0">
        <ul v-if="tempAttachments.length > 0" class="list-unstyled">
            <li v-for="tempAttachment in tempAttachments" :key="tempAttachment._id" class="border-bottom mb-3">
                <div class="file-details">
                    <div class="file-name d-flex justify-content-between">
                        <p class="mb-1" ref="attachmentTitle">
                            {{ tempAttachment.title }}
                            <font-awesome-icon v-if="tempAttachment.dimension.length" class="opacity-50 align-middle px-2" :icon="['fas', 'circle']" size="2xs" />
                            <span class="opacity-50">{{ tempAttachment.dimension }}</span>
                        </p>
                        <a class="dz-remove" :class="{'hide' : tempAttachment.status === statusList.default }" href="javascript:undefined;" data-dz-remove @click="removeFile(tempAttachment)">
                            <font-awesome-icon :icon="['fas', 'xmark']" size="lg" />
                        </a>
                    </div>
                    <div class="file-info">
                        <b-progress 
                        v-if="checkProgress(tempAttachment)" 
                        :value="tempAttachment.progress" 
                        :max="maxProgressValue" 
                        :animated="tempAttachment.status === statusList.success || tempAttachment.status === statusList.fail ? false : true"
                        :variant="getProgressBarVariant(tempAttachment.status)"></b-progress>
                        
                        <div class="d-flex mt-1 mb-1">
                            <p
                            :class="[
                                {'processing' : tempAttachment.status !== statusList.success || tempAttachment.status !== statusList.fail},
                                {'text-success': tempAttachment.status === statusList.success}
                            ]"
                            class="status text-capitalize col-2 mt-1 pl-0">
                                <font-awesome-icon v-if="tempAttachment.status === statusList.success" :icon="['fas', 'circle-check']" />
                                <font-awesome-icon v-if="tempAttachment.status === statusList.fail" class="text-danger" :icon="['fas', 'xmark']" />
                                <font-awesome-icon v-if="tempAttachment.status !== statusList.success && tempAttachment.status !== statusList.fail" class="text-secondary" :icon="['fas', 'spinner']" spin />
                                {{tempAttachment.status}}
                            </p>
                            <div class="mt-1">
                                <div class="error-msg mb-0 text-danger" v-for="error in tempAttachment.error" :key="error.code" :id="error.code" >
                                    <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />
                                    <span>{{ error.message }}</span>
                                    <b-tooltip 
                                    v-if="error.code === 'dimension_error'" 
                                    :target="error.code" 
                                    triggers="hover" 
                                    variant="info" 
                                    placement="bottom" 
                                    container="tooltip-container" 
                                    offset="50"
                                    delay="1000"
                                    show>
                                        <p class="mb-0">Supported background video dimensions are:</p>
                                        <ul>
                                            <li v-for="dimension in supportedVideoDimensions" :key="dimension">{{ dimension }}</li>
                                        </ul>
                                    </b-tooltip>
                                </div>
                                <div id="tooltip-container"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>`
}

export { TempUploadedVideoList }