import Vue from 'vue';
import { debounce } from 'lodash';

const ShowFilterPills = Vue.component("show-filter-pills", {
     data: function() {
        return ({
            filters: this.store.state.filter
        });
    },
    methods: {
        removeFilter(filter){
           this.filters[filter] = undefined;
        }
    },
    props: {
        store: Object,
        hideRemoveBtn: Boolean
    },
    template: `
        <div class="filter-pills-container d-inline-block">
            <attributes-filter-pill v-for="(value, key) in filters" v-if="key === 'attributes' && value.length !== 0" :value="value" :store="store" :key="value + key" :hideRemoveBtn=hideRemoveBtn></attributes-filter-pill>
            <b-badge v-else-if="value && value.length !== 0 && key !== 'page' && key !== 'page_size'" pill class="filter-pill text-left pl-3 py-1 mt-1 mr-1" :class="hideRemoveBtn ? 'pr-3' : 'pr-2'">
                <div class="filter-pill-content d-flex">
                    <search-filter-pill v-if="key === 'search'" :value="value"></search-filter-pill>
                    <item-id-filter-pill v-else-if="key === 'item_ids'" :value="value"></item-id-filter-pill>
                    <default-filter-pill v-else :filter_key="key" :value="value"></default-filter-pill>
                    <button type="button" @click="removeFilter(key)" class="close mr-1 ml-2" aria-label="Close" :class="{hide: hideRemoveBtn ? true : false}">
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </button>
                </div>
            </b-badge>
        </div>
    `,
});

Vue.component("default-filter-pill", {
    props: {
        filter_key: String,
        value: undefined
    },
    template: `
        <span class="text-capitalize">{{filter_key}}-{{value}}</span>
    `
});

Vue.component("search-filter-pill", {
    props: {
        value: undefined
    },
    template: `
        <span>Name: {{value}}</span>
    `
});

Vue.component("item-id-filter-pill", {
    props: {
        value: undefined
    },
    template: `
        <span>{{value.split(",").length}} pinned items</span>
    `
});

Vue.component("attributes-filter-pill", {
    props: {
        value: undefined,
        store: Object,
        hideRemoveBtn: Boolean
    },
    methods: {
        removeAttribute(attributeName){
            const selectedValues = this.store.getSelectedAttributeValues(attributeName);
            selectedValues.forEach(value => this.store.removeAttributeFilter(attributeName, value));
        }
    },
    computed: {
        getList(){
            let tags = {}
            this.value.forEach(dict => {
                if (!(dict["attribute"] in tags)) {
                    tags[dict["attribute"]] = dict["value"]
                } else {
                    tags[dict["attribute"]] += `, ${dict["value"]}`
                }
            });
            return tags
        }
    },
    template: `
        <div class="attribute-filter-pill d-inline-block">
            <b-badge v-for="(val, attribute) in getList" pill class="filter-pill text-left pl-3 py-1 mt-1 mr-1" :key="val + attribute" :class="hideRemoveBtn ? 'pr-3' : 'pr-2'">
                <div class="filter-pill-content d-flex">
                    <span v-if="attribute === 'tags__name'">Tags: {{val}}</span>
                    <span class="text-capitalize" v-else>{{attribute}}: {{val}}</span>
                    <button type="button" @click="removeAttribute(attribute)" class="close mr-1 ml-2" aria-label="Close" :class="{hide: hideRemoveBtn ? true : false}">
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </button>
                </div>
            </b-badge>
        </div>
    `
});

Vue.component("search-panel", {
    methods: {
        handleSearch: debounce(function(text) {
            this.store.setSearchFilter(text);
        }, 1000)
    },
    computed: {
        text: {
            get() { return this.store.state.filter.search || ""},
            set: debounce(function(value) { this.store.setSearchFilter(value) }, 1000)
        }
    },
    props: {
        store: Object
    },
    template: `
        <form autocomplete="off">   
            <label class="filter_label">Search</label>
            <div class="form-row">
                <div class="col">
                    <input placeholder="Search by item name" class="form-control" v-on:keydown.enter.prevent v-model="text">
                </div>
            </div>
        </form>
    `,
});

Vue.component("price-panel", {
    props: {
        store: Object
    },
    computed: {
        minPrice: {
            get() { return this.store.state.filter.price__gte },
            set(minPrice) { this.store.setPriceFilter(this.parsePrice(minPrice), this.maxPrice) }
        },
        maxPrice: {
            get() { return this.store.state.filter.price__lte },
            set(maxPrice) { this.store.setPriceFilter(this.minPrice, this.parsePrice(maxPrice)) }
        }
    },
    methods: {
        parsePrice: price => {
            price = parseFloat(price);
            return isNaN(price) ? undefined : price;
        }
    },
    template: `
        <form autocomplete="off">
            <label class="filter_label">Price</label>
            <div class="form-row">
                <div class="col-6">
                    <input class="form-control" type="number" placeholder="min" v-model="minPrice">
                </div>
                <div class="col-6">
                    <input class="form-control" type="number" placeholder="max" v-model="maxPrice">
                </div>
            </div>
        </form>
    `,
});

Vue.component("year-panel", {
    props: {
        store:Object
    },
    computed: {
        minYear: {
            get() { return this.store.state.filter.year__gte },
            set(minYear) { this.store.setYearFilter(this.parseYear(minYear), this.maxYear) }
        },
        maxYear: {
            get() { return this.store.state.filter.year__lte },
            set(maxYear) { this.store.setYearFilter(this.minYear, this.parseYear(maxYear)) }
        }
    },
    methods: {
        parseYear: year => parseInt(year) || undefined
    },
    template: `
        <form autocomplete="off">
            <label class="filter_label">Year</label>
            <div class="form-row">
                <div class="col-6">
                    <input class="form-control" type="number" placeholder="min" v-model="minYear">
                </div>
                <div class="col-6">
                    <input class="form-control" type="number" placeholder="max" v-model="maxYear">
                </div>
            </div>
        </form>
    `,
});

Vue.component("duration-panel", {
    computed: {
        start: {
            get() { return this.store.state.filter.start_time__gte },
            set(start) { this.store.setDurationFilter(this.formatDate(start), this.end ) }
        },
        end: {
            get() { return this.store.state.filter.end_time__lte },
            set(end) { this.store.setDurationFilter(this.start, this.formatDate(end)) }
        }
    },
    props: {
        store:Object
    },
    methods: {
        formatDate(date) {
            // Format before from html input tag: 2022-05-11T19:47
            // Format after(datetimefield accepted format): 2022-05-11 19:47
            return (date ? date.replace("T", " ") : date);
        }
    },
    template: `
        <form autocomplete="off">
            <label class="filter_label">Duration</label>
            <div class="form-row">
                <div class="col-6">
                    <input class="form-control" type="text" placeholder="start" onfocus="(this.type='datetime-local')" onblur="(this.type='text')" v-model="start">
                </div>
                <div class="col-6">
                    <input class="form-control" type="text" placeholder="end" onfocus="(this.type='datetime-local')" onblur="(this.type='text')" v-model="end">
                </div>
            </div>
        </form>
    `,
});

Vue.component("lat-long-panel", {
    props: {
        store: Object
    },
    computed: {
        minLatitude: {
            get() { return this.store.state.filter.latitude__gte },
            set(min) { this.store.setLatitudeFilter(this.parseCoordinate(min), this.maxLatitude) }
        },
        maxLatitude: {
            get() { return this.store.state.filter.latitude__lte },
            set(max) { this.store.setLatitudeFilter(this.minLatitude, this.parseCoordinate(max)) }
        },
        minLongitude: {
            get() { return this.store.state.filter.longitude__gte },
            set(min) { this.store.setLongitudeFilter(this.parseCoordinate(min), this.maxLongitude) }
        },
        maxLongitude: {
            get() { return this.store.state.filter.longitude__lte },
            set(max) { this.store.setLongitudeFilter(this.minLongitude, this.parseCoordinate(max)) }
        },
    },
    methods: {
        parseCoordinate: coord => {
            coord = parseFloat(coord);
            return isNaN(coord) ? undefined : coord;
        }
    },
    template: `
        <form autocomplete="off">
            <label class="filter_label">Latitude</label>
            <div class="form-row">
                <div class="col-6">
                    <input class="form-control mb-4" type="number" placeholder="min" v-model="minLatitude">
                </div>
                <div class="col-6">
                    <input class="form-control" type="number" placeholder="max" v-model="maxLatitude">
                </div>
            </div>
            <label class="filter_label">Longitude</label>
            <div class="form-row">
                <div class="col-6">
                    <input class="form-control" type="number" placeholder="min" v-model="minLongitude">
                </div>
                <div class="col-6">
                    <input class="form-control" type="number" placeholder="max" v-model="maxLongitude">
                </div>
            </div>
        </form>
    `,
});

Vue.component("attribute-panel", {
    props: {
        url: String,
        attribute: String,
        label: String,
        store: Object
    },
    data: function() {
        return ({
            options: [],
            collapse: true,
            // Cached list of selected values
            selectedValues: new Set()
        })
    },
    watch: {
        // Watch attributes in the filter to refresh our cache
        'store.state.filter.attributes': function () {
            this.selectedValues = this.getSelectedValues()
        }
    },
    computed: {
        selected: {
            get() { return Array.from(this.selectedValues); },
            set(values) {
                // Get the set of selected values
                const toRemove = this.selectedValues;
                for (const val of values) {
                    // If the new value is not already selected, select it
                    if (!toRemove.has(val)) {
                        this.store.addAttributeFilter(this.attribute, val);
                    }
                    // Otherwise remove it from the list to be unset
                    else {
                        toRemove.delete(val);
                    }
                }
                // Unset any previously selected values that weren't found in the new selected list
                for (const val of toRemove) {
                    this.store.removeAttributeFilter(this.attribute, val);
                }
            }
        },

        boldedAttribute: function () {
            return this.selectedValues.size > 0 ? 'bold' : ''
        },

        attributeNameColor: function () {
            return this.selectedValues.size > 0 ? '' : '#666666'
        },
    },
    methods: {
        fetchOptions() {
            Vue.axios.get(this.url).then(response => {
                this.options = response.data.map(item => {
                    return item[this.attribute]
                });
            })
        },

        getSelectedValues() {
            return this.store.getSelectedAttributeValues(this.attribute);
        },

        constructId(attribute, option) {
            return attribute + "-" + option;
        },

        constructIdDiv(attribute, option) {
            return attribute + "-" + option + "-" + "div";
        },

        handleCollapse() {
            this.collapse = !this.collapse
        }
    },
    mounted: function() {
        this.fetchOptions();
    },

    template: `
        <div>
            <form autocomplete="off">
                <div class="form-row">
                    <div class="col">
                        <label :style="{'font-weight': boldedAttribute, color: attributeNameColor}">{{ label | capitalize }}</label>
                    </div>
                    <div class="col">
                        <font-awesome-icon type="button" class="float-right" :icon="collapse ? ['fas', 'caret-down'] : ['fas', 'caret-up']" @click="handleCollapse" />
                    </div>
                </div>
                <div class="filter-options-container bg-white rounded-lg border border-medium p-2" :class="{collapse: collapse}">
                    <div v-for="option of options" class="form-check custom-control custom-checkbox" :key="constructIdDiv(attribute, option)">
                        <input
                            type="checkbox"
                             :id="constructId(attribute, option)"
                             name="'attribute' + attribute"
                             v-model="selected"
                             :value="option"
                             class="form-check-input custom-control-input"/>
                        <label :for="constructId(attribute, option)" class="mb-0 align-middle custom-control-label">{{ option }}</label>
                    </div>
                </div>
            </form>
        </div>
    `,
})

let ItemSearchFilterPanel = Vue.extend({
    props: {
        filterSchema: Array,
        store: Object,
    },
    methods: {
         /**
         * Translation of the filter schema. This function maps the different types of components we have
         * and populates props and what functions we pass down.
         *
         * We populate a list with these different components so that we can loop over them dynamically
        **/
          getFilters: function() {
            let filters = []
            for (let x in this.filterSchema) {
                let props;
                if (this.filterSchema[x].component === 'attribute-panel') {
                    props = {
                        url: this.filterSchema[x].url,
                        attribute: this.filterSchema[x].attribute,
                        label: this.filterSchema[x].label || this.filterSchema[x].attribute,
                        store: this.store,
                    }
                } else {
                    props = {
                        store: this.store
                    }
                }
                filters.push({
                    component: this.filterSchema[x].component,
                    props: props
                });
            }
            return filters
        },
    },
    template: `
        <div class="item-search-filter-panel-container bg-white default-theme-border-radius py-4 pl-4 mt-0">
            <div class="row container-fluid">
                <h5>Filters</h5>
            </div>
            <div class="filters-content pr-4">
                <component
                    v-for="(filter,index) in getFilters()" :key=index
                    v-bind:is="filter.component"
                    v-bind="{...filter.props}"
                    v-on="{...filter.update}"
                    v-bind:class="['mb-4']"
                    ></component>
            </div>
        </div>
    `
})

export { ItemSearchFilterPanel, ShowFilterPills }