let ThemePreviewIframe = {
    props: {
        template: Object,
        isShowSize: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return ({
            isLoading: false,
            previewSource: '',
            secondPreviewSource: '',
            showingFirstIframe: true,
            timeout: 3000,
            clearOldIframeTimeoutFunction: null
        })
    },
    methods: {
        // As the iframe srcdoc for some of the theme will be all the same, 
        // frontend need to control the iframe size to show different sizes of template
        getWidth(size) {
            return size.split(" ")[0]
        },
        getHeight(size) {
            return size.split(" ")[2]
        },
        // Update preview iframe source
        updatePreviewIframeSource(newIframeSource) {
            // Trigger loading animation
            if (!this.isLoading) this.isLoading = true;
            if (this.showingFirstIframe) {
                // if the first iframe is showing, assign newIframeSource to secondPreviewSource
                this.updateIframeDisplay("secondPreviewSource", newIframeSource);
            } else {
                // otherwise we will show first iframe by assigning newIframeSource to previewSource
                this.updateIframeDisplay("previewSource", newIframeSource);
            }
        },
        // Update the display of iframe
        updateIframeDisplay(targetPreviewUrlName, newValForTarget) {
            // prevent multiple refresh before setTimeout done
            // if a timeout is running already, cancel the old one
            if (this.clearOldIframeTimeoutFunction) {
                clearTimeout(this.clearOldIframeTimeoutFunction);
                // reset variable to null
                this.clearOldIframeTimeoutFunction = null;
            }

            this[targetPreviewUrlName] = newValForTarget;

            // Delay update to have approximate wait for content to load and keep old preview
            this.clearOldIframeTimeoutFunction = setTimeout(function () {
                this.displayIframe();
                this.isLoading = false;
                // Reset timeout to null as finished timeout will still have value
                this.clearOldIframeTimeoutFunction = null;
            }.bind(this), this.timeout);
        },
        // Trigger transition to new preview iframe
        displayIframe() {
            this.showingFirstIframe = !this.showingFirstIframe
        },
    },
    mounted() {
        this.updatePreviewIframeSource(this.template.html)
    },
    watch: {
        template: {
            handler(newValues, oldValues) {
                this.updatePreviewIframeSource(newValues.html)
            },
            deep: true
        },
    },
    template: `
    <div class="theme-preview-iframe-container mb-5" :style="'width: '+ getWidth(template.size) + 'px;'">
        <p class="mb-0" v-if="template && isShowSize">{{ template.size }}</p>
        <div class="position-relative col-12 p-0 align-self-center" :style="'height: '+ getHeight(template.size) + 'px'">
            <div class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="isLoading ? 'show' : 'hide'">
                <b-spinner label="Loading..."></b-spinner>
            </div>

            <iframe class="theme-preview-iframe position-absolute"
            :class="showingFirstIframe ? 'show' : 'hide'"
            :width="getWidth(template.size)" 
            :height="getHeight(template.size)" 
            :srcdoc="previewSource" 
            frameborder="0">
            </iframe>

            <iframe class="theme-preview-iframe position-absolute"
            :class="!showingFirstIframe ? 'show' : 'hide'"
            :width="getWidth(template.size)" 
            :height="getHeight(template.size)" 
            :srcdoc="secondPreviewSource" 
            frameborder="0">
            </iframe>
        </div>
    </div>
    `
}

export { ThemePreviewIframe }