import { ItemSetService} from '../common/api.service.js';
import { ItemCardFilter } from '../ItemCards.js';
import {ItemSearchFilterPanel} from '../ItemSearchFilterPanel.js';
import {ItemsetEditMixin} from "../mixins/ItemsetEditMixin.js";
import {ItemSearchMixin} from "../mixins/ItemSearchMixin.js";
import  {store}  from "../store/ItemsetsStore.js"

const ItemsetEditCustom = {
    name: "ItemsetEditCustom",
    mixins: [ItemSearchMixin, ItemsetEditMixin],
    data: function () {
        return {
            store: store(),
            pinnedItems: null,
            isSaving: false,
            isValidating: false,
            dataReady: false,
            itemsetNameError: false,
            currentPage: 1,
            // Only used in Items page
            isInSaveSetMode: false,
            isAppliedFilter: false
        };
    },
    created: function () {
        if (this.$route?.params.id) {
            this.fetchItemSet();
        } else {
            this.store.updateFilter({});
            this.fetchItems();
        }
    },
    mounted: function () {
        if (this.dataReady && !this.itemsetName && !this.isItemsPage) {
            this.$refs.nameInput.focus();
        }
    },
    methods: {
        fetchItems: function() {
            // Use the mixin for the basic fetch, then handle pinned items
            ItemSearchMixin.methods.fetchItems.apply(this)
                .then(this.handlePinnedItemsResponse)
                .then(this.scrollBackToTop)
                .catch(this.handleNotFoundWithPageFilter)
        },
        handlePinnedItemsResponse(response) {
            this.pinnedItems = response.pinned_items || null;
            this.itemCount = this.getItemCount(response.count, response.pinned_items_count, this.store.isOnlyPinnedFilterActive());
            this.repairItemIds(response.pinned_items_count);
        },
        handleNotFoundWithPageFilter(error) {
            // Handle cases where we're using a page offset and we change the filter so that we have fewer pages
            if (error?.response.status === 404 && this.store.state.filter.page > 1) {
                // Go back to the first page
                this.store.setPageFilter(1);
            }
        },
        getItemCount(itemsCount, pinnedCount, onlyPinnedFilterActive) {
            pinnedCount = pinnedCount || 0;
            return onlyPinnedFilterActive ? pinnedCount : itemsCount + pinnedCount;
        },
        repairItemIds(pinnedCount){
            // Update the itemids if the pinned items returned match the param
            // this inconsistency could happen due to items being removed from the website
            if (typeof pinnedCount !== 'undefined' && pinnedCount !== this.store.getItemIdFilterCount()) {
                const selectedIds = this.pinnedItems.map(item => item.id);
                this.store.resetItemIdFilter(selectedIds.join(','));
            }
            this.dataReady = true;
        },

        saveItemset() {
            this.isSaving = this.isValidating = true;
            if (!this.nameIsValid) {
                this.isSaving = false;
                return;
            }

            // convert filter obj to remove undefined values, then convert back to object to save
            const filter = Object.entries(this.store.state.filter)
            const filteredArray = filter.filter(function ([key, value]) {
                return value !== undefined && value !== null && value !== [] && value !== "";
            })
            const filteredFilter = Object.fromEntries(filteredArray);


            let formData = {
                'name': this.itemsetName,
                // Currently this app only supports the custom ItemSet type
                'type': 'custom',
                'item_filter': filteredFilter
            };

            let itemsetService = ItemSetService.init(this.website);
            if (this.itemset) {
                itemsetService.update(`${this.itemset.id}/`, formData)
                    .then(response => this.returnLink(response))
                    .catch(this.handleSaveError)
            } else {
                itemsetService.create(formData)
                    .then(response => this.returnLink(response))
                    .catch(this.handleSaveError)
            }
        },
        handleSaveError: function() {
            this.isSaving = false;
        },
        returnLink(response) {
            if(!response.data) {
                this.$router.push({ name: 'itemsets' })
            }else{
                if (this.isItemsPage) {
                    // Handle redirect page when this component is not inside Itemsets.js (aka when serving as Items page)
                    const baseUrl = window.location.origin;
                    const params = { hl: response.data.id };
                    const url = baseUrl + "/" + this.website + "/sets#"
                    // Serialize the params into a query string
                    const queryString = Object.keys(params)
                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                        .join('&');
                    const redirectUrl = url + '?' + queryString;
                    window.location.href = redirectUrl;
                } else {
                    this.$router.push({ name: 'itemsets', query: { hl: response.data.id } })
                }
            }
        },
        scrollBackToTop() {
            this.$refs.itemsContainer.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    },
    computed: {
        buttonText: function () {
            return this.itemset ? 'Save' : 'Create'
        },
    },
    watch: {
        'store.state.filter': {
            handler() {
                this.fetchItems()
                if(this.store.isAppliedAnyFilters() && this.isItemsPage && this.dataReady && !this.isAppliedFilter) {
                    this.isAppliedFilter = true
                }
            },
            deep: true
        },
        itemset: function (itemset) {
            this.dataReady = Boolean(itemset);
        },
        pinnedItems: {
            handler() {
                if(this.isItemsPage && !this.isAppliedFilter) this.isAppliedFilter = true
            },
            deep: true
        },
        currentPage: {
            handler: function (value) {
                this.store.setPageFilter(value)
                this.$nextTick(() => {
                    this.fetchItems()
                })
            }
        }
    },
    components: {
        'filter-card': ItemCardFilter,
        'item-filter-panel': ItemSearchFilterPanel,
    },
    props: {
        website: String,
        websiteType: String,
        websiteId: String,
        filterSchema: Array,
        newItemsetType: String,
        isItemsPage: Boolean, // Indicate if showing this component in Item page
        isVideoAdUser: String,
        presetAssetThumbnail: String,
        presetImageThumbnail: String
    },
    template:
        `
    <div class="itemset-edit-custom">
        <div class="custom-header mb-0">
            <div>
                <ol v-if="!isItemsPage" class="breadcrumb pl-0 mb-0 font-weight-600">
                    <li class="breadcrumb-item">
                        Sets
                    </li>
                    <li class="breadcrumb-item">
                        <a @click="returnLink" class="pseudolink">Edit set</a>
                    </li>
                </ol>
                <div v-else> 
                    <div v-if="isAppliedFilter && !isInSaveSetMode" class="float-right forrest-background p-2 px-4 mr-2 mt-n2 text-white rounded">
                        <p class="m-0">
                            <font-awesome-icon :icon="['fas', 'circle-info']" />
                            Create a new set from this filter?
                              <b-button variant="outline-light" size="sm" class="ml-4 d-inline-block" @click="isInSaveSetMode = true">
                              Create New Set
                              </b-button>
                        </p>
                    </div>
                    <h4 class="page-title">Items</h4>
                </div>
            </div>
            <div v-if="(isItemsPage && isInSaveSetMode) || !isItemsPage" class="d-flex justify-content-between mt-3" :class="{ 'mt-4' : isItemsPage && isInSaveSetMode}">
                <div class="col-lg-6 m-0 p-0">
                    <div id="div_id_name" class="form-group">
                        <b-form-input
                            placeholder="Name for your new set"
                            class="form-control editable-name-control h3 mb-0 font-weight-600 eggplant-color"
                            :class="{ invisible: !dataReady }"
                            :state="isValidating ? nameIsValid : null"
                            v-model="itemsetName"
                            id="id_name"
                            ref="nameInput"/>
                        <b-form-invalid-feedback :state="isValidating ? nameIsValid : null" class="mt-0">Please name this set.</b-form-invalid-feedback>
                        <b-popover v-if="(isItemsPage && isInSaveSetMode)" target="id_name" triggers="hover" placement="topright" variant="info" custom-class="itemset-edit-custom-pop-over">
                            <p class="m-0 text-white">
                                <font-awesome-icon :icon="['fas', 'circle-info']" />
                                Remember to give your set a name.
                            </p>
                        </b-popover>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary px-3" v-on:click="saveItemset()" :disabled="isSaving">{{buttonText}}</button>
             </div>
        </div>
     
        <div class="itemset-edit-content d-flex" :class="{'mt-4': isItemsPage && !isInSaveSetMode, 'short' : (isItemsPage && isInSaveSetMode), 'mid-short' : !isItemsPage }">
            <div class="filter-container col-4 col-lg-3 mt-0 p-0">
                <item-filter-panel
                :filterSchema="filterSchema"
                :store="store"
                />
            </div>
            <div class="col-8 col-lg-9 flex-wrap bg-white p-4 default-theme-border-radius ml-2">
                <div class="row container-fluid m-0 p-0" style="width:100%">
                    <div class="col pt-2 px-0"><h5>{{itemCount}} items<span v-if="isItemsPage && isAppliedFilter"> selected</span></h5></div>
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="totalPages * store.getPageSize()"
                    :per-page="store.getPageSize()"
                    first-text="First"
                    last-text="Last"
                    hide-ellipsis
                    ></b-pagination>
                </div>
                <div class="items-container flex-wrap mt-4 pr-2" ref="itemsContainer">
                    <div v-if="pinnedItems" class="horizontal-scroller p-0 mr-1">
                        <filter-card
                            v-for="item in pinnedItems"
                            :key="item.id"
                            :item="item"
                            :website="website"
                            :websiteType="websiteType"
                            :websiteId="websiteId"
                            :presetAssetThumbnail="presetAssetThumbnail"
                            :presetImageThumbnail="presetImageThumbnail"
                            :class="'d-inline-block align-top mr-3'"
                            :store="store"
                            :isVideoAdUser="isVideoAdUser"
                            v-on:onUpdateItemVisibility="fetchItems"
                        />
                    </div>
                    <div class="product-grid pr-0 mt-2">
                        <filter-card
                            v-for="item in items"
                            :key="item.id"
                            :item="item"
                            :class="{'opacity-50': store.isOnlyPinnedFilterActive()}"
                            :website="website"
                            :websiteType="websiteType"
                            :websiteId="websiteId"
                            :presetAssetThumbnail="presetAssetThumbnail"
                            :presetImageThumbnail="presetImageThumbnail"
                            :store="store"
                            :isVideoAdUser="isVideoAdUser"
                            v-on:onUpdateItemVisibility="fetchItems"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
     `
};

export {ItemsetEditCustom}
