let GTMInstructionsModal = {

    props: {
        id: String,
        code: String
    },
    template: `
        <b-modal
        :id="'gtm-modal-' + id"
        size="lg"
        scrollable
        title="Google Tag Manager Instructions"
        body-class="p-4"
        hide-footer>
            <div class="container">
                <h4>Install AdRetriever Pixel</h4>
                <ol>
                    <li>In Google Tag Manager, create a new tag.</li>
                    <li>Name the tag (e.g. <b>AdRetriever</b>).</li>
                    <li>For the Tag Configuration, choose Custom HTML.</li>
                    <li>Copy and paste this HTML:
                        <textarea class="w-100 bg-light p-3" style="height: 150px;" readonly onfocus="this.select()">{{ code }}</textarea>
                    </li>
                    <li>Under Triggering, choose All Pages, or set up the required filters for your case.</li>
                    <li>Save the tag.</li>
                </ol>
            </div>
        </b-modal>
`
}

export {GTMInstructionsModal};
