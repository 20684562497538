import { DogPlusSvg } from "../../tiers/components/DogPlusSvg.js"
import {usePermissionsStore} from "../../store/PermissionStore";
import {mapState} from "pinia";

let PlanCard = {
    name: "PlanCard",
    data() {
        return {
            permissionsStore : usePermissionsStore(),
            unlimitedValue: "Unlimited",
            planFeatures: [
                {
                    title: 'Advertisers',
                    dataKey: 'website_limit'  // the key in planDetails
                },
                {
                    title: 'Collaborators',
                    dataKey: 'user_limit'
                },
                {
                    title: 'Inventory',
                    dataKey: 'item_limit'
                },
                {
                    title: 'Ads',
                    dataKey: 'ads_limit'
                },
                {
                    title: 'Theme Library',
                    description: 'Selection of themes available to all advertisers'
                },
                {
                    title: 'Custom Themes',
                    dataKey: 'can_have_custom_themes',
                    checkMarkOnly: true
                },
                {
                    title: 'Integrations',
                    items: [
                        {
                            title: 'Meta',
                        },
                        {
                            title: 'Google',
                        },
                        {
                            title: 'Campaign Manager 360',
                        }
                    ]
                },
                {
                    title: 'Data Sources',
                    items: [
                        {
                            title: 'Shopify',
                        },
                        {
                            title: 'WooCommerce',
                        },
                        {
                            title: 'Google Sheets',
                        },
                        {
                            title: 'CSV',
                        },
                        {
                            title: 'Site Walk',
                            dataKey: 'can_have_custom_import',
                        },
                        {
                            title: 'API',
                            dataKey: 'can_have_custom_import',
                        },
                    ]
                }
            ],
            colorClass: {
                cedar: 'bone-teal-lk', // e.g. for button with bg as cedar, we want the dog icon to have bone color, and teal-lk for hover color,
                river: 'bone-teal-lk',
                eggplant: 'marigold-v-teal-lk',
                tealLk: 'teal-lk-bone',
            }
        }
    },
    methods: {
        convertToUnlimited(value) { // If a is grader than 100000000 (One hundred million), it is consider Unlimited
            if(value > 100000000) return this.unlimitedValue
            return value
        },
        onSelectedPlan(){
            this.$emit('onSelectedPlan', this.currentPlanPriceObject?.id, this.planDetails.name)
        }
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions']),
        planName() {
            // e.g. Brand_enterprise
            if (this.planDetails.name.includes('_')) {
                return this.planDetails.name.split('_')[1];
            } else {
                return this.planDetails.name;
            }
        },
        currentPlanPriceObject() { // Plan price changed when user select different currency and/or billing period
            // if(!this.selectedCurrency && !this.selectedBillingPeriods) return 0
            return this.planDetails.prices.find(price => price.currency === this.selectedCurrency.toLowerCase() && price.billing_period === this.selectedBillingPeriods.toLowerCase());
        },
        isCurrentPlan() {
            if(this.currentAccountPlanName == this.planNameDict.basic && this.planName == this.planNameDict.basic) return true
            if(!this.currentAccountPlanPriceId) return false
            return this.currentAccountPlanPriceId === this.currentPlanPriceObject?.id
        }
    },
    props: {
        planDetails: Object,
        planNameDict: Object,
        currentAccountPlanPriceId: String,
        currentAccountPlanName: String,
        state: Object,
        selectedCurrency: String,
        selectedBillingPeriods: String,
        planStyleClasses: String,
        isShowDogIcon: {
            type: Boolean,
            default: true
        }
    },
    components: {
        DogPlusSvg,
    },
    template: `
    <div class="card plan-card col-3 p-3" :class="[{ 'current-plan' : isCurrentPlan }, planStyleClasses]">
        <p v-if="isCurrentPlan" class="current-plan-indicate position-absolute spruce-color font-weight-600">Your current plan:</p>
        <h5 class="plan-name font-weight-bold text-capitalize">{{ planName }}</h5>
        <h1 class="plan-price font-weight-bolder mb-2">&#36;{{ currentPlanPriceObject?.unit_amount || 0 }}</h1>
        <!-- The Plan selection button is only shown if the user is director or admin (can invite users) -->
        <b-button v-if="permissions.user_invite" class="btn-select-plan" :class="{ 'current-plan' : isCurrentPlan }" @click="onSelectedPlan">
            <DogPlusSvg v-if="isShowDogIcon" :fillColorClass="colorClass[planStyleClasses]" />
            <span v-if="isCurrentPlan">Current Plan</span>
            <span v-else>Select Plan</span>
        </b-button>
        <hr>
        <div v-for="feature in planFeatures" class="plan-feature mb-3">
            <p class="font-weight-bold">{{ feature.title }}</p>
            <p v-if="feature.description">
                {{ feature.description }}
            </p>
            <div v-if="feature.dataKey">
                <span v-if="feature.checkMarkOnly">
                    <span class="text-success" v-if="planDetails[feature.dataKey] === true">&#x2713;</span>
                    <span class="text-danger" v-else>&#x2717;</span>
                </span>
                <span v-else>{{ convertToUnlimited(planDetails[feature.dataKey]) }}</span>
            </div>
            <div v-if="feature.items">
                <p class="m-0" v-for="item in feature.items">
                    <span v-if="item.dataKey">
                        <span class="text-success" v-if="planDetails[item.dataKey] === true">&#x2713;</span>
                        <span class="text-danger" v-else>&#x2717;</span>
                    </span>
                    <span v-else>
                        <span class="text-success">&#x2713;</span>
                    </span>
                    <span>{{ item.title }}</span>
                </p>
            </div>  
        </div>
    </div>
    `,
}

export { PlanCard }

