import { ReportItem } from "./ReportItem.js";

let ReportItemList = {
    props: {
        issues: Array,
        type: String,
        staticUrl: String,
        accountId: String
    },
    components: {
        'report-item': ReportItem
    },
    data() {
        return {
            showTierUpgradeButton: false
        }
    },
    methods: {
        checkIssueType() {
            for (let issue of this.issues) {
                // Check if any issue has type 'item_limit_exceeded'
                if (issue.type === 'item_limit_exceeded') {
                    this.showTierUpgradeButton = true; // Set to true if found
                    break; // Exit the loop as we found a match
                }
            }
        }
    },
    template: `
        <div class="px-4">
            <component
            :is="'report-item'"
            v-for="issue in issues"
            :key="issue.type"
            :type="type"
            :issue="issue"
            :staticUrl="staticUrl"
            :accountId="accountId"
            />
        </div>
    `
}

export {ReportItemList}
