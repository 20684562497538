import Vue from 'vue';
import {ReportService} from '../common/api.service.js'
import VueApexCharts from 'vue-apexcharts'

let CTRChartWidget = {
    data: function () {
        return ({
            reportData: [],
            dataReady: false,
            noData: false
        })
    },
    props: {
        website: String,
    },
    methods: {
        getReportData() {
            ReportService.get("website_ctr", this.website).then(response => {
                this.reportData = response.result
                this.dataReady = true
                if (this.reportData.length===0) {
                    this.noData = true
                }
            }).catch(err => {
                this.dataReady = true
                this.noData = true
            })
        }
    },
    mounted: function () {
        this.getReportData()
    },
    template: `
    <div class="dashboard-component shadow-sm bg-white">
        <h5 class="widget-title">CTR by Month</h5>
        <div v-if="!dataReady" class="d-flex justify-content-center">
            <b-spinner class="" variant="secondary" label="Loading..."></b-spinner>
        </div>
        <div v-else-if="noData">
            <h5 class="my-5 no-data text-center">No Data</h5>
        </div>
        <div v-else>
            <component
            :is="'ctrChart'"
            :reportData="reportData"
            />
        </div>
    </div>
    `
}

Vue.component("ctrChart", {
    data: function() {
        return({
            chartOptions: {
                chart: {
                  id: 'ctr-by-month',
                  toolbar: {
                    show: false
                  }
                },
                xaxis: {
                  categories: [],
                    crosshairs: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    labels: {
                        style: {
                            cssClass: 'apexcharts-xaxis-label'
                        }
                    },
                    tickPlacement: 'between',

                },
                colors: ['#0087B5'], // $river
                legend: {
                    show: false
                },
                stroke: {
                    curve: 'smooth',
                },
                markers: {
                    size: 6,
                    colors: ['#0F9F71'], // $grass
                },
                yaxis: {
                    show: false,
                },
                grid: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                noData: {
                    text: 'Loading...'
                },
              },
              series: [{
                name: 'CTR',
                data: []
              }],
        })
    },
    props: {
        reportData: Array
    },
    methods: {
        getChartData() {
            let results = [];
            this.reportData.map(data => {
                results.push({x: data.month, y: data.ctr})
            })
            this.$refs.ctrChart.updateSeries([{
                name: 'CTR',
                data: results
            }], true)
        }
    },
    mounted: function () {
       this.getChartData()
    },
    components: {
        'apexchart': VueApexCharts
    },
    template: `
    <apexchart ref="ctrChart" width="80%" height="150" type="line" :options="chartOptions" :series="series"></apexchart>
    `
})

export {CTRChartWidget}