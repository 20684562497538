import { MerchantCenter } from "./FeedStatusTemplates/MerchantCenter.js"
import { Meta } from "./FeedStatusTemplates/Meta.js"
import { DefaultTemplate } from "./FeedStatusTemplates/DefaultTemplate.js"
import { useIntegrationStore } from "../../store/IntegrationStore.js"
import { mapState } from 'pinia';

const FeedStatus = {
    name: "FeedStatus",
    props: {
        platform: Object,
        staticUrl: String,
        websiteName: String,
        feedDetails: Object,
        getFeedTimeTries: Number,
        isShowStatusModal: Boolean
    },
    data() {
        return {
            platformTemplateByType: {
                google_ads: DefaultTemplate,
                gcm: DefaultTemplate,
                meta: Meta,
                merchant_center: MerchantCenter,
            },
            integrationStore: useIntegrationStore()
        }
    },
    methods: {
        closeModal(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            this.$emit('hideModal')
        }
    },
    computed: {
        ...mapState(useIntegrationStore, [ 'platformStatusMap' ]),
        platformStatusTemplateProps() {
            return {
                platform: this.platform,
                websiteName: this.websiteName,
                feedDetails: this.feedDetails,
                getFeedTimeTries: this.getFeedTimeTries,
                platformStatusMap: this.platformStatusMap,
                // Status and icon
                statusDisplayValue: this.statusDisplayValue,
                icon: this.icon,
                iconColorClass: this.iconColorClass,
                isEnabling: this.isEnabling
            }
        },
        isMetaLoading() {
            if (!this.feedDetails || !this.feedDetails.datafeed_status) {
                // this.loadingStatus = "Getting Feed Status..."
                return true
            } else if (this.isMetaFatalError) {
                return false
            } else if (this.feedDetails?.items_added === 0 && this.feedDetails?.items_rejected === 0) {
                // this.loadingStatus = "Getting Feed Status..."
                return true
            }
            return false
        },
        isMetaFatalError() {
            if (!this.feedDetails) {
                return false
            }
            for (var error_i in this.feedDetails.upload_errors) {
                if (this.feedDetails.upload_errors[error_i]["severity"] === "fatal") {
                    return true
                }
            }
            return false
        },
        statusDisplayValue() {
            switch (this.platform.status) {
                case this.platformStatusMap.ENABLED:
                    return 'Ready'
                case this.platformStatusMap.ENABLING:
                    return 'Creating'
                case this.platformStatusMap.ERROR:
                    return 'Could not create feed'
                default:
                    return ''
            }
        },
        icon() {
            switch (this.platform.status) {
                case this.platformStatusMap.ENABLED:
                    return ['fas', 'circle-check']
                case this.platformStatusMap.ENABLING:
                    return ['fas', 'spinner']
                case this.platformStatusMap.ERROR:
                    return ['fas', 'triangle-exclamation']
                default:
                    return []
            }
        },
        iconColorClass() {
            switch (this.platform.status) {
                case this.platformStatusMap.ENABLED:
                    return 'text-success'
                case this.platformStatusMap.ENABLING:
                    return ''
                case this.platformStatusMap.ERROR:
                    return 'marigold-v-color'
                default:
                    return ''
            }
        },
        isEnabling() {
            return this.platform.status === this.platformStatusMap.ENABLING
        }
    },
    template: `
    <div>
        <p variant="outline" class="font-size-sm px-0 py-2 mb-0">
            <font-awesome-icon class="mr-2" v-if="icon.length" :icon="icon" :class="iconColorClass" :spin="isEnabling" />
            {{ statusDisplayValue }}
        </p>

        <b-modal
        v-model="isShowStatusModal" 
        :title="'Integration status - ' + platform.name"
        scrollable
        centered
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        @close="(e) => closeModal(e)">
            <b-alert show variant="success">
                <font-awesome-icon :icon="['fas', 'circle-info']" />
                <span>To change settings, please reenable this integration.</span>
            </b-alert>
            <component
            :is="platformTemplateByType[platform.type]"
            v-bind={...platformStatusTemplateProps}
            />
        </b-modal> 
    </div>
    `
}

export { FeedStatus };