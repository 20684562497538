let VideoSelectorButton = {
    data(){
        return {}
    },
    props: {
        store: Object
    },
    mounted: function (){
    },
    methods: {},
    template: `
    <div class="row">
        <div class="col-12 px-0">
            <b-button class="video-selector-button px-5"  @click="store.showVideoLibraryModal()" >
                <img v-if="store.assetGroup" :src="store.getThumbnail(store.assetGroup) || preset_asset_thumbnail" class="my-2">
                <font-awesome-icon v-else :icon="['fas', 'image']" />
            </b-button>
        </div>
    </div>`
}

export { VideoSelectorButton }