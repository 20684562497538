import {ItemSetService} from "../common/api.service.js";

const ItemsetEditMixin = {
    data: function () {
        return {
            itemset: null,
            itemsetName: null,
            selectedType: null
        }
    },
    computed: {
        nameIsValid: function () {
            return Boolean(this.itemsetName);
        }
    },
    methods: {
        fetchItemSet: async function () {
            return ItemSetService.init(this.website).get(this.$route.params.id).then(
                itemset => this.handleItemSetResponse.apply(this, [itemset])
            );
        },
        handleItemSetResponse: function (itemset) {
            this.itemset = itemset;
            this.itemsetName = this.itemset.name;
            this.selectedType = this.itemset.type;
            this.store.updateItemset(this.itemset);
            this.store.updateFilter(this.itemset.item_filter);
            return itemset;
        }
    }
};
export {ItemsetEditMixin};