import {LogoCardList} from './LogoCardList.js'

let LogoModal = {
    data() {
        return {
            highlightedLogo: this.store.getLogo(),
        }
    },
    props: {
        website: String,
        store: Object,
        preset_image_thumbnail: String,
    },
    methods: {
        highlightLogo(logo) {
            this.highlightedLogo = logo;
        },
        select(bvModalEvt) {
            bvModalEvt.preventDefault();
            // Pass in isApplyColourToAdTheme = false, so the default colour of new selected logo will NOT override current ad's colour
            this.store.updateLogo(this.highlightedLogo, false);
            this.$bvModal.hide('modal-logo');
        },
        cancel() {
            this.highlightedLogo = this.store.getLogo() || null
            this.$bvModal.hide('modal-logo');
        },
    },
    watch: {
        'store.ad.logo': function(newValue, oldValue) {
            this.highlightLogo(newValue)
        }
    },
    components: {
        LogoCardList
    },
    template: `
        <b-modal
            id="modal-logo"
            scrollable
            size="xl"
            title="Logo">
                <LogoCardList 
                    class="logo-selector" 
                    :highlightedLogo="highlightedLogo" 
                    :preset_image_thumbnail=preset_image_thumbnail 
                    :website="website"
                    :highlightLogo="highlightLogo"
                    :store="store"/>
                <template #modal-footer>
                    <div class="w-100">
                        <div v-if="highlightedLogo" class="align-items-center d-flex justify-content-between m-2">
                            <div class="align-items-center d-flex w-75"></div>
                            <div class="w-25">
                                <b-button variant="primary" class="float-right m-1"
                                @click=select>
                                    Select
                                </b-button>
                                <b-button variant="secondary" class="float-right m-1"
                                @click=cancel>
                                    Cancel
                                </b-button>
                            </div>
                        </div>
                        <div v-else class="justify-content-end">
                            <b-button variant="secondary" class="float-right m-1"
                            @click=cancel>
                                Cancel
                            </b-button>
                        </div>
                    </div>
                </template>
        </b-modal>
`
}

export {LogoModal};












