import Vue from 'vue'
import { AdThemeCard } from "./AdThemeCard.js";
import {AdsService, ThemeVersionsService} from "../common/api.service.js"
import { usePermissionsStore } from "../store/PermissionStore";
import { mapState } from "pinia";

let AdThemeCardList = {
    components: {
        AdThemeCard
    },
    data: function () {
        return ({
            permissionsStore : usePermissionsStore(),
            themes: null,
            customThemes: null,
            ads: null,
            selectedAd: null,
            ad: "",
            hover: false,
            activeTab: 0, // Default active the first tab
        })
    },

    methods: {
        getAds() {
            let adsService = AdsService.init(this.website);
            adsService.query({
                expand: "theme_config,itemset,theme_config__theme_version,theme_config__theme_version__templates",
                only: 'id,name,theme_config,itemset__name'
            }, this.isVideo ? 'video' : null)
            .then(response => {
                this.ads = response.results;
            });
        },

        getThemeAndCustomFieldsFromEvent(type, obj) {
            let theme = null;
            let customFields = {};
            if (type === 'ad') {
                // If it's an ad, we want to choose that ad's (child) theme's parent theme
                theme = Object.assign({}, obj.theme_config.theme_version);
                // We also want to copy the custom fields from the child theme
                Object.assign(customFields, obj.theme_config.custom_fields);
            }
            else {
                // Otherwise we're expecting a theme from the list of top-level themes
                theme = obj;
                for (const [key, value] of Object.entries(obj.custom_fields)) {
                    if (new Set(['body_font', 'heading_font']).has(key)) {
                        customFields[key] = value
                    }
                }
            }
            return [theme, customFields];
        },

        onClickedItem(type, obj) {
            this.highlightTheme(...this.getThemeAndCustomFieldsFromEvent(type, obj));
        },
        selectRow(type, ad) {
            this.onClickedItem(type, ad);
            this.selectedAd = ad;
        },

        // For AdThemeModal & AdThemeCardList
        getArgs() {
            let args = [this.website, {
                'itemset_id': this.store.ad.itemset.id,
                // 'theme_version_id': this.store.ad.theme_config.theme_version.id,
                'type': this.isVideo ? 'video' : 'html',
                'item_type': this.store.state.itemType,
                'website_id': this.store.state.websiteId,
                'latest_version_only': true
            }];

            if (!this.isVideo) {
                args[1]['dynamic_templates'] = true;
                args[1]['single_dynamic_template'] = true; // Rendering only 300x250

                // Iterate over custom_fields and add them to the args object
                for (let field in this.store.ad.theme_config.custom_fields) {
                    if (this.store.ad.theme_config.custom_fields.hasOwnProperty(field)) {
                        args[1][field] = this.store.ad.theme_config.custom_fields[field];
                    }
                }
            }

            return args;
        },

        filterThemes(results) {
            this.themes = results.filter(theme => !theme.is_custom);
            this.customThemes = results.filter(theme => theme.is_custom);
        },


        getThemes() {
            const queryMethod = ThemeVersionsService.get_themes_previews.bind(ThemeVersionsService);
            const args = this.getArgs();

            queryMethod(...args)
                .then(response => {
                    this.filterThemes(response.results);
                    this.reorderThemes();
                });
        },

        reorderThemes() {
            let highlightedThemeId = this.store.ad.theme_config.theme_version?.id;

            // Check if highlightedThemeId exists in this.themes or this.customThemes
            let themeIndex = this.themes.findIndex(theme => theme.id === highlightedThemeId);
            let isCustom = false;

            if (themeIndex === -1) {
                // If not found in this.themes, look in this.customThemes
                themeIndex = this.customThemes.findIndex(theme => theme.id === highlightedThemeId);
                isCustom = true;
            }

            // If highlightedThemeId is not found in both lists, exit the function
            if (themeIndex === -1) return;

            // Extract the highlighted theme
            const highlightedTheme = isCustom ? this.customThemes.splice(themeIndex, 1)[0] : this.themes.splice(themeIndex, 1)[0];

            // Place the highlighted theme at the beginning of the appropriate list
            if (isCustom) {
                this.customThemes.unshift(highlightedTheme);
            } else {
                this.themes.unshift(highlightedTheme);
            }
        }
    },
    mounted: function () {
        this.getAds();
        this.getThemes();
    },
    activated: function () {
        this.getAds();
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions']),
        tierUpgradeDogImageUrl: function () {
            return this.activeTab === 1 ? this.staticUrl + 'img/doge_plus_eggplant.png' : this.staticUrl + 'img/doge_plus.png';
        }
    },
    watch: {
        activeTab(newValue, oldValue) {
            this.$emit('updateModalFooter', newValue)
        }
    },
    props: {
        isVideo: Boolean,
        website: String,
        preset_image_thumbnail: String,
        highlightedTheme: Object,
        store: Object,
        highlightTheme: Function,
        staticUrl: String
    },
    template:
        `
    <div>
        <keep-alive>
          <b-tabs v-model="activeTab">
            <b-tab title="Standard" class="mt-3">
                <keep-alive>
                    <div v-if="themes === null" class="m-5 text-center"><b-spinner></b-spinner></div>
                    <div v-else class="row ml-4">
                        <AdThemeCard
                        v-for="theme in themes"
                        :theme="theme"
                        :key="theme.id"
                        :preset_image_thumbnail="preset_image_thumbnail"
                        :currentSelection="highlightedTheme ? highlightedTheme.id : null"
                        v-on:update="onClickedItem"
                        />
                    </div>
                </keep-alive>
            </b-tab>
            <b-tab :active="highlightedTheme?.is_custom" class="mt-3">
                <template #title>
                    Custom
                    <img v-if="!permissions.tiered_themeversion_add_temporary" class="align-top" :src="tierUpgradeDogImageUrl">
                </template>
                <keep-alive>
                    <div v-if="customThemes === null" class="m-5 text-center"><b-spinner></b-spinner></div>
                    <div v-else class="row ml-4">
                        <div v-if="!customThemes.length && permissions.tiered_themeversion_add_temporary">No custom themes</div>
                        <div v-else-if="!customThemes.length && !permissions.tiered_themeversion_add_temporary" class="d-flex align-items-baseline">
                            <p class="mr-4">Looks like you’ve discovered custom themes. Upgrade now to upload custom themes!</p>
                        </div>
                        <AdThemeCard
                        v-else
                        v-for="theme in customThemes"
                        :theme="theme"
                        :key="theme.id"
                        :preset_image_thumbnail="preset_image_thumbnail"
                        :currentSelection="highlightedTheme ? highlightedTheme.id : null"
                        v-on:update="onClickedItem"
                        />
                    </div>
                </keep-alive>
            </b-tab>
            <b-tab title="Copy From Ad">
                <div v-if="ads === null" class="m-5 text-center"><b-spinner></b-spinner></div>
                <div v-else-if="Object.keys(ads).length !== 0">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Ad</th>
                          <th>Set</th>
                          <th>Theme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="ad in ads" :key="ad.id" :style="{ cursor: 'pointer'}" @click="selectRow('ad', ad)" :class="{'highlight': (ad == selectedAd)}">
                           <td>{{ ad.name }}</td>
                           <td>{{ ad.itemset.name }}</td>
                           <td>{{ ad.theme_config.name }}</td>
                        </tr>
                       </tbody>
                    </table>
                </div>
                <div v-else class="mt-4">
                   <p>No ads available</p>
                </div>
            </b-tab>
          </b-tabs>
        </keep-alive>
    </div>
    `,
}

export {AdThemeCardList}
