import {VideoLibraryModal} from './VideoLibraryModal.js';
import {VideoSelectorButton} from './VideoSelectorButton.js';
import { store } from '../store/VideoLibraryStore.js'

let VideoLibraryStoreInit = (dataStore, website, websiteId) => {
    dataStore.init(website, websiteId)
}

let VideoLibrary = {
    data(){
        return {
            store: store(),
        }
    },
    watch: { 
        itemAssetGroup: function(newVal) {
            // Update the store using the selected video from db record
            this.store.assetGroup = newVal
        },
      'store.assetGroup': {
        handler() {
            this.$emit('updateParentVideoAssetGroup', this.store.assetGroup);
        },
        deep: true
    },
    },
    props: {
        website: String,
        website_id: String,
        preset_asset_thumbnail: String,
        itemAssetGroup: Object, // Only used once to update the store with asset that is stored with item / ad
    },
    mounted: function (){
        VideoLibraryStoreInit(this.store, this.website, this.website_id);
        if(this.website){
            this.updateStore()
        }
    },
    components: {
        'video-selector-button': VideoSelectorButton,
        'video-library-modal': VideoLibraryModal
    },
    methods: {
        async updateStore() {
            this.store.init(this.website)
            this.store.preset_asset_thumbnail = this.preset_asset_thumbnail
            let theItemAssetGroup = await this.isItemAssetDeleted(this.itemAssetGroup) ? {...this.itemAssetGroup, deleted: true} : this.itemAssetGroup
            this.store.updateAssetGroupAfterInit(theItemAssetGroup);
            // Update tempAsset so in VideoManagementTab, the item's selected video will be shown as selected
            this.store.resetTempAssetGroup();
        },
        // Add a field in item asset if it is deleted, for disable selection and deletion in video library tab
        async isItemAssetDeleted(itemAssetGroup){
             // Return if no item asset group for this item
            if(!itemAssetGroup || Object.keys(itemAssetGroup).length === 0 && itemAssetGroup.constructor === Object) return;
            try {
                const response = await this.store.assetGroupService.get(itemAssetGroup.id)
                if(response) return false
            } catch (e) {
                // Only return true if the asset group is not found in db = soft deleted
                if(e.response.data.detail === "Not found.") return true
            }
        }
    },
    template: `
    <div class="row">
        <div class="col-12 px-0">
            <h6 class="mt-2">Background Video: {{ store.assetGroup?.name }}</h6>
            <component
            class="video-library-component px-3"
            is="video-selector-button"
            :store="store"
            />
            <component
            class="video-library-component"
            is="video-library-modal"
            :store="store"
            :isItemAssetDeleted=isItemAssetDeleted
            />
        </div>
    </div>`
}


export { VideoLibrary }