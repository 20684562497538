import { Chrome } from 'vue-color';

let ColorPickerCustom = {
    name: "ColorPickerCustom",
    data: function () {
        return {
            colors: {
                hex: '#000000', // default value
            },
            currentColorValue: '',
            displayPicker: false,
            debounceTimer: null,
            isShowEyeDropper: true
        };
    },
    props: {
        colorValue: String,
        customFieldsName: String
    },
    methods: {
        // Color picker functions
        setColor(color) {
            this.updateColors(color);
            this.currentColorValue = color;
        },
        showPicker() {
            document.addEventListener('click', this.documentClick);
            this.displayPicker = true;
        },
        hidePicker() {
            document.removeEventListener('click', this.documentClick);
            this.displayPicker = false;
        },
        togglePicker() {
            this.displayPicker ? this.hidePicker() : this.showPicker();
        },
        updateColors(color) {
            if (color.slice(0, 1) == '#') {
                this.colors = {
                    hex: color
                };
            }
            // Handle rgba value when user manipulate the alpha channel
            else if (color.slice(0, 4) == 'rgba') {
                var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
                    hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
                this.colors = {
                    hex: hex,
                    a: rgba[3],
                }
            }
        },
        updateFromPicker(color) {
            this.colors = color;
            if (color.rgba.a === 1) {
                this.setColor(color.hex);
            }
            else {
                this.setColor('rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')');
            }
        },
        documentClick(e) {
            var el = this.$refs.colorPicker,
                target = e.target;
            if (el !== target && !el.contains(target)) {
                this.hidePicker();
            }
        },

        // Eyedropper functions
        openEyedropper() {
            const eyeDropper = new window.EyeDropper();
            eyeDropper
                .open()
                .then((result) => {
                    this.setColor(result.sRGBHex);
                })
                .catch((e) => {
                    console.log('Eyedropper error:', e);
                });
        },
        updateEyeDropperDisplay() {
            if (!window.EyeDropper) {
                this.isShowEyeDropper = false;
            }
        }
    },
    watch: {
        colorValue(val) {
            this.setColor(val)
        },
        currentColorValue(val) {
            if (val) {
                // Debounce
                clearTimeout(this.debounceTimer);
                this.debounceTimer = setTimeout(() => {
                    this.updateColors(val);
                }, 300); // Delay in milliseconds
            }
        },
        colors(val) { // Triggered by updateColors()
            if (val.hex && !val.source) {
                // Only update store when user not dragging the dropper inside vue-color
                this.$emit('updateStoreColor', this.customFieldsName, val.hex);
            }

        }
    },
    components: {
        'chrome-color-picker': Chrome
    },
    mounted() {
        this.setColor(this.colorValue);
        this.updateEyeDropperDisplay();
    },
    template:
        `
        <div class="color-picker" ref="colorPicker">
            <div class="color-picker-container p-2 border rounded d-flex flex-column flex-nowrap">
                <div class="align-self-center align-items-center d-flex flex-nowrap">
                    <button v-if="isShowEyeDropper" @click="openEyedropper" id="start-button" class="border-0 bg-transparent">
                        <font-awesome-icon :icon="['fas', 'eye-dropper']" />
                    </button> 
                    <span class="color-picker-toggle current-color ml-1" :style="'background-color: ' + currentColorValue" @click="togglePicker()"></span>
                </div>
                <span class="current-color-value text-uppercase text-center font-size-sm" @click="togglePicker()">
                    {{ currentColorValue }}
                </span>
            </div>
            <chrome-color-picker :disableAlpha="true" :value="currentColorValue" @input="updateFromPicker" v-if="displayPicker" />
        </div>
    `
}

export { ColorPickerCustom };
