import {ReportService} from "./common/api.service.js";

let PunisherModal = {
	data() {
		return {
			modalShow: false,
			res: {},
			biggestRangeItem: "",
			time: 0,

		}
	},
	created() {
		const date = new Date();
		const thirtyDaysAgo = new Date(date.getTime() - (30 * 24 * 60 * 60 * 1000));
		ReportService.query({
				website: this.website_id,
				ordering: "-end",
				start_gte: thirtyDaysAgo.toISOString()}, "PUNISHER")
			.then(response => {
			let result = response.results.length ? JSON.parse(response.results[0].result) : ''
			let itemSet = this.getItemsetWithMostItems(result)
			this.res = this.getItems(response.results, itemSet)
		});
	},
	computed: {
		top5Items() {
			// Get 5 items with the biggest range (max(score) - min(score))
			if (Object.keys(this.res).length == 0) {
				return {}
			}
			const items = Object.entries(this.res);
			const sortedItems = items.sort((a, b) => {
				const rangeA = Math.max(...a[1]) - Math.min(...a[1]);
				const rangeB = Math.max(...b[1]) - Math.min(...b[1]);
				return rangeB - rangeA;
			});
			this.biggestRangeItem = sortedItems[0][0];

			const res = sortedItems.slice(0, 5);

			return res.reduce((obj, item) => {
				obj[item[0]] = item[1];
				return obj;
				}, {});
		}

	},
	props: {
		website_id: String,
		value: Boolean
	},
	methods: {
		getItems(reports, itemSet_key) {
			// Get the scores from ~30 reports for each item
			// Store the scores in an object of arrays
			let res = {}
			this.time = Math.min(reports.length, 30)
			for (let i = 0; i < this.time; i++) {
				let report_result = JSON.parse(reports[i].result)
				if (itemSet_key in report_result) {
					let itemSet = report_result[itemSet_key]
					itemSet.forEach(item => {
						if (!res[item[0]]) {
							res[item[0]] = [];
						}
						res[item[0]].unshift(item[1]);
					  });
				}
			}
			return res
		},
		getItemsetWithMostItems(data) {
			let itemsetWithMostItems = "";
			let maxItems = 0;
			for (let itemset in data) {
			  if (data[itemset].length > maxItems) {
				maxItems = data[itemset].length;
				itemsetWithMostItems = itemset;
			  }
			}
			return itemsetWithMostItems
		},

		plot() {
			if (Object.keys(this.top5Items).length != 0) {
				let data = []
				let x = Array.from({length: this.time}, (_, i) => i + 1);
				for (const item in this.top5Items) {
					data.push({
						name: item,
						x: x,
						y: this.top5Items[item],
						type: 'scatter'
					})
				}

				let layout = {
					  title: 'Punisher Score vs Time',
					  xaxis: {
						title: 'Reports (~days)',
						showgrid: false,
						zeroline: false
					  },
					  yaxis: {
						title: 'Score',
						showline: false
					  },
				};
				Plotly.newPlot(this.$refs.punisher_plot, data, layout);
			}
		},
	},
	watch: {
		res: {
			handler(n, o) {
				this.plot()
			}
		},
		value(newValue) {
            this.modalShow = newValue;
        },
        modalShow(newValue) {
            this.$emit('input', newValue);
        },
	},
	components: {
	},
	template: `
	<div>
	    <b-modal modal-title="Punisher chart"  v-model="modalShow">
			<div ref="punisher_plot"></div>
	    </b-modal>
	</div>
`
}

export {PunisherModal};













