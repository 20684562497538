import {VideoManagementTab} from './tabs/VideoManagementTab.js';
import {VideoUploadTab} from './tabs/VideoUploadTab.js';

let VideoLibraryModal = {
    data(){
        return {}
    },
    components: {
        'video-management-tab': VideoManagementTab,
        'video-upload-tab': VideoUploadTab
    },
    props: {
        store: Object,
        isItemAssetDeleted: Function
    },
    methods: {
        emitStoreCancelAndCloseModal(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            this.store.cancelAndCloseModal()
        },
        getPaginatedVideoAssetGroups() {
            this.store.assetGroupService.query(
                this.store.getQueryParams()
            ).then(response => {
                this.updateStore(response)
            });
        },
        async updateStore(response) {
            // Update asset group 'deleted' if it was deleted
            let storeAssetGroup = Object.assign({}, this.store.assetGroup)
            if( await this.isItemAssetDeleted(storeAssetGroup) ) this.store.assetGroup = {...storeAssetGroup, deleted: true}
            // Update store after assetGroup updated
            this.store.updateStoreAfterGetVideoAssetGroups(response)
        },
        onChangeTab(tabName){
            this.store.activeTab = tabName
        },
    },
    template: ` 
    <b-modal 
    id="video-library-modal" 
    class="video-library-modal"
    content-class="default-theme-border-radius"
    body-class="p-4 overflow-auto eggplant-color"
    header-class="border-0"
    size="lg" 
    v-model="store.isShowVideoLibraryModal"
    hide-footer
    no-close-on-backdrop
    @cancel="emitStoreCancelAndCloseModal"
    @close="emitStoreCancelAndCloseModal"
    >
        <template #modal-title>
            <h4 class="m-0 eggplant-color font-weight-600">Background Video</h4>
        </template>

        <b-tabs 
        content-class="mt-3"
        nav-class="eggplant-color hello"
        active-nav-item-class="font-weight-bold eggplant-color">
            <b-tab title="Upload" :active="store.activeTab === 'upload'" @click="onChangeTab('upload')"> 
                <component
                class="video-tab-component"
                is="video-upload-tab"
                :store="store"
                v-on:getPaginatedVideoAssetGroups="getPaginatedVideoAssetGroups"
                />
            </b-tab>

            <b-tab title="Video Library" class="video-management-tab" :active="store.activeTab === 'management'" @click="onChangeTab('management')">
                <component
                class="video-tab-component"
                is="video-management-tab"
                :store="store"
                v-on:getPaginatedVideoAssetGroups="getPaginatedVideoAssetGroups"
                />
            </b-tab>
        </b-tabs>
    </b-modal>`
}

export { VideoLibraryModal }