import {ItemService} from '../common/api.service.js'
import { ItemCardFilter } from '../ItemCards.js';
import { store } from '../store/ItemsetsStore.js'

let RecentlyDiscoveredWidget = {
    data: function () {
        return ({
            items: [],
            dataReady: false,
            noData: false,
            store: store(),
            itemDetailModalKey: 'recently-discovered-widget'
        })
    },
    methods: {
        getItemsData() {
            ItemService.init(this.websiteName).query({ordering: "-first_seen", page_size: '4'})
        .then(response => {
                this.items = response.results
                if(this.items.length === 0) {
                    this.noData = true
                }
                this.dataReady = true
            })
        },
    },
    mounted: function() {
        this.getItemsData()
    },
    props: {
        websiteName: String,
        websiteId: String,
        websiteType: String,
        itemsLink: String,
        isVideoAdUser: String,
        presetAssetThumbnail: String,
        presetImageThumbnail: String
    },
    components: {
        'filter-card': ItemCardFilter
    },
    template: `
        <div class="dashboard-component shadow-sm bg-white">
            <div class="d-flex justify-content-between">
                <h5 class="widget-title">Recently Discovered</h5>
                <a :href="itemsLink" class="d-block text-right mb-3">View All</a>
            </div>
            <div v-if="!dataReady" class="d-flex justify-content-center my-5">
                <b-spinner class="" variant="secondary" label="Loading..."></b-spinner>
            </div>
            <div v-else-if="noData">
                <h5 class="my-5 no-data text-center">No Data</h5>
            </div>
            <div v-else class="horizontal-scroller d-flex p-0 pb-3 mb-2">
                <filter-card
                    v-for="item in items"
                    :modalKey="itemDetailModalKey"
                    :key="item.id"
                    :item="item"
                    :website="websiteName"
                    :websiteType="websiteType"
                    :websiteId="websiteId"
                    :presetAssetThumbnail="presetAssetThumbnail"
                    :presetImageThumbnail="presetImageThumbnail"
                    :class="'d-inline-block align-top mr-3'"
                    :store="store"
                    :isVideoAdUser="isVideoAdUser"
                    :hidePin="true"
                    v-on:onUpdateItemVisibility="getItemsData"
                />
            </div>
        </div>
    `
}

export {RecentlyDiscoveredWidget}
