import { PublishFileDropzone } from "./components/PublishFileDropzone.js";
import { ThemeVersionsService } from "../common/api.service.js";
import { convertDatetime } from "../utils/date_utils.js";

let ThemeDetails = {
    name: "ThemeDetails",
    props: {
        website: String,
        template_url: String,
        item_type: String,
        publishFileUploadURL: String,
        entry_point: String,
        accountId: String
    },
    data: function () {
        return ({
            themeId: this.$route.params.themeId ? this.$route.params.themeId : '',
            themeDetails: null,
            versionList : [],
            themeName: '',
            isEditingThemeName: false,
            isValidating: false,
            isSaving: false,
            isTableLoading: true,
            dropzoneErrorMessage: null,
            versionTableErrorMessage: null,
            /* Table related data */
            selectedVersions: [],
            sortByField: 'number',
            versionsTableFields: [
                {
                    key: 'selected',
                    label: '',
                },
                {
                    key: 'number',
                    label: "Version",
                    sortable: true
                },
                {
                    key: 'ads_using.length',
                    label: "Ads In Use",
                    sortable: true
                },
                {
                    key: 'last_updated',
                    label: "Last Updated",
                    sortable: true
                },
                {
                    key: 'source_file',
                    label: "Source File",
                },
            ]
        })
    },
    mounted: function () {
        this.getThemeDetails();
    },
    methods: {
        getThemeDetails() {
            ThemeVersionsService.getThemes(this.themeId, {}).then(response => {
                // Handle user deleted all theme versions, but somehow can still query the record
                if(response.latest_version == null || response.deleted == true){
                    this.$router.push({ name: 'theme-versions-list' })
                }
                this.themeDetails = response
                // Theme's last updated time = latest version's last updated time
                this.themeDetails.last_updated = this.convertTimestamp(response.versions.find(version => version.number === response.latest_version).last_updated)
                this.themeName = response.name
                this.versionList = response.versions
                this.versionList.map(version => ({...version, selected: false}))
                this.updatePageTitle(response.name)
                this.isTableLoading = false
            })
            .catch(response => {
                // Handle if user deleted all theme versions, should redirect back to list page
                if(response.response.status === 404 || response.response.status === 500){
                    this.$router.push({ name: 'theme-versions-list' })
                }
            });
        },
        updatePageTitle(pageTitle) {
            this.$emit('updatePageTitle', pageTitle)
        },
        editThemeName() {
            this.isEditingThemeName = true
            this.$nextTick(() => {
                this.$refs.themeNameInput.focus()
            })
        },
        cancelEditThemeName() {
            this.isEditingThemeName = false
            // Reset name
            this.themeName = this.themeDetails.name
            this.isSaving = this.isValidating = false
        },
        saveThemeName() {
            this.isSaving = this.isValidating = true;

            if (!this.isThemeNameValid) {
                this.isSaving = false;
                return;
            }

            ThemeVersionsService.patchTheme(this.themeId, this.themeName).then(response => {
                this.isEditingThemeName = false
                this.updatePageTitle(response.data.name)
            });
        },
        /* Table related functions */
        onRowSelected(versions) {
            versions.length ? this.selectedVersions = versions : this.selectedVersions = []
        },
        onRowChanged(index, isSelected, totalAdsCount, id) {
            // Check if this row is selectable, only rows that has 0 total_ads can be select
            totalAdsCount === 0 ? isSelected ? this.$refs.versionsTable.selectRow(index) : this.$refs.versionsTable.unselectRow(index) : ''

            if (isSelected) {
                this.selectedVersions.push(this.$refs.versionsTable.items[index]);
            }
        },
        onRowClicked(version){
            this.$router.push({ name: 'version-details', params: { 'themeId': version.theme_id, 'versionId' : version.id } })
        },
        selectRowByIndex() {
            let deletableVersionIds = this.getDeletableVersionRows().map(version => {
                return version.id;
            });

            this.$refs.versionsTable.items.forEach((item, idx) => {
                if (deletableVersionIds.includes(item.id)) {
                    this.$refs.versionsTable.selectRow(idx)
                }
            });
        },
        getDeletableVersionRows() {
            return this.versionList.filter(version => version.ads_using.length === 0)
        },
        toggleAllSelection() {
            this.allSelected ? this.$refs.versionsTable.clearSelected() : this.selectRowByIndex();
        },
        deleteSelectedVersions() {
            this.versionTableErrorMessage = null // Reset error msg
            let selectedVersionIds = this.selectedVersions.map(version => version.id)
            // Warn if user deleting all theme versions
            if(selectedVersionIds.length === this.themeDetails.versions.length) {
                this.$bvModal.msgBoxConfirm('We noticed that you are about to delete all versions under the theme. Please be aware that this action will also delete the theme itself, as it will have no versions left.', {
                    title: 'Are you sure?'
                }).then((result) => {
                    if (result) {
                        this.bulkDeleteThemeVersions(selectedVersionIds)
                    }
                })
            }else{
                this.bulkDeleteThemeVersions(selectedVersionIds)
            }
        },
        bulkDeleteThemeVersions(selectedVersionIds) {
            var params = { version_ids: selectedVersionIds, type: this.themeDetails.type };
            ThemeVersionsService.bulkDeleteThemeVersions(params).then(response => {
                this.$nextTick(() => {
                    this.getThemeDetails()
                });
            })
            .catch(response => {
                let versionNumbersAdUsing = this.themeDetails.versions
                    // Get the version number base on response's theme version id
                    .filter(version => response.response.data.version_ids_using_ads.includes(version.id))
                    .map(version => version.number)
                    // Sort the array by version number
                    .sort()
                    // Concat them with comma
                    .join(',')
                this.versionTableErrorMessage = response.response.data.error + `The versions associated with ads are: ${versionNumbersAdUsing}`
            });
        },
        dropzoneUploadSuccessCallback(response) {
            if (this.entry_point === 'admin_tools') {
                this.$router.push({ name: 'version-details', params: { themeId: response.theme_id, versionId: response.theme_version_id }, query: { from: 'upload' } })
            } else {
                if (response.type === 'html') {
                    this.$router.push({ name: 'theme-preview', params: { id: response.theme_version_id } })
                } else {
                    this.$router.push({ name: 'version-details', params: { themeId: response.theme_id, versionId: response.theme_version_id }, query: { from: 'upload' } })
                }
            }
        },
        convertTimestamp(timestamp) {
            return convertDatetime(timestamp)
        }
    },
    components: {
        publishFileDropzone: PublishFileDropzone
    },
    computed: {
        dropzoneComponentProps: function () {
            return {
                website: this.website,
                template_url: this.template_url,
                item_type: this.item_type,
                collapsible: false,
                pageName: 'themeDetails', // using page name for determine action text in dropzone
                themeId: this.themeId,
                publishFileUploadURL: this.publishFileUploadURL,
                accountId: this.accountId
            }
        },
        hasDeletableVersion: function () {
            return this.getDeletableVersionRows()?.length
        },
        allSelected: function () {
            return this.selectedVersions.length === this.getDeletableVersionRows().length
        },
        isThemeNameValid: function () {
            return Boolean(this.themeName);
        }
    },
    template: `
    <div class="theme-details-container">
        <div v-if="!themeDetails" class="loading-overlay page-loading-overlay position-absolute d-flex justify-content-center align-items-center">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-container v-else class="d-flex justify-content-between flex-column">
            <b-row class="justify-content-between">
                <b-col cols="6" class="theme-details-info p-0">
                    <div v-if="isEditingThemeName" class="d-flex">
                        <div class="col-8">
                            <b-form-input
                            placeholder="Theme name"
                            class="form-control editable-name-control h1 mb-0"
                            :state="isValidating ? isThemeNameValid : null"
                            v-model="themeName"
                            ref="themeNameInput"/>
                            <b-form-invalid-feedback :state="isValidating ? isThemeNameValid : null" class="mt-0">Invalid theme name.</b-form-invalid-feedback>
                        </div>
                        <div class="col-6 align-self-center">
                            <b-button class="mr-1" variant="primary" @click="saveThemeName">Save</b-button>
                            <b-button variant="secondary" @click="cancelEditThemeName">Cancel</b-button>
                        </div>
                    </div>
                    <div v-else class="text-break">
                        <h1 class="d-inline m-0">{{ themeName }}</h1>
                        <div class="d-inline align-text-bottom mx-2" @click="editThemeName">
                            <font-awesome-icon icon="pencil-alt" size="lg" class="pb-1" />
                        </div>
                    </div>
                    <p class="m-1">
                        <span>{{ themeDetails.total_ads }}</span>
                        <span v-if="themeDetails.total_ads > 1">ads</span>
                        <span v-else>ad</span>
                        <span>in use</span>
                        <span class="badge rounded-pill cedar-border cedar-color px-2 ml-2">
                            <font-awesome-icon v-if="themeDetails.type === 'html'" :icon="['fas', 'code']" />
                            <font-awesome-icon v-else :icon="['fas', 'video-camera']" />
                        </span>
                    </p>
                    <p class="m-1 text-muted">Last updated: {{ themeDetails.last_updated }}</p>
                </b-col>
                <b-col cols="5" class="ml-5 p-0">
                    <publishFileDropzone
                    v-bind="{...dropzoneComponentProps}" 
                    v-on:dropzoneUploadSuccessCallback="dropzoneUploadSuccessCallback"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="p-0">
                    <div class="versions-table mt-4">
                        <div class="d-flex justify-content-between mb-3">
                            <div class="mb-3 col-9 p-0">
                                <h5>Versions</h5>
                                <p vi-if="versionTableErrorMessage?.length" class="mb-0 text-danger">{{ versionTableErrorMessage }}</p>
                            </div>
                            <div>
                                <b-button v-if="selectedVersions.length" variant="danger" @click="deleteSelectedVersions">Delete selected</b-button>
                            </div>
                        </div>
                        <div>
                            <b-table ref="versionsTable" class="default-theme-border-radius table-rounded" table-variant="light" thead-class="default-theme-border-radius border-0 forrest-background text-white" :busy="isTableLoading"
                                id="list-table"
                                :sort-by="sortByField"
                                primary-key="id"
                                :fields="versionsTableFields"
                                :items="versionList"
                                v-model="versionList"
                                hover
                                outlined
                                no-border-collapse
                                selectable
                                no-select-on-click
                                select-mode="multi"
                                @row-selected="onRowSelected"
                                @row-clicked="onRowClicked"
                                >
                                <template #table-busy>
                                    <div class="text-center my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                    </div>
                                </template>
                                <template #head(selected)="data">
                                    <b-form-checkbox v-if="hasDeletableVersion" :checked="allSelected" @change="toggleAllSelection" class="prevent-select"></b-form-checkbox>          
                                </template>
                                
                                <template #cell(selected)="data">
                                    <b-form-checkbox v-if="data.item.ads_using.length == 0" :checked="data.rowSelected" @change="onRowChanged(data.index, !data.rowSelected, data.item.ads_using.length, data.item.id)"></b-form-checkbox>
                                </template>

                                <template #cell(number)="data">
                                    <router-link :to="{ name: 'version-details', params: { themeId: themeId, versionId: data.item.id }}">{{ data.item.number }}</router-link>
                                </template>

                                <template #cell(last_updated)="data">
                                    {{ convertTimestamp(data.item.last_updated) }}
                                </template>

                                <template #cell(source_file)="data">
                                    <a v-if="data.item.source_file?.length" target="_new_blank" :href="data.item.source_file">
                                        <font-awesome-icon :icon="['fas', 'download']" />
                                    </a>
                                    <p v-else>-</p>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
    `
}

export { ThemeDetails }

