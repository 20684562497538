import ApiService, { WebsitesService } from '../common/api.service.js';
import { usePermissionsStore } from '../store/PermissionStore.js';
import { mapState } from 'pinia';
import { StatusAlert } from "../common/components/StatusAlert.js"


let AutoBGRemoval = {
    name: "AutoBGRemoval",
    props: {
        websiteName: String,
    },
    data() {
        return {
            permissionsStore: usePermissionsStore(),
            isBGRemovalEnabled: null,
            isShowAlert: false,
            isSuccess: false,
            responseMsg: null
        }
    },
    methods: {
        updateSetting() {
            this.isShowAlert = false
            this.responseMsg = null

            ApiService.post(`${this.websiteName}/manage-bg-removal-setting`, {
                remove_background: !this.isBGRemovalEnabled
            }).then(response => {
                this.isSuccess = true
                this.isShowAlert = true
                this.isBGRemovalEnabled = response.data.remove_background
                this.responseMsg = response.data.message
            }).catch(err => {
                this.isSuccess = false
                this.isShowAlert = true
                this.responseMsg = null
            });
        },
        getWebsite() {
            WebsitesService.get(`${this.websiteName}/`).then(response => {
                this.isBGRemovalEnabled = response.remove_background
            })
        }
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions', 'isInitialized']),
    },
    mounted () {
        this.getWebsite();
    },
    components: {
        StatusAlert,
    },
    template: `
    <div class="auto-bg-removal-container">
        <StatusAlert :isSuccess="isSuccess" :successMessage="responseMsg" :isShowAlert="isShowAlert" />
        <div v-if="isInitialized" class="mb-3 border p-3 bg-light default-theme-border-radius shadow d-flex justify-content-between align-items-center">
            <h5 class="m-0">Auto background removal</h5>

            <b-button v-if="permissions.tiered_manage_bg_removal" type="submit" variant="primary" @click="updateSetting">
                <span v-if="isBGRemovalEnabled">Disable</span>
                <span v-else>Enable</span>
            </b-button>

            <a v-else class="btn float-right btn-tier-restriction-html" href="{% url 'frontend:account-billing-view' request.session.retriever_account_id %}">
                <span class="img-upgrade d-inline-block">&nbsp;</span>
                Enable
            </a>
                    
        </div>
    </div>
    `
}

export { AutoBGRemoval };


