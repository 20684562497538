import vue2Dropzone from 'vue2-dropzone'
import Cookies from "js-cookie";
import Vue from 'vue'
import {mapState} from "pinia";
import {usePermissionsStore} from "../../store/PermissionStore";
import { TierUpgradeButton } from "../../tiers/TierUpgradeButton.js"

let PublishFileDropzone = {
    props: {
        website: String,
        template_url: String,
        item_type: String,
        collapsible: Boolean,
        pageName: String,
        themeId: String,
        isShowHelp: {
            default: true,
            type: Boolean
        },
        publishFileUploadURL: String,
        staticUrl: String,
        accountId: String
    },
    data: function () {
        return ({
            modalKey: "CustomThemesCreation",
            permissionsStore : usePermissionsStore(),
            isDropzoneExpanded: false,
            tempAttachments: [], // For display uploaded files and progress
            maxProgressValue: 100,
            files: [], // Store original dropped file, use for abort upload function and upload to endpoint
            // Text based on showing the dropzone's page name
            displayActionText: {
                'themesList' : 'add an theme',
                'themeDetails' : 'add an version'
            },
            // Send to backend to indicate if the file is for add new theme or new version base on pageName
            addRecordType :{
                'themesList' : 'new_theme',
                'themeDetails' : 'new_version'
            },
            statusList: {
                default: 'processing', // Default asset's status, each asset status will keep updating after uploaded to endpoint, only used in frontend
                /* Status from backend */
                created: 'created', // Control if enable delete function, deletion is available after the video is uploaded to S3 and created record in DB
                success: 'validated', // Final stage status of asset, to determine if all process in backend is finished
                fail: 'failed' // Error status, show errors from backend if asset has this status
            },
            isUploading: false,
            isShowDropzoneErrorState: false, // Control showing error state display
            hasDropzoneError: false, // Control to stop proceed when error occurs, reset in nextTick after detected error
            dropzoneErrorMessage: "",
            isDownloadTemplateError: false,
            downloadTemplateResponseMsg: "",
            downloadTemplateSuccessMsg: "A sample feed related to the items has been added to the GWD theme ZIP file and has been successfully downloaded. You can open this file in Google Web Designer to start working.",
            dropzoneOptions: {
                // The Url Where Dropped or Selected files will be sent
                url:  'url',
                headers: {
                    "X-CSRFToken": Cookies.get('csrftoken')
                },
                // Param name for the file when send file to endpoint 
                paramName: function(n) {
                    return "file";
                },
                maxFiles: 1, // Allow only one file to upload
                maxFilesize: 100, // File Size allowed in MB
                acceptedFiles: ".zip",
                includeStyling: true,
                previewsContainer: false, // Use default preview container
                autoProcessQueue: false, // Stop auto upload so we can stop proceed if having errors
                uploadMultiple: false, // Send multiple files in one request
                addRemoveLinks: true,
                timeout: 0, // Milliseconds, set to null / 0 to disable timeout
            }
        })
    },
    watch: {
        isDropzoneExpanded: {
            handler: function (value) {
                this.$emit("updateDropzoneExpandedStatus", this.isDropzoneExpanded)
            }
        }
    },
    methods: {
        collapseDropzone() {
            this.resetDropzone()
            this.isDropzoneExpanded = false
        },
        expandDropzone() {
            this.isDropzoneExpanded = true
            this.resetDropzone()
        },
        resetDropzone(){
            this.isShowDropzoneErrorState = false
            this.hasDropzoneError = false
            this.dropzoneErrorMessage = ""
            // Clear files
            if(this.$refs.sourceFileDropzone) this.$refs.sourceFileDropzone.removeAllFiles()
            if(this.tempAttachments) this.tempAttachments = []
        },
        /* Drop zone flow and functions */
        dropzoneMounted() {
            this.$refs.sourceFileDropzone?.setOption('url', this.publishFileUploadURL);
        },
        // function called for every file dropped or selected
        fileAdded(file) {
            // Reset error state
            this.isShowDropzoneErrorState = false

            // Store the file blob in array
            this.files = [...this.files, file]

            // Construct file object to render in the UI
            let attachment = {};
            attachment._id = file.upload.uuid;
            attachment.isLoading = true;
            attachment.progress = null;
            attachment.status = this.statusList.default;
            attachment.addRecordType = this.addRecordType[this.pageName]
            attachment.isError = false
            attachment.error = []

            this.tempAttachments = [...this.tempAttachments, attachment];

            // Check if having any error
            this.$nextTick(() => {
                if(!this.hasDropzoneError) {
                    this.$refs.sourceFileDropzone.processQueue()
                    this.isUploading = true
                }
            })
            
        },
        // a middle layer function where you can change the XHR request properties
        sendingFiles(file, xhr, formData) {
            formData.append("website", this.website);
            formData.append("type", this.addRecordType[this.pageName]);
            formData.append("name", file.name);
            formData.append("theme_id", this.themeId);
        },
        // function where we get the upload progress
        uploadProgress(file, progress, bytesSent) {
            this.tempAttachments.map(attachment => {
                if (attachment._id === file.upload.uuid) {
                    attachment.progress = `${Math.floor(progress)}`;
                }
            });
        },
        // called on successful upload of a file
        uploadSuccess(file, response){
            this.isUploading = false
            const tempAttachmentsIdx = this.getTempAttachmentIdxByUploadUUID(file)
            if (response === undefined) {
                this.tempAttachments[tempAttachmentsIdx].status = this.statusList.fail
            }

            // Clear file
            if(this.pageName === 'themePreview') {
                this.removeFile(this.tempAttachments[tempAttachmentsIdx]);
            }
            this.$emit("dropzoneUploadSuccessCallback", response);
        },
        dropzoneError(file, message, xhr){
            if(this.isUploading) this.isUploading = false
            this.isShowDropzoneErrorState = true // Display error state
            this.hasDropzoneError = true
            this.dropzoneErrorMessage = message.error

            // Clear files after detected error
            this.$refs.sourceFileDropzone.removeAllFiles()

            this.$nextTick(() => {
                // Reset at next tick so when user put correct file again, processQueue() will be called
                this.hasDropzoneError = false
            })
        },
        removeFile(fileToRemove){
            // Remove files in list in display
            const tempAttachmentsIndex = this.getTempAttachmentsIndex(fileToRemove)
            if (tempAttachmentsIndex > -1) {
                // Will only call api to delete file if asset status is or after created
                if(this.tempAttachments[tempAttachmentsIndex].status !== this.statusList.default && this.tempAttachments[tempAttachmentsIndex].assetId) {
                    this.store.assetsService.destroy(this.tempAttachments[tempAttachmentsIndex].assetId)
                    .then(response => {
                        this.tempAttachments.splice(tempAttachmentsIndex, 1)
                    });
                }else{
                    this.tempAttachments.splice(tempAttachmentsIndex, 1)
                }
            }
            // Get the file in files list, remove and abort request
            const fileIndex = this.files.map(f => f.upload.uuid).indexOf(fileToRemove._id);
            if(fileIndex >= 0 && this.files[fileIndex].xhr) this.files[fileIndex].xhr.abort() // Stop posting request
            this.files.splice(fileIndex, 1) // Remove file from file list
        },
        /* Utils */
        getTempAttachmentsIndex(targetAttachment){
            return this.tempAttachments.indexOf(targetAttachment)
        },
        checkProgress(attachment) {
            return attachment.progress === null ? false : true;
        },
        getProgressBarVariant(status) {
            switch (status) {
                case this.statusList.success:
                  return 'success'
                case this.statusList.fail:
                    return 'danger'
                default:
                    return 'info'
            } 
        },
        getTempAttachmentIdxByUploadUUID(file){
            return this.tempAttachments.map(attachment => attachment._id).indexOf(file.upload.uuid);
        },
        downloadTemplate() {
            this.closeDownloadTemplatePopover()
            this.downloadTemplateResponseMsg = ""
            this.isDownloadTemplateError = false

            Vue.axios.get('/api/' + this.website +  "/download_template", {
                responseType: 'blob'
            }).then(response => {
                let fileName = response.headers['content-disposition'].split('filename=')[1].split('.')[0]
                let blob = new Blob([response.data], { type: 'application/zip' });
                let url = window['URL'].createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                window['URL'].revokeObjectURL(url);

                this.downloadTemplateResponseMsg = this.downloadTemplateSuccessMsg
                this.openDownloadTemplatePopover()
            })
            .catch(async error => {
                let errorString = error.response.data;
                if (
                  error.request.responseType === 'blob' &&
                  error.response.data instanceof Blob &&
                  error.response.data.type &&
                  error.response.data.type.toLowerCase().indexOf('json') != -1
                ) {
                  // Extract error message
                  errorString = JSON.parse(await error.response.data.text());
                }
                this.isDownloadTemplateError = true
                this.downloadTemplateResponseMsg = errorString.error
                this.openDownloadTemplatePopover()
            })
        },
        closeDownloadTemplatePopover() {
            this.$refs.downloadTemplatePopover.$emit('close');
        },
        openDownloadTemplatePopover() {
            this.$refs.downloadTemplatePopover.$emit('open');
            // Hide pop over after showing for few seconds
            setTimeout(() => { 
                this.closeDownloadTemplatePopover()
            }, 5000);
        }
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions', 'isInitialized']),
        getTempAttachments() {
          return this.tempAttachments;
        },
        hasAttachments: function () {
            return this.tempAttachments.length
        },
        isAllAssetsInFinalStage: function () {
            return this.tempAttachments.every((attachment) => attachment.status === this.statusList.fail || attachment.status === this.statusList.success );
        },
        isAllAssetsValidated: function () {
            return this.tempAttachments.every((attachment) => attachment.status === this.statusList.success );
        },
    },
    components: {
        vueDropzone: vue2Dropzone,
        TierUpgradeButton
    },
    template: `
    <div class="publish-file-dropzone file-dropzone w-100 d-flex flex-column">

        <div v-if="collapsible && !isDropzoneExpanded && isInitialized" class="expanded-dropzone align-self-end">
          <TierUpgradeButton
          :isShowDefault="permissions.tiered_themeversion_add_temporary"
          buttonText="New Theme"
          :modalKey="modalKey"
          :staticUrl="staticUrl" 
          :isShowShadow="false"
          :accountId="accountId"
          @defaultFunction="expandDropzone"/>
        </div> 

        <div v-if="collapsible && isDropzoneExpanded || !collapsible" class="expanded-dropzone position-relative d-flex flex-column">
            <b-popover ref="downloadTemplatePopover" triggers="manual" target="help-section" placement="topright" fallback-placement="clockwise" custom-class="text-white" :variant="isDownloadTemplateError ? 'danger' : 'success'" @click="closeDownloadTemplatePopover()">
                {{ downloadTemplateResponseMsg }}
            </b-popover>
            <div v-if="isShowHelp" id="help-section" class="align-self-end">
                <p class="m-0" id="dropzone-help">Need some help?</p>
                <b-popover target="dropzone-help" triggers="hover" placement="top">
                    <a href="#" v-on:click.prevent.stop="downloadTemplate()">Download template</a><br>
                    <a target="_blank" href="https://webdesigner.withgoogle.com/">Download Google Web Designer</a>
                </b-popover>
            </div>

            <div v-if="collapsible && !tempAttachments.length > 0" class="collapse-dropzone-btn position-absolute">
                <b-button class="mx-2 mt-2 mb-1" variant="light" @click="collapseDropzone">
                    <font-awesome-icon :icon="['fas', 'angles-up']" size="xl" />
                </b-button>
            </div>

            <div v-if="tempAttachments.length > 0 && !isShowDropzoneErrorState"  class="file-list w-100 px-3 py-5 position-absolute bg-white">
                <ul v-if="tempAttachments.length > 0" class="list-unstyled">
                    <li v-for="tempAttachment in tempAttachments" :key="tempAttachment._id" class="mb-3">
                        <p>
                            <font-awesome-icon class="text-secondary" :icon="['fas', 'spinner']" spin />
                            Uploading: <b>{{ tempAttachment.title }}</b>
                        </p>
                        <b-progress 
                        v-if="checkProgress(tempAttachment)" 
                        :value="tempAttachment.progress" 
                        :max="maxProgressValue" 
                        :animated="tempAttachment.status === statusList.success || tempAttachment.status === statusList.fail ? false : true"
                        :variant="getProgressBarVariant(tempAttachment.status)"></b-progress>
                        <p class="text-center mt-2">Please hold on while uploading, you will be redirected soon.</p>
                    </li>
                </ul>
            </div>

            <vue-dropzone
            class="rounded"
            :class="{'error bg-danger text-white darken-hover' : isShowDropzoneErrorState }"
            ref="sourceFileDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @vdropzone-mounted="dropzoneMounted"
            @vdropzone-file-added="fileAdded"
            @vdropzone-sending="sendingFiles"
            @vdropzone-upload-progress="uploadProgress"
            @vdropzone-success="uploadSuccess"
            @vdropzone-error="dropzoneError"
            >
                <div class="file-selector">
                    <div v-if="!isShowDropzoneErrorState" class="default-view">
                        <font-awesome-icon :icon="['far', 'file']" class="mb-4" size="2xl" />
                        <p class="m-0">Upload a theme file to <b>{{ displayActionText[pageName] }}</b></br>Browse files, or drop here</p>
                    </div>
                    <div v-else class="error-view">
                        <font-awesome-icon class="mb-4" :icon="['fas', 'circle-exclamation']" size="2xl" />
                        <p class="m-0">Error: {{ dropzoneErrorMessage }} 
                        <br>Please try uploading again.
                        </p>
                    </div>
                </div>
            </vue-dropzone>
        </div>
    </div>
    `
}

export { PublishFileDropzone }

