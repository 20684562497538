/**
 *
 * This store is for the theme that are being previewed in theme preview page. Only infos
 * needed to be show/edit in theme preview page will be stored in this store.
 *
 * theme_config.custom_fields also being use as default value to pass as query when getting
 * theme preview in theme list page
 *
 */
const store = () => {
    return {
        id: '', //Theme version id
        name: '',
        logo: null,
        templates: [],
        publishFile: null,
        sourceFile: null,
        theme_config: {
            custom_fields: {
                // We only need these two fields for getting theme preview
                headline: "You don't want to miss out!",
                call_to_action: "SHOP NOW",
                scaling: "contain"
            },
            theme_version: null
        },
        previewItems: {
            items: [],
            currentItemIdx: 0, // index in items[] = the item (id) that sent to theme preview API
            pageNumber: 1,
            itemCount: 0,
            totalPages: 0
        },
        state: {
            ready: false,  // if the store is ready to be rendered
            previewItemsReady: false,
            websiteId: null,
        },
        init(website_id) {
            this.state.ready = true
            // Reset
            this.name = ''
            this.id = ''
            this.sourceFile = null
            this.publishFile = null
            this.state.websiteId = website_id
        },
        getLogo() {
            return this.logo;
        },
        updateLogo(response) {
            this.logo = response;
        },
        getCustomFieldValueByKey(key) {
            return this.theme_config.custom_fields[key]
        },
        updateTheme(response) {
            this.templates = response.templates
            if(response.name.length) this.name = response.name
        },
        updateId(id) {
            this.id = id
        },
        getId() {
            return this.id
        },
        getName() {
            return this.name
        },
        updatePreviewItemsByKey(key, value) {
            this.previewItems[key] = value
            if (key === 'items' && !this.previewItemsReady) {
                this.previewItemsReady = true
            }
        },
        getCurrentItemId() {
            return this.previewItems.items[this.previewItems.currentItemIdx].id
        },
        updateCurrentItemIdx() {
            if (this.previewItems.currentItemIdx !== this.previewItems.items.length - 1) {
                this.previewItems.currentItemIdx++
            } else {
                this.previewItems.currentItemIdx = 0
            }
        },
        getPreviewItemsLength() {
            return this.previewItems.itemCount
        },
        updateSourceFile(url){
            this.sourceFile = url
        },
        // type: source / publish
        getFile(type) {
            let fileType = type + 'File'
            if(!this[fileType]) return false
            return {
                url: this[fileType],
                name: this[fileType].substring(this[fileType].lastIndexOf('/')+1)
            }

        }
    }
}

export {
    store
};
