import {WebsitesService} from '../common/api.service.js'

let FirstAdStepsWidget = {
    data: function () {
        return {
            isFirstAdHidden: false,
        }
    },
    computed: {
        isImporting: function () {
            return Boolean(this.websiteImportStatus) && this.websiteImportStatus.import_end_time === null;
        },
        dataSourceStateIcon: function() {
            return this.getIcon(this.isDataSourceComplete)
        },
        importStateIcon: function () {
            return this.getIcon(this.isImportComplete)
        },
        createAdStateIcon: function() {
            return this.getIcon(this.isCreateAdComplete)
        },
        readyStateIcon: function() {
            return this.getIcon(this.isCreateAdComplete)
        },
        marginTop: function() {
            return this.isCreateAdComplete ? "mt-4" : ""
        },
        closeButton: function () {
            return this.isCreateAdComplete ? "" : "d-none"
        },
        isDataSourceComplete: function () {
            return ["data_source", "import", "ad"].includes(this.firstAdState);
        },
        isImportComplete: function () {
            return ["import", "ad"].includes(this.firstAdState);
        },
        isCreateAdComplete: function () {
            return ["ad"].includes(this.firstAdState);
        }
    },
    methods: {
        getIcon: function(step) {
            return step ? ['fas', 'circle-check'] : ['far', 'circle']
        },
        hideFirstAd(){
            // This should only execute the first time the close button is clicked
            this.isFirstAdHidden = true
            WebsitesService.update(`${this.websiteName}/`, {"is_first_ad_hidden": true})
        }
    },
    props: {
        firstAdState: String,
        adsLink: String,
        importLink: String,
        dataSourceLink: String,
        websiteName: String,
        websiteID: String,
        websiteImportStatus: Object
    },
    template: `
      <div v-if="!isFirstAdHidden" class="dashboard-component shadow-sm bg-white">
        <div class="d-flex">
          <div class="p-2 w-100 text-center mb-5"><h5 class="eggplant-color font-weight-600">Let's create your first
            ad!</h5></div>
          <div class="p-2 flex-shrink-1">
            <button @click="hideFirstAd()" type="button" class="close" :class="closeButton" aria-label="Dismiss"
                    title="Dismiss">
              <span aria-hidden="true">&times;</span></button>
          </div>
        </div>
        <div class="first-create-wrapper mb-4" :class="marginTop">
          <div class="d-flex flex-column justify-content-center align-items-center first-create">
            <font-awesome-icon :icon="dataSourceStateIcon" class="first-create-icon first-check lake-color"/>
            <a v-if="firstAdState === 'base'" :href="dataSourceLink" class="mb-0 mt-3 first-create-text pseudolink">Set
              up data source</a>
            <p v-else class="mb-0 mt-3 first-create-text">Set up data source</p>
          </div>

          <div class="d-flex flex-column justify-content-center align-items-center first-create">
            <font-awesome-icon v-if="!isImporting" :icon="importStateIcon" class="first-create-icon river-color"/>
            <font-awesome-icon v-else-if="isImporting" :icon="['fas', 'circle-notch']" spin
                               class="first-create-icon first-check river-color"
                               style="font-size:24px; border-radius:20px;" v-b-tooltip.hover title="Importing data">
              <span class="sr-only"></span>
            </font-awesome-icon>

            <a v-if="firstAdState === 'data_source' && !isImporting" :href="importLink"
               class="mb-0 mt-3 first-create-text pseudolink">Import data</a>
            <p v-else class="mb-0 mt-3 first-create-text">Import data</p>
          </div>

          <div class="d-flex flex-column justify-content-center align-items-center first-create">
            <font-awesome-icon :icon="createAdStateIcon" class="first-create-icon cedar-color"/>
            <a v-if="firstAdState === 'import'" :href="adsLink" class="mb-0 mt-3 first-create-text pseudolink">Create
              your first ad</a>
            <p v-else class="mb-0 mt-3 first-create-text">Create your first ad</p>
          </div>

          <div class="d-flex flex-column justify-content-center align-items-center first-create">
            <font-awesome-icon :icon="readyStateIcon" class="first-create-icon last-check forrest-color"/>
            <p class="mb-0 mt-3 first-create-text">You're ready!</p>
          </div>
        </div>
      </div>

    `,
}

export {FirstAdStepsWidget}