import Cookies from "js-cookie";
import {TempUploadedVideoList} from "./TempUploadedVideoList.js";
import {AssetGroupService} from "../../common/api.service.js";
import vue2Dropzone from 'vue2-dropzone'


let VideoUploadTab = {
    data(){
        return {
            assetGroupName: "",
            tempAttachments: [], // For display uploaded files and progress
            files: [], // Store original dropped file, use for abort upload function and upload to endpoint
            statusList: {
                default: 'processing', // Default asset's status, each asset status will keep updating after uploaded to endpoint, only used in frontend
                /* Status from backend */
                created: 'created', // Control if enable delete function, deletion is available after the video is uploaded to S3 and created record in DB
                success: 'validated', // Final stage status of asset, to determine if all process in backend is finished
                fail: 'failed' // Error status, show errors from backend if asset has this status
            },
            formError: {
                groupName: false
            },
            saveAssetGroupErrorMsg: '', // Error msg from backend when create asset group
            getAssetsStatusInterval: null,
            assetGroupService: AssetGroupService.init(this.store.website),
            showSaveAssetGroupSuccessMsg: false,
            supportedVideoDimensions: [ // Tooltip's content when having dimension error after uploaded video
                '1920 x 1080',
                '1080 x 1080',
                '1080 x 1920'
            ],
            dropZoneError: { // Self define the error object from dropzone, ref structure to errors return by backend validation function
                code: "dropzone_error",
                message: ''
            },
            dropzoneOptions: {
                // The Url Where Dropped or Selected files will be sent
                url: window.location.origin + `/api/${this.store.website}/assets/large_file_upload/`,
                headers: {
                    "X-CSRFToken": Cookies.get('csrftoken')
                },
                // Param name for the file when send file to endpoint
                paramName: function(n) {
                    return "file";
                },
                maxFilesize: 100, // File Size allowed in MB
                acceptedFiles: ".mov, .mp4, .ogg, .webm, .avi",
                includeStyling: true,
                previewsContainer: false, // Use default preview container
                thumbnailWidth: 250,
                thumbnailHeight: 140,
                parallelUploads: 20,
                // autoProcessQueue: false, // Stop auto upload //Start again this.$refs.myVueDropzone.processQueue()
                chunking: true,
                // Uncomment below setting when enable chunking
                chunkSize: 6 * 1024 * 1024, // Bytes,
                parallelChunkUploads: false,
                uploadMultiple: false, // Send multiple files in one request
                addRemoveLinks: true,
                timeout: 0, // Milliseconds, set to null / 0 to disable timeout
                chunksUploaded: (file, done) => {
                    this.store.assetsService.query({"name": file.upload.uuid}).then(response=> {
                        this.uploadSuccess(file, response.results[0])
                        done()
                    })
                }
            }
        }
    },
    components: {
        vueDropzone: vue2Dropzone,
        TempUploadedVideoList: TempUploadedVideoList
    },
    computed: {
        getTempAttachments() {
          return this.tempAttachments;
        },
        hasAttachments: function () {
            return this.tempAttachments.length
        },
        isAllAssetsInFinalStage: function () {
            return this.tempAttachments.every((attachment) => attachment.status === this.statusList.fail || attachment.status === this.statusList.success );
        },
        isAllAssetsValidated: function () {
            return this.tempAttachments.every((attachment) => attachment.status === this.statusList.success );
        },
    },
    watch: { 
        hasAttachments: function () {
            this.store.hasAttachments = this.hasAttachments
        }
    },
    props: {
        store: Object
    },
    methods: {
        /* Drop zone flow and functions */
        // function called for every file dropped or selected
        fileAdded(file) {
            // Store the file blob in array
            this.files = [...this.files, file]

            // Construct file object to render in the UI
            let attachment = {};
            attachment._id = file.upload.uuid;
            attachment.title = file.name;
            attachment.type = "file";
            attachment.extension = "." + file.type.split("/")[1];
            attachment.size = file.size;
            attachment.isLoading = true;
            attachment.progress = null;
            attachment.status = this.statusList.default;
            attachment.assetId = null // Will be updated after video uploaded to backend
            attachment.isError = false
            attachment.error = []
            attachment.dimension = ""

            this.tempAttachments = [...this.tempAttachments, attachment];
        },
        // a middle layer function where you can change the XHR request properties
        sendingFiles(file, xhr, formData) {
            formData.append("website", this.store.website_id);
            formData.append("name", file.name);
            formData.append("type", "video")
        },
        // function where we get the upload progress
        uploadProgress(file, progress, bytesSent) {
            this.tempAttachments.map(attachment => {
                if (attachment._id === file.upload.uuid) {
                    attachment.progress = `${Math.floor(progress)}`;
                }
            });
        },
        // called on successful upload of a file
        uploadSuccess(file, response){
            const tempAttachmentsIdx = this.getTempAttachmentIdxByUploadUUID(file)
            if (response === undefined) {
                this.tempAttachments[tempAttachmentsIdx].status = this.statusList.fail
            }
            if (response.id) {
                this.tempAttachments[tempAttachmentsIdx].assetId = response.id
                this.tempAttachments[tempAttachmentsIdx].status = response.status
            }

            if(this.getAssetsStatusInterval) return
            this.getAssetsStatusInterval = setInterval(()=>{ 
                this.getAssetsStatus();
            }, 5000);
        },
        getAssetsStatus(){
            // Clear timer if all assets has reached to final stage or no temp attachment
            if(this.isAllAssetsInFinalStage || !this.tempAttachments.length){
                this.clearGetAssetsInterval(this.getAssetsStatusInterval);
                return;
            }

            const allAssetIds = this.getAllProcessingTempAttachmentsAssetId()
            if(!allAssetIds.length) return
            this.store.assetsService.query(
                {
                    asset_ids : allAssetIds.toString()
                }
            ).then(response => {
                if (response) {
                    // Update temp attachments' status base on response
                    response.results.forEach((asset) => {
                        const thisTempAttachmentIdx = this.getTempAttachmentIdxByAssetId(asset)
                        this.tempAttachments[thisTempAttachmentIdx].status = asset.status
                        if (asset.status !== this.statusList.fail) {
                            this.tempAttachments[thisTempAttachmentIdx].dimension = asset.size?.width + " x " + asset.size?.height
                        }
                        // Update error msg if fail
                        if (asset.status === this.statusList.fail) {
                            // Get each error msg
                            this.tempAttachments[thisTempAttachmentIdx].error = asset.data.error
                        }
                    });
                }
            });
        },
        removeFile(fileToRemove){
            // Remove files in list in display
            const tempAttachmentsIndex = this.getTempAttachmentsIndex(fileToRemove)
            if (tempAttachmentsIndex > -1) {
                // Will only call api to delete file if asset status is or after created
                if(this.tempAttachments[tempAttachmentsIndex].status !== this.statusList.default && this.tempAttachments[tempAttachmentsIndex].assetId) {
                    this.store.assetsService.destroy(this.tempAttachments[tempAttachmentsIndex].assetId)
                    .then(response => {
                        this.tempAttachments.splice(tempAttachmentsIndex, 1)
                    });
                }else{
                    this.tempAttachments.splice(tempAttachmentsIndex, 1)
                }
            }
            // Get the file in files list, remove and abort request
            const fileIndex = this.files.map(f => f.upload.uuid).indexOf(fileToRemove._id);
            if(fileIndex >= 0 && this.files[fileIndex].xhr) this.files[fileIndex].xhr.abort() // Stop posting request
            this.files.splice(fileIndex, 1) // Remove file from file list

            // Stop interval if no temp attachments left
            if(!this.tempAttachments.length){
                this.clearGetAssetsInterval();
            }
        },
        dropzoneError(file, message, xhr){
            // Catching wrong file type error
            const thisTempAttachmentIdx = this.getTempAttachmentIdxByUploadUUID(file)
            this.tempAttachments[thisTempAttachmentIdx].status = this.statusList.fail
            // Get each error msg
            let newDropzoneError = this.dropZoneError
            newDropzoneError.message = message
            this.tempAttachments[thisTempAttachmentIdx].error.push(newDropzoneError)
        },
        saveVideoGroup(){
            if(!this.isAllAssetsInFinalStage || !this.isAllAssetsValidated) return

            this.resetFormError();
            // Error checking
            if(!this.assetGroupName.length) {
                this.formError.groupName = true
                return
            }

            this.assetGroupService.create(
                {
                    asset_ids: this.tempAttachments.filter((file) => file.assetId !== null).map(file => file.assetId),
                    name: this.assetGroupName,
                    website: this.store.website_id
                }
            ).then(response => {
                this.showSaveAssetGroupSuccessMsg = true;
                setTimeout(function () {
                    // Hide video saved message and get asset group after 2 secs
                    this.getNewlySavedAssetGroup(response.data.id);
                    // Refresh management tab asset group list
                    this.showSaveAssetGroupSuccessMsg = !this.showSaveAssetGroupSuccessMsg;
                }.bind(this), 2000);
            })
            .catch(error => {
                this.saveAssetGroupErrorMsg = error.response.data.detail
                // Scroll container to the top
                this.$refs.dropzoneContainer.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
                return error;
            })
        },
        /* Utils */
        getTempAttachmentsIndex(targetAttachment){
            return this.tempAttachments.indexOf(targetAttachment)
        },
        getAllProcessingTempAttachmentsAssetId(){
            // Ignored attachment that has reached to final stage of upload
            return this.tempAttachments.filter((file) => file.assetId !== null && file.assetId !== undefined && file.status !== (this.statusList.success || this.statusList.fail)).map(file => file.assetId)
        },
        getTempAttachmentIdxByUploadUUID(file){
            return this.tempAttachments.map(attachment => attachment._id).indexOf(file.upload.uuid);
        },
        getTempAttachmentIdxByAssetId(asset){
            return this.tempAttachments.map(attachment => attachment.assetId).indexOf(asset.id);
        },
        clearGetAssetsInterval() {
            clearInterval(this.getAssetsStatusInterval);
            this.getAssetsStatusInterval = null;
        },
        resetTab(){
            if(this.getAssetsStatusInterval) this.clearGetAssetsInterval()
            if(this.tempAttachments) this.tempAttachments = []
            if(this.files) this.files = []
            this.assetGroupName = ''
            this.store.isShowConfirmQuitModal = false
            // Abort uploading files
            this.$refs.videoDropzone.$emit("removeAllFiles");
            // Reset all form errors
            this.resetFormError();
        },
        resetFormError(){
            Object.keys(this.formError).forEach(error => this.formError[error] = false)
            this.saveAssetGroupErrorMsg = ''
        },
        getNewlySavedAssetGroup(assetGroupId) {
            this.store.assetGroupService.get(
                assetGroupId, this.store.getQueryParams()
            ).then(response => {
                this.store.tempAssetGroup = response
                this.store.onSelectBackgroundVideo()
                this.store.closeModal()
                this.resetTab()                
            });
        },
        /* Confirm to close */
        closeConfirmModal() {
            this.store.isShowConfirmQuitModal = false
        },
        confirmCloseVideoLibraryModal() {
            this.resetTab();
            this.store.closeModalOnUploadTab();
        }
    },
    template: `
    <div class="container video-upload-tab-content p-0">
        <div class="dropzone-container pb-5 pt-4 px-3 flex-row" :class="{'overflow-hidden': !hasAttachments}" ref="dropzoneContainer">
            <div :class="saveAssetGroupErrorMsg.length ? 'show' : 'hide'" class="asset-group-error-message mb-3 bg-danger rounded py-2 px-2 col-7 fade align-self-start text-white">
                <font-awesome-icon :icon="['fas', 'xmark']" />
                <span class="">Failed to save video group:</span>
                <p class="m-0 pl-3 ">{{saveAssetGroupErrorMsg}}</p>
            </div>
            <div :class="showSaveAssetGroupSuccessMsg ? 'show' : 'hide'" class="asset-group-saved-message col bg-success rounded py-2 mb-4 pl-3 col-5 fade">
                <font-awesome-icon class="text-white" :icon="['fas', 'circle-check']" />
                <span class="text-white">Video group saved!</span>
            </div>
            <div v-if="hasAttachments" class="video-group-name col align-self-start pb-4 pl-0 w-50">
                <label for="fname">Video Group Name</label>
                <input placeholder="Enter video group name" class="form-control" validation="required" v-model="assetGroupName"/>
                <p v-if="formError.groupName" class="text-danger mb-0">Please enter a name for the video group</p>
            </div>
            <TempUploadedVideoList
            v-if="hasAttachments"
            :tempAttachments="getTempAttachments"
            :statusList="statusList"
            :supportedVideoDimensions="supportedVideoDimensions"
            v-on:removeFileMethod="removeFile"
            />
            <vue-dropzone
            class="col mx-auto"
            :class="hasAttachments ? 'w-100 py-3 small-dropzone' : 'w-75 mt-5'"
            ref="videoDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @vdropzone-upload-progress="uploadProgress"
            @vdropzone-file-added="fileAdded"
            @vdropzone-sending="sendingFiles"
            @vdropzone-success="uploadSuccess"
            @vdropzone-removed-file="removeFile"
            @vdropzone-error="dropzoneError"
            >
                <div class="file-selector" :class="hasAttachments ? 'd-flex' : ''">
                    <font-awesome-icon :class="hasAttachments ? 'mr-3 fa-2x' : 'fa-5x mb-4'" :icon="['far', 'file-video']" :size="hasAttachments ? 'lg' : '2xl'" />
                    <p>Browse video files, or drop here</p>
                </div>
            </vue-dropzone>
        </div>
        <div class="d-flex col my-3 pr-4 justify-content-end">
            <div class="">
                <b-button class="cancel-upload-btn align-self-end" @click="store.cancelAndCloseModal()" variant="outline-secondary">Cancel</b-button>
                <b-button
                v-if="hasAttachments"
                class="save-video-group-btn align-self-end ml-3" 
                :class="{disabled: !isAllAssetsValidated}"
                variant="primary"
                @click="saveVideoGroup">
                    <span v-if="isAllAssetsInFinalStage">Save Video Group</span>
                    <span v-else>Uploading...</span>
                </b-button>
            </div>
        </div>
        <b-modal 
        :visible="store.isShowConfirmQuitModal" 
        no-close-on-esc 
        no-close-on-backdrop 
        centered 
        content-class="default-theme-border-radius"
        body-class="p-4 overflow-auto eggplant-color"
        header-class="border-0"
        footer-class="border-0"
        @cancel="closeConfirmModal"
        @close="closeConfirmModal"
        @ok="confirmCloseVideoLibraryModal"
        >
            <template #modal-title>
                <h4 class="m-0 eggplant-color font-weight-600">Are you sure?</h4>
            </template>
            <p>Your video group is not saved. All videos you uploaded will be lost.</p>
        </b-modal> 
    </div>`
}

export { VideoUploadTab }