import { VueEllipseProgress } from "vue-ellipse-progress";

let AdListStatus = {
    props: {
        ad: Object,
    },
    data: function () {
        return ({
            // Ordered by priority
            platformTypeMap: {
                "gcm": "Google Campaign Manager",
                "google_ads": "Google Ads",
                "merchant_center": "Merchant Center",
            },
            statusMap: [
                {
                    condition: () => this.hasVideosRendering(),
                    status: 'loading_video_rendering', // Need to differentiate for render different component
                    tooltipText: () => this.progressStatus,
                    pillVariant: 'secondary',
                    pillText: 'In Progress'
                },
                {
                    condition: () => this.isAnyLoading(),
                    status: 'loading_any',
                    tooltipText: () => this.firstLoadingConnection,
                    pillText: 'In Progress'
                },
                {
                    condition: () => this.isAnyErrored(),
                    status: 'error_any',
                    tooltipText: () => this.firstErrorMessage,
                    pillVariant: 'danger',
                    pillText: 'Error'
                },
                {
                    condition: () => this.isWarning(),
                    status: 'warning',
                    tooltipText: () => this.getWarningTooltipMsg(),
                    pillVariant: 'warning',
                    pillText: 'Warning'
                },
                {
                    condition: () =>!this.hasVideosRendering() &&!this.isAnyLoading() &&!this.isAnyErrored() &&!this.isWarning(),
                    status: 'ready',
                    tooltipText: () => "Ready",
                    pillVariant: 'success',
                    pillText: 'Ready'
                }
            ],
            current: {
                status : null,
                statusGroup : null,
                tooltipText : null,
                pillVariant: null,
                pillText: null
            }
        })
    },
     mounted () {
        this.checkStatus();
    },
    computed: {
        videoProgress: function () {
            // return null if there is error so we dont show progress bar
            return this.ad.progress >= 0 ? this.ad.progress : null
        },
        firstErrorMessage: function () {
            // Sort the platforms by priority set in platformTypeMap
            const platformOrder = Object.keys(this.platformTypeMap);
            const sortedPlatforms = this.ad.platforms.sort((a, b) => {
                const indexA = platformOrder.indexOf(a.type);
                const indexB = platformOrder.indexOf(b.type);
                // If the type is not found in platformOrder, it gets the lowest priority
                return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
            });

            const firstConnectionWithError = sortedPlatforms.find(platform => platform.data.some(adConnection => adConnection.status === "error"))
            if (firstConnectionWithError) {
                const errorAdConnection = firstConnectionWithError.data.find(adConnection => adConnection.status === "error");
                if (errorAdConnection) {
                    return `${errorAdConnection.error_message}. Disconnect ad to retry.`
                }
            }
        },
        firstLoadingConnection: function () {
            const firstLoadingConnection = this.ad.platforms.find((adConnection) => adConnection.status === "connecting" || adConnection.status === "disconnecting");
            if(firstLoadingConnection){
                return firstLoadingConnection.status === "connecting" ? "Connecting" : "Disconnecting";
            }
            return this.ad.associated === "connecting" ? "Connecting" : "";
        },
        progressStatus: function () {
            if (this.ad.progress === -1) {
                return "Video rendering failed";
            } else if (this.ad.progress === -2) {
                return "One or more videos failed to render";
            } else {
                return `${this.ad.progress}% Rendered`;
            }
        }
    },
    methods: {
        checkStatus() {
            for (const { condition, status, tooltipText, pillVariant, pillText } of this.statusMap) {
                if (condition()) {
                    this.current.status = status
                    this.current.statusGroup = this.getStatusCategory(status);
                    this.current.pillVariant = pillVariant
                    this.current.pillText = pillText
                    // Determine if tooltipText is a function and call it if so, otherwise use it as a string
                    this.current.tooltipText = typeof tooltipText === 'function' ? tooltipText.call(this) : tooltipText;
                    break;
                }
            }
        },
        getStatusCategory(status) {
            if (status.includes('error')) {
                return 'error';
            } else if (status.includes('warning')) {
                return 'warning';
            } else if (status.includes('loading')) {
                return 'loading';
            } else {
                return 'ready';
            }
        },
        hasVideosRendering(){
            return this.ad.status == "rendering"
        },
        isAnyLoading() {
            return this.ad.status == "connecting"
        },
        isItemSetDeleted(){
            return this.ad.itemset.deleted
        },
        hasNoItems() {
            return !this.ad.itemset.has_items
        },
        isAnyErrored() {
            return this.ad.status == "error"
        },
        isWarning(){
            return this.isItemSetDeleted() || this.hasNoItems();
        },
        getWarningTooltipMsg(){
            if (this.isItemSetDeleted()) {
                return 'This ad is using a deleted set';
            } else if (this.hasNoItems()) {
                return 'This ad has no items—it’s serving the default ad';
            }
            return ''; // Default or fallback message if none of the conditions are met
        },
    },
    watch: {
        ad: {
            immediate: true,
            deep: true,
            handler() {
                this.checkStatus();
            }
        }
    },
    components: {
        'vue-ellipse-progress' : VueEllipseProgress
    },
    template: `
    <b-badge class="ad-list-status-pill p-2 d-flex flex-row align-items-center" pill :title="current.tooltipText" v-b-tooltip.hover :variant="current.pillVariant">
        <vue-ellipse-progress
        v-if="current.status === 'loading_video_rendering'"
        :progress="videoProgress"
        color="#6c757d"
        :size="16"
        :thickness="2"
        :legend="false"
        class="mr-1"
        />
        <font-awesome-icon class="mr-1" v-else-if="current.status === 'loading_any'" :icon="['fas', 'spinner']" spin-pulse />
        <font-awesome-icon class="mr-1" v-else-if="current.statusGroup === 'warning'" :icon="['fas', 'triangle-exclamation']"  />
        <font-awesome-icon class="mr-1" v-else-if="current.statusGroup === 'error'" :icon="['fas', 'triangle-exclamation']"  />
        <font-awesome-icon class="mr-1" v-else :icon="['fas', 'circle-check']" />
        <span class="font-weight-normal">{{ current.pillText }}</span>
    </b-badge>
    `
}

export {AdListStatus};
