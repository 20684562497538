import { ThemeVersionsService } from "../common/api.service.js";
import { PublishFileDropzone } from "./components/PublishFileDropzone.js";
import { ThemePreviewIframe } from "./components/ThemePreviewIframe.js";
import { mapState } from "pinia";
import { usePermissionsStore } from "../store/PermissionStore";

let ThemesList = {
    name: 'ThemesList',
    props:{
        website: String,
        template_url: String,
        item_type: String,
        previewDetails: Object,
        themePreviewStore: Object,
        preset_image_thumbnail: String,
        publishFileUploadURL: String,
        entry_point: String,
        staticUrl: String,
        accountId: String
    },
    data: function () {
        return ({
            currentPage: 1,
            pageSize: 9,
            totalPages: 0,
            totalThemes: 0,
            customThemesPreview: [],
            isDropzoneExpanded: false,
            isLoadingThemes: true,
            permissionsStore : usePermissionsStore(),
        })
    },
    mounted: function () {
        this.getThemesPreview();
    },
    components: {
        publishFileDropzone: PublishFileDropzone,
        themePreviewIframe: ThemePreviewIframe
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions']),
        dropzoneComponentProps: function () {
            return {
                website: this.website,
                template_url: this.template_url,
                item_type: this.item_type,
                collapsible: true,
                pageName: 'themesList', // using page name for determine action text in dropzone
                themeId: "", // We will adding new theme here, so themeId is empty,
                publishFileUploadURL: this.publishFileUploadURL,
                staticUrl: this.staticUrl,
                accountId: this.accountId
            }
        },
        sectionTitle: function () {
            return this.entry_point === 'admin_tools' ? 'Themes' : 'Custom Themes'
        }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.getThemesPreview()
            }
        },
        'themePreviewStore.logo.id'(newValue, oldValue) {
            if(!newValue) return
            this.getThemesPreview()
        },
    },
    methods: {
        onClick(themeId) {
            this.$router.push({ name: 'theme-details', params: { 'themeId' : themeId } })
        },
        getThemesPreview() {
            if(!this.isLoadingThemes) this.isLoadingThemes = true
            let logo = this.themePreviewStore.getLogo()
            if(!logo) {
                logo = {
                    id: '',
                    url: this.preset_image_thumbnail
                }
            }

            let targetServiceFunction = ThemeVersionsService.get_themes_previews
            if(this.entry_point === 'admin_tools'){
                targetServiceFunction = ThemeVersionsService.get_library_themes_previews
            }

            let params =  {
                page_size: this.pageSize,
                page: this.currentPage,
                logo_url: logo.asset,
                logo_id: logo.id,
                default_custom_fields: true,
                headline: this.themePreviewStore.getCustomFieldValueByKey('headline'),
                call_to_action: this.themePreviewStore.getCustomFieldValueByKey('call_to_action'),
                scaling: this.themePreviewStore.getCustomFieldValueByKey('scaling'),
                order_by: 'theme__name',
                latest_version_only: true,
                dynamic_templates: true
            }

            let result
            if(this.entry_point === 'admin_tools'){
                result = targetServiceFunction(params);
              } else {
                params.is_custom = true
                result = targetServiceFunction(this.website, params);
              }
              
              result.then(response => {
                 this.customThemesPreview = response.results
                 this.totalThemes = response.total_items
                 this.totalPages = response.total_items / this.pageSize
                 this.isLoadingThemes = false
              })
            
        },
        // Only for controlling to display the dropzone button in a flex container or not
        updateDropzoneExpandedStatus(isDropzoneExpanded) {
            this.isDropzoneExpanded = isDropzoneExpanded
        },
        changePage() {
            if(this.currentPage !== this.totalPages) this.currentPage + 1
        },
        dropzoneUploadSuccessCallback(response) {
            if (this.entry_point === 'admin_tools') {
                this.$router.push({ name: 'version-details', params: { themeId: response.theme_id, versionId: response.theme_version_id }, query: { from: 'upload' } })
            } else {
                if (response.type === 'html') {
                    this.$router.push({ name: 'theme-preview', params: { id: response.theme_version_id } })
                } else {
                    this.$router.push({ name: 'version-details', params: { themeId: response.theme_id, versionId: response.theme_version_id }, query: { from: 'upload' } })
                }
            }
        },
        isLastInRow(idx) {
            return (idx + 1) % 3 === 0
        }
    },
    template: `
    <div class="themes-list-container">

        <div :class="isDropzoneExpanded ? '' : 'd-flex justify-content-between'">          
            <h5 class="mt-0">{{ sectionTitle }}</h5>
            <div :class="isDropzoneExpanded ? 'mb-5' : ''" class="source-file-dropzone-container align-self-center">
                <publishFileDropzone 
                v-bind="{...dropzoneComponentProps, staticUrl: staticUrl}" 
                v-on:updateDropzoneExpandedStatus="updateDropzoneExpandedStatus"
                v-on:dropzoneUploadSuccessCallback="dropzoneUploadSuccessCallback"
                />
            </div>
        </div>

        <b-pagination
            class="justify-content-end my-2 mb-3"
            v-if="customThemesPreview.length"
            v-model="currentPage"
            :total-rows="totalThemes"
            :per-page="pageSize"
            @page-click="changePage"
            first-number
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
        ></b-pagination>

        <div v-if="customThemesPreview.length && !isLoadingThemes" class="theme-card-container d-flex w-100 flex-wrap justify-content-start mt-2 rounded">
            <div 
            v-for="(theme, index) in customThemesPreview"
            :key="theme.theme_id"
            class="theme-card card p-0 mb-4 default-theme-border-radius border-0 cursor-pointer"
            :class="{ 'mr-3': !isLastInRow(index) }"
            @click="onClick(theme.theme_id)"
            >
                <div v-if="theme.type === 'html'" class="position-relative">
                    <div class="theme_iframe-wrapper">
                        <themePreviewIframe :template="theme.templates[0]" :isShowSize='false' />
                    </div>
                </div>

                <div v-else class="w-100 h-100">
                    <img class="w-100 rounded" :src="theme.thumbnail_url || preset_image_thumbnail " />
                </div>
                <div class="overlay-details-container bg-white position-absolute w-100 p-3 default-theme-border-radius">
                    <div class="content-container d-flex">
                        <div class="theme-name-container mr-2">
                            <h5 class=" d-inline eggplant-color">{{ theme.name }}</h5>
                        </div>
                        <p class="badge rounded-pill cedar-border cedar-color px-2 mr-5 mb-0 mt-1 h-100">
                            <font-awesome-icon v-if="theme.type === 'html'" :icon="['fas', 'code']" />
                            <font-awesome-icon v-else :icon="['fas', 'video-camera']" />
                        </p>
                    </div>
                    
                    <div class="more-details">
                        <div class="version-badge-container position-absolute">
                            <div class="version-badge rounded-circle grass-background text-white pt-1 text-center w-100 h-100"><b>v.{{ theme.number }}</b></div>
                        </div>
                        <p class="text-muted m-0">In use by <b>{{ theme.total_ads }}</b> {{ theme.total_ads === "1" ? "ad" : "ads"}}</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-else-if="isLoadingThemes" class="text-center">
            <b-spinner label="Spinning"></b-spinner>
        </div>
        
        <div v-else class="text-center mt-5">
            <p v-if="permissions.tiered_themeversion_add_temporary || entry_point === 'admin_tools'">No {{ entry_point === 'admin_tools' ? '' : 'custom' }} themes</p>
            <p v-if="!permissions.tiered_themeversion_add_temporary && entry_point !== 'admin_tools'">Looks like you don't have any custom themes. Upgrade now to upload your own designs.</p>
        </div>
    </div>
    `
}

export { ThemesList }

