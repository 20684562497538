let RecentInventoryItem = {
  props: {
    item: Object,
  },
  data: function () {
    return {
      hasErrors:
        this.item.errors.errors && this.item.errors.errors.count > 0
          ? true
          : false,
      hasWarnings:
        this.item.errors.warnings && this.item.errors.warnings.count > 0
          ? true
          : false,
      disabled:
        (this.item.errors.errors && this.item.errors.errors.count > 0) ||
        (this.item.errors.warnings && this.item.errors.warnings.count > 0)
          ? false
          : true,
    };
  },
  computed: {
    importStatus: function () {
      var iconDefinition;
      if (this.item.errors.errors?.count > 0) {
        iconDefinition = {
          prefix: "fas",
          iconName: "exclamation-circle",
          classes: ["text-danger"],
        };
      } else if (this.item.errors.warnings?.count > 0) {
        iconDefinition = {
          prefix: "fas",
          iconName: "exclamation-triangle",
          classes: ["text-warning"],
        };
      } else {
        iconDefinition = {
          prefix: "fas",
          iconName: "check-circle",
          classes: ["text-success"],
        };
      }
      return iconDefinition;
    },
    itemsAddedUpdated: function () {
      let items_count =
        this.item.summary.new_count + this.item.summary.updated_count;
      return items_count === 1 ? `${items_count} item` : `${items_count} items`;
    },
    rejectedErrorsCount: function () {
      return this.item.errors.errors?.count === 1
        ? "1 item"
        : `${this.item.errors.errors?.count} items`;
    },
    deletedItems: function () {
      return this.item.summary.removed_count === 1 ? "item" : "items";
    },
    addedColor: function () {
      return this.item.summary.new_count + this.item.summary.updated_count > 0
        ? "color:green"
        : "color:grey";
    },
    rejectedColor: function () {
      return this.item.errors.errors?.count > 0 ? "color:red" : "color:grey";
    },
  },
  methods: {
    selectItem(item) {
      this.$emit("selectItem", item);
    },
  },
  template: `
    <div class="border-bottom py-3 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <div class="layered-icons mr-3">
                <font-awesome-icon icon="upload" class="secondary bottom-layer-icon" />
                <font-awesome-icon :icon="importStatus" class="top-layer-icon" :class="importStatus.classes" />
            </div>
            <div>
                <p class="mb-0">{{item.end_time}}</p>
                <p class="data-source-small mb-0">{{item.summary.total_count}} Total items</p>
            </div>
         </div>
            <div class="d-flex flex-column">
              <div v-if="itemsAddedUpdated  != '0 items'" class="d-inline data-source-small" :style="addedColor">
              <font-awesome-icon icon="circle" /> </i> {{ itemsAddedUpdated }} added or updated
              </div>
              <div v-if="rejectedErrorsCount != '0 items'" class="d-inline data-source-small" :style="rejectedColor">
              <font-awesome-icon icon="circle" /> </i> {{ rejectedErrorsCount }} rejected due to errors
              </div>
              <div v-if="item.summary.removed_count" class="d-inline data-source-small" style="color:grey">
              <font-awesome-icon icon="circle" /> {{ item.summary.removed_count }} {{ deletedItems }} deleted
              </div>
            </div>
         <b-button variant="light" :disabled="disabled" @click="selectItem(item)">View More</b-button>
    </div>
    `,
};

export { RecentInventoryItem };
