import Vue from 'vue';

var IntervalSelector = Vue.extend({

    template: 
        `
            <div class="card-body default-theme-border-radius">
                <label class="my-1 mr-2" for="inlineFormCustomSelectPref">How frequently would you like your inventory updated?</label>
                <select class="custom-select my-1 mr-sm-2 p-2 mb-3" id="inlineFormCustomSelectPref" name='interval_dropdown' v-model="localValues.interval_selected">
                    <option value="3" selected="selected">Once a month</option>    
                    <option value="2" selected="selected">Once a week</option>
                    <option value="1" selected="selected">Once a day</option>
                    <option v-if="localValues.interval_selected === '4'" value="4" selected="selected">Every {{ localValues.day_month_selected }} minutes</option>
                </select>
                <div v-if="(localValues.interval_selected === '3' || localValues.interval_selected === '2')">
                    <label class="my-1 mr-2" for="inlineFormCustomSelectDay">And on Which day?</label>
                    <select class="custom-select my-1 mr-sm-2 p-2 mb-3" id="inlineFormCustomSelectDay" name='day_week_dropdown' v-model="localValues.day_week_selected" v-if="(localValues.interval_selected === '2')">
                        <option value="0">Monday</option>
                        <option value="1">Tuesday</option>
                        <option value="2">Wednesday</option>
                        <option value="3">Thursday</option>
                        <option value="4">Friday</option>
                        <option value="5">Saturday</option>
                        <option value="6">Sunday</option>
                    </select>
                    <select class="custom-select my-1 mr-sm-2 p-2 mb-3" id="inlineFormCustomSelectDay" name='day_month_dropdown' v-model="localValues.day_month_selected" v-if="(localValues.interval_selected === '3')">
                        <option v-for="day in 28">{{ day }}</option>
                    </select>
                </div>
            </div>
        `,

    props: {
        interval_selected: String,
        day_week_selected: String,
        day_month_selected: String,
    },
    data() {
        return {
          localValues: {
            interval_selected: this.interval_selected,
            day_week_selected: this.day_week_selected,
            day_month_selected: this.day_month_selected
          }
        }
    },
    computed: {
        combinedValues: function () {
            return {
                interval_selected: this.localValues.interval_selected,
                day_week_selected: this.localValues.day_week_selected,
                day_month_selected: this.localValues.day_month_selected
            };
        }
    },
    watch: {
        combinedValues: {
            deep: true,
            handler: function (newVal) {
                this.$emit('updateValues', newVal.interval_selected, newVal.day_week_selected, newVal.day_month_selected);
            }
        }
    },

    mounted: function() {
        this.day_relevant = false
    },

})

export { IntervalSelector };
