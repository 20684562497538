let AdThemeCard = {
    props: {
        theme: Object,
        preset_image_thumbnail: String,
        currentSelection: String,

    },
    data() {
        return {
            isLoading: true,
            loadingTimeout: null,
            loadingTime: 2000 // Hardcoded time for showing loading spinner
        }
    },
    computed: {
        activeClass: function() {
            return this.currentSelection === this.theme.id ? "active-selection" : ""
        },
        firstTemplate: function() {
            return this.theme.templates && this.theme.templates[0] ? this.theme.templates[0].html : null;
        }
    },
    mounted () {
        this.updateIframeState();
    },
    methods: {
        updateIframeState() {
            if(this.isLoading && this.loadingTimeout === null) {
                this.loadingTimeout = setTimeout(function(){
                    this.isLoading = false
                    this.loadingTimeout = null
                }.bind(this),  this.loadingTime);
            }
        }
    },
    template:
        `
        <div class="my-4 mx-3 p-2 border default-theme-border-radius" :class="activeClass">
            <div v-if="firstTemplate" class="content__theme__dynamic bg-white"  v-on:click="$emit('update', 'theme', theme)">
                <div class="theme_iframe-wrapper position-relative">
                    <div class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': !isLoading}">
                        <b-spinner label="Loading..."></b-spinner>
                    </div>
                    <iframe class="content__theme-iframe" 
                            width="300" 
                            height="250" 
                            :srcdoc="firstTemplate" 
                            frameborder="0">
                    </iframe>
                </div>
            </div>
            <div v-else class="content__theme__dynamic bg-white"  v-on:click="$emit('update', 'theme', theme)">
                <img class="" width="300" height="250"
                     :src="firstTemplate && firstTemplate.thumbnail_url ? firstTemplate.thumbnail_url : theme.thumbnail_url ? theme.thumbnail_url  : preset_image_thumbnail ">
            </div>
            <div class="card-titleonly"> {{ theme.name }} </div>
        </div>
    `,
}

export {AdThemeCard};
