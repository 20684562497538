let DataSourceWidget = {
    props: {
        dataSource: String,
        website: String,
        websiteName: String,
        websiteImportStatus: Object,
        itemsLink: String,
        dataSourceLink: String,
    },
    computed: {
        isImporting: function () {
            return this.websiteImportStatus?.import_end_time === null || false
        },
        systemErrors: function () {
            return this.websiteImportStatus?.latest_import_system_errors
        },
        errors: function () {
            return this.websiteImportStatus?.latest_import_errors ||
                {
                    errors: {count: 0, column_names: [], issues: []},
                    warnings: {count: 0, column_names: [], issues: []}
                }
        },
        summary: function () {
            return this.websiteImportStatus?.latest_import_summary || {}
        },
        importEndTime: function () {
            return this.websiteImportStatus.import_end_time
        },
        dataReady: function () {
            return Boolean(this.websiteImportStatus) && this.isImporting === false;
        },
        noData: function () {
            return this.websiteImportStatus === null
        },
        itemStatus: function () {
            let status;
            if (this.summary.new_count === this.summary.removed_count) {
                status = "neutral"
            } else {
                status = this.summary.new_count > this.summary.removed_count ? "positive" : "negative";
            }
            return status
        },
        itemsPercent: function () {
            var total = this.summary.new_count / (this.summary.total_count + this.summary.removed_count);

            return total > 0 && total !== Infinity ? Math.round(total * 100) + "%" : ""
        },
        itemPercentIcon: function () {
            let iconName, angle;

            if (this.itemStatus === "positive") {
                iconName = 'long-arrow-alt-up';
                angle =  45;
            } else if (this.itemStatus === "negative") {
                iconName = 'long-arrow-alt-down';
                angle =  -45;
            } else if (this.itemStatus === "neutral") {
                iconName = 'slash';
                angle =  -38;
            }

            return { icon: ['fas', iconName], angle };
        },
        badgeColor: function () {
            var color;
            if (this.itemStatus === "positive") {
                color = "data-source-pill-positive"
            } else if (this.itemStatus === "negative") {
                color = "data-source-pill-negative"
            } else {
                color = "data-source-pill-neutral"
            }
            return color;
        },
        reportStatusIcon: function () {
            let iconDefinition;
            if (this.systemErrors) {
                iconDefinition = { prefix: 'fas', iconName: 'exclamation-circle', classes: ['text-danger'] };
            } else if (this.errors.errors.count >  0) {
                iconDefinition = { prefix: 'fas', iconName: 'exclamation-circle', classes: ['text-danger'] };
            } else if (this.errors.warnings.count >  0) {
                iconDefinition = { prefix: 'fas', iconName: 'exclamation-triangle', classes: ['text-warning'] };
            } else {
                iconDefinition = { prefix: 'fas', iconName: 'check-circle', classes: ['text-success'] };
            }
            return iconDefinition;
        },
        reportStatus: function () {
            var result;

            if(this.systemErrors){
              result = 'Failed'
            } else if (this.errors.errors.count > 0) {
                result = this.errors.errors.count > 1 ? `${this.errors.errors.count} Errors` : `${this.errors.errors.count} Error`
            } else if (this.errors.warnings.count > 0) {
                result = this.errors.warnings.count > 1 ? `${this.errors.warnings.count} Warnings` : `${this.errors.warnings.count} Warning`
            } else {
                result = 'All good!'
            }
            return result
        },
    },
    template: `
      <div class="dashboard-component shadow-sm bg-white">
        <h5 class="widget-title">Data Source</h5>
        <div v-if="noData">
          <h5 class="my-5 no-data text-center">No Data</h5>
        </div>
        <div v-else-if="!dataReady" class="d-flex justify-content-center">
          <b-spinner class="" variant="secondary" label="Loading..."></b-spinner>
        </div>
        <div v-else class="d-flex justify-content-between mt-4">
          <div class="col-3 p-0">
            <div class="d-flex">
              <p class="data-source-label mb-2 w-50">Last Import</p>
              <p class="data-source-value mb-0 w-50">{{ summary.last_update || importEndTime }}</p>
            </div>
            <div class="d-flex">
              <p class="data-source-label mb-2 w-50">Source</p>
              <p class="data-source-value mb-0 w-50 text-capitalize">{{ dataSource }}</p>
            </div>
            <div class="d-flex">
              <p class="data-source-label mb-2 w-50">Status</p>
              <div class="d-flex">
                <p v-if="errors.errors.count > 0 || errors.warnings.count > 0"><a
                    :href="dataSourceLink">{{ reportStatus }}</a></p>
                <p v-else class="data-source-value mb-0">{{ reportStatus }}</p>
                <font-awesome-icon class="mt-1 pl-2" :icon="reportStatusIcon" :class="reportStatusIcon.classes"></font-awesome-icon>
              </div>
            </div>
          </div>
          <div class="col-7 d-flex justify-content-between pl-5">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <p class="big-number mb-0 d-flex align-items-center">{{ summary.total_count }}
                <b-badge v-if="summary.total_count" pill :class="badgeColor" class="ml-2">{{ itemsPercent }} <font-awesome-icon :icon="itemPercentIcon.icon" :style="{ transform: 'rotate(' + itemPercentIcon.angle + 'deg)' }"></font-awesome-icon>
                </b-badge>
              </p>
              <p class="data-source-label">Total Items</p>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <p class="big-number mb-0">{{ summary.new_count }}</p>
              <p class="data-source-label">New</p>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <p class="big-number mb-0">{{ summary.updated_count }}</p>
              <p class="data-source-label">Updated</p>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <p class="big-number mb-0">{{ summary.removed_count }}</p>
              <p class="data-source-label">Deleted</p>
            </div>
          </div>
          <div class="col-2 pt-2 text-right">
            <a :href="itemsLink" class="data-source-link">View Items</a>
          </div>
        </div>
      </div>

    `
}

export {DataSourceWidget}