import {AssetsService, AssetGroupService} from "../common/api.service.js";

const store = () => { return {
    assetsService: null, // Only for getting uploaded video status
    assetGroupService: null,
    assetGroup: null,
    tempAssetGroup: null,
    assetGroups: [],
    assetGroupsCount: 0,
    search: '',
    searchQuery: '',
    videoAssetGroupsExist: false,
    isShowVideoLibraryModal: false, // Control if showing the video library modal
    preset_asset_thumbnail: "", // TODO we should have a global store for info like this
    website: "", // TODO we should have a global store for info like this
    website_id: "", // TODO we should have a global store for info like this
    queryParams: {
        type: "video",
        page_size: 12, //(4 * 3) showing 4 asset_groups per row in assets list
        page: 1,
    },
    tabs: {
        upload: "upload",
        management: "management"
    },
    activeTab: "upload", // Default showing upload tab, will show 'management' tab if has selected video
    /* For video upload tab */
    hasAttachments: false,
    isShowConfirmQuitModal: false,
    state: {
        ready: false,  // if the store is ready to be rendered
        dataReady: false, // if the assets are back
    },
    init(website, websiteId) {
        if(this.state.ready) return
        this.assetGroupService = AssetGroupService.init(website)
        this.assetsService = AssetsService.init(website)
        this.website = website
        this.website_id = websiteId
        this.resetTempAssetGroup();
        this.state.ready = true
    },

    getQueryParams(){
        if(this.search.length) {
            let newQueryParams = Object.assign({}, this.queryParams)
            delete newQueryParams.page // Remove page number if searching
            newQueryParams.search = this.search
            return { ...newQueryParams }
        }
        return {...this.queryParams}
    },

    // This function is needed for VideoManagementTab
    // so put it here to aviod passing function throught serval components
    assignTempAssetGroupToAssetGroup(){
        // Assign selected tempAsset to asset 
        this.assetGroup = this.tempAssetGroup
        this.closeModal()
    },

    updateTempAssetGroup(assetGroup) {
        this.tempAssetGroup = assetGroup;
    },
    
    resetTempAssetGroup(){
        // Reset to asset or to null if not assigned asset in db record for this item / ad
        this.tempAssetGroup = this.assetGroup ? this.assetGroup : null;
    },

    resetSearchQuery(){
        this.search = ''
    },

    // Only used for upload tab to close modal after confirmation modal ('Ok' button in confirmation modal)
    closeModalOnUploadTab(){
        this.closeModal();
    },

    // Used for video library modal
    cancelAndCloseModal(){
        if(this.hasAttachments) {
            this.isShowConfirmQuitModal = true
        }else{
            this.resetTempAssetGroup();
            this.closeModal();
        }
    },

    // Used only within this store, actual function to trigger the video library modal hidden
    closeModal(){
        this.resetSearchQuery();
        this.isShowVideoLibraryModal = false
        this.activeTab = this.tabs.upload // back to default
    },

    showVideoLibraryModal() {
        this.isShowVideoLibraryModal = true
        // Check if selected any asset group, if yes, direct to management tab
        if(this.assetGroup) this.activeTab = this.tabs.management
    },

    updateStoreAfterGetVideoAssetGroups(response){
        this.assetGroups = response.results
        if(response.count !== this.assetGroupsCount) this.assetGroupsCount = response.count
        this.putCurrentAssetFirstInAssetsGroupList()
        this.state.dataReady = true
        this.videoAssetGroupsExist = (Boolean(this.assetGroups.length) && !this.search) || this.search
        this.searchQuery = this.search
        this.totalPages = Math.ceil(response.count / this.queryParams.page_size);
    },

    putCurrentAssetFirstInAssetsGroupList() {
        const currentAssetId = this.assetGroup?.id;
        if (!currentAssetId) return;

        const index = this.assetGroups.findIndex((asset) => asset.id === currentAssetId);
        if (index !== -1) this.assetGroups.splice(0, 0, this.assetGroups.splice(index, 1)[0]);
        else if (this.queryParams.page === 1) this.assetGroups.unshift(this.assetGroup);
    },

    // Check if selection changes / have selection at all
    // Used in case in item detail page, to disalbe "Select" button if return false
    isTempAssetGroupDifferentFromAssetGroup (){
        if(!this.tempAssetGroup) return true
        if(!this.assetGroup) return true
        if(this.tempAssetGroup.id !== this.assetGroup.id) return true
        return false
    },

    // Only called on init
    updateAssetGroupAfterInit (_asset){
        this.assetGroup = _asset
        if(_asset) this.activeTab = this.tabs.management // Switch to library view if selected video before
    },

    onSelectBackgroundVideo(){
        // Assign selected tempAssetGroup to assetGroup 
        this.assignTempAssetGroupToAssetGroup()
    },

    // Get the thumbnail matches the one when user selected the background video
    // We are using size 1080 x 1920's thumbnail (thumbnail size still 728 x 90)
    getThumbnail(assetGroup) {
        let targetAsset = assetGroup.assets.find(asset => asset.size.height == 1080 && asset.size.width == 1920)
        return targetAsset ? targetAsset.thumbnails.MEDIUM?.thumbnail_file : assetGroup?.assets[0]?.thumbnails.MEDIUM.thumbnail_file // Return the first one if no asset size matched
    },

    resetAfterDeleteAssetGroup(){
        this.tempAssetGroup = null
        this.assetGroup = null
    },

    updatePageNumber(page) {
        this.queryParams.page = page
    }
}}

export {
    store
};
