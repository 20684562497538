import Vue from 'vue';
import VueFormulate from "@braid/vue-formulate";
import { ConfirmationModal } from "../common/components/ConfirmationModal.js"
import { DogPlusSvg } from "../tiers/components/DogPlusSvg.js"
import { WebsitesService } from "../common/api.service.js";


Vue.use(VueFormulate);

let General = {
    name: "General",
    props: {
        websiteName: String
    },
    data() {
        return {
            websiteDetail: {
                displayName: "",
                isArchived: false,
                itemType: ""
            },
            isShowArchiveConfirmation: false,
            isShowSuccessAlert: false
        }
    },
    mounted() {
        this.getWebsiteData();
    },
    methods: {
        getWebsiteData() {
            WebsitesService.get(this.websiteName).then(response => {
                this.websiteDetail.displayName = response.display_name;
                this.websiteDetail.isArchived = response.archived
                this.websiteDetail.itemType = response.item_type
            })
        },
        onSubmit(data) {
            this.isShowSuccessAlert = false
            delete data.item_type; // Ignore item_type as that is read only

            WebsitesService.update(this.websiteName + '/', { ...data }).then(response => {
                // Show success message
                this.isShowSuccessAlert = true
                this.websiteDetail.displayName = response.data.display_name
                this.websiteDetail.isArchived = response.data.archived
            })
        },
        updateArchiveStatus(confirmed = false) {
            this.isShowSuccessAlert = false;
            // Confirm if the website is not archived
            if (!confirmed && !this.websiteDetail.isArchived) {
                this.isShowArchiveConfirmation = true;
                return;
            }
            const handleArchiveResponse = response => {
                // Ensure confirmation model is hidden
                this.isShowArchiveConfirmation = false;
                // Show success message
                this.isShowSuccessAlert = true;
                this.websiteDetail.isArchived = response.data.archived;
            };
            const handleArchiveException = request => {
                if (request.response.status === 403) {
                    if (request.response.data.detail === "Website limit exceeded")
                        this.showAdvertiserCreationModal();
                }
            };
            const action = this.websiteDetail.isArchived ? WebsitesService.unarchive : WebsitesService.archive;
            action(this.websiteName).then(handleArchiveResponse).catch(handleArchiveException)
        },
        showAdvertiserCreationModal() {
            // WARNING: We assume that the modal with this ID has been created by some other component (i.e. the
            //          advertiser menu).
            this.$root.$emit('bv::show::modal', 'AdvertiserCreation')
        }
    },
    components: {
        ConfirmationModal,
        DogPlusSvg,
    },
    template: `
    <div class="general-container">
        <b-alert variant="success" :show="isShowSuccessAlert">Advertiser updated!</b-alert>
        <FormulateForm @submit="onSubmit">
            <FormulateInput
            type="text"
            label="Name*"
            name="display_name"
            input-class="form-control border-0"
            label-class="font-size-md"
            v-model="websiteDetail.displayName"
            required />

            <FormulateInput
            type="text"
            label="Inventory Item Type"
            name="item_type"
            input-class="form-control border-0"
            label-class="font-size-md"
            v-model="websiteDetail.itemType"
            required
            disabled />

            <b-button type="submit" variant="primary" class="mr-2">Update</b-button>
            <b-button type="button" variant="danger" class="font-weight-600" @click="updateArchiveStatus()">
                <span v-if="!websiteDetail.isArchived">Archive</span>
                <span v-else>Unarchive</span>
            </b-button>
        </FormulateForm>

        <ConfirmationModal
        :show="isShowArchiveConfirmation"
        title="Archive"
        message="Are you sure you want to archive this advertiser?"
        @confirmed="updateArchiveStatus(true)"
        @cancelled="isShowArchiveConfirmation = false" />
    </div>
    `
}

export { General };
