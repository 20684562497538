import {ConversionTypesService} from "./common/api.service.js";
import {InstallPixelModal} from "./InstallPixel.js";
import {GTMInstructionsModal} from "./GTMInstructions.js";
import ApiService from "./common/api.service.js";
import Vue from 'vue';

let ConversionType = Vue.extend({
    props: {
        websiteName: String,
        websiteId: String,
        code: String,
         fields: {
            default: function () {
                return [
                    {
                        key: 'name',
                        label: "Name",
                        thStyle: { width: "70%" },
                        tdClass: 'align-middle',
                    },
                    {
                        key: 'edit',
                        label: '',
                        thStyle: { width: "10%" },
                    },
                    {
                        key: 'install',
                        label: '',
                        thStyle: { width: "10%" },

                    },
                    {
                        key: 'archive',
                        label: '',
                        thStyle: { width: "10%" },
                    },
                ]
            }
        }
    },
    components: {
        "pixel-modal": InstallPixelModal,
        "gtm-modal": GTMInstructionsModal
    },
    data() {
        return {
            types: [],
            isStatusSectionExpanded: false,
            // status 1 = all good, while 0 meaning no data received
            pixelStatus: 0
        }
    },
    methods:{
        getConversionTypes() {
            let ConversiontypesService = ConversionTypesService.init(this.websiteName);
            ConversiontypesService.query()
            .then(response => {
                this.types = response.results.map(item =>({...item, isEdit: false, isDone: true}));
            });
        },
        getPixelStatus() {
            ApiService.get( this.websiteName, 'check_pixel_data_exist/')
            .then(response => {
                this.pixelStatus = response.pixel_exists
            });
        },
        editRowHandler(data) {
            this.types[data.index].isEdit = !this.types[data.index].isEdit;
            this.types[data.index].isDone = true
            if (!data.item.name){
                this.types.splice(data.index, 1);
            }
            if(data.item.id) {
                ConversionTypesService.init(this.websiteName).update(data.item.id, {name: data.item.name});
            } else {
                ConversionTypesService.init(this.websiteName).create({name: data.item.name, website: this.websiteId})
                .then(response => {
                    this.types[data.index].id = response.data.id
                    this.types[data.index].archive = response.data.archived
                });
            }
        },
        addRowHandler() {
            const newRow = this.fields.reduce((a, c) => ({...a, [c.key]: null}) ,{})
            newRow.isEdit = true;
            newRow.isDone = false;
            this.types.unshift(newRow);
        },
        ArchiveType(data){
            this.$bvModal.msgBoxConfirm('Are you sure you want to archive this conversion type?').then((result) => {
                if (result) {
                    ConversionTypesService.init(this.websiteName).update(data.item.id, {'archived': true});
                    this.types.splice(data.index, 1);
                }
            })
        },
        convertCode(){
          const parser = new DOMParser();
          const html = parser.parseFromString(this.code, 'text/html');
          const formatted_code = html.body
          return formatted_code.innerText
      }
    },
    mounted: function () {
        this.getConversionTypes();
        this.getPixelStatus();
    },
    template: `
    <div>
        <div class="pixel-status-container card-body border bg-light mb-3 p-3 default-theme-border-radius shadow" :class="isStatusSectionExpanded ? 'w-100' : 'w-50'">

            <div class="d-flex align-item-center justify-content-between">
                <div class="d-flex">
                    <h5 class="my-0 mr-2">Pixel Status:</h5>
                    <span :class="pixelStatus ? 'text-success' : 'text-warning'">
                        <font-awesome-icon :icon="['fas', 'circle']" />
                        <span v-if="pixelStatus">Pixel data received</span>
                        <span v-else>No pixel data received</span>
                    </span>
                </div>
                <b-button class="bg-transparent btn-no-outline border-0 p-0 ml-2" @click="isStatusSectionExpanded = !isStatusSectionExpanded">
                    <font-awesome-icon v-if="!isStatusSectionExpanded" class="spruce-color" :icon="['fas', 'circle-question']" size="lg" />
                    <font-awesome-icon v-else class="spruce-color" :icon="['fas', 'caret-up']" size="lg" />
                </b-button>
            </div>

            <div v-if="isStatusSectionExpanded" class="collapsed-content">
                <div v-if="pixelStatus">
                    <p class="font-weight-bold mb-0 mt-2">All good!</p>
                    <p>We've received data from your pixel in the past 30 days.</p>
                </div>
                <div v-else class="">
                    <p class="mt-2">We haven’t received any data from your pixel the past 30 days.</p>
                    <p class="font-weight-bold mb-0">What should you do?</p>
                    <p>
                        Please review the instructions below for adding the AdRetriever pixel to your website, either by
                        adding the <code>&lt;script&gt;</code> tag to your
                        <b-link v-b-modal.gtm-modal-1>tag manager</b-link> or by manually including it in your website's
                        HTML.
                    </p>
                </div>
            </div>
        </div>

        <div class="card-body border bg-light mb-3 p-3 default-theme-border-radius shadow">
            <h5 class="mt-0">Pixel</h5>
            <p>The AdRetriever pixel can be installed on a website to enable conversion tracking and to power Learning Sets. To
            install, copy and paste this <code>&lt;script&gt;</code> tag into the body of your Web page, or add it to your tag manager of choice:</p>
            <textarea class="w-100 bg-light p-3" style="height: 150px;" readonly onfocus="this.select()">{{convertCode()}}</textarea>
            <b-link v-b-modal.gtm-modal-1>Google Tag Manager Instructions</b-link>
            <gtm-modal :id="'1'" :code="convertCode()"></gtm-modal>
        </div>

        <div class="card-body border bg-light mb-3 p-3 default-theme-border-radius shadow">
            <div class="mt-2 mb-5">
                <b-button class="add-button float-right mb-2" variant="primary" @click="addRowHandler">Add Type</b-button>
                <h5 class="mt-0">Conversion Types</h5> 
            </div>
            
            <b-table hover outlined :items="types" :fields="fields">       
            <template #cell(name)="data">
                <b-form-input v-if="types[data.index].isEdit" type="text" v-model="types[data.index].name"></b-form-input>
                <span v-else>{{data.value}}</span>
            </template>
            <template #cell(edit)="data">
                <b-button variant="outline-primary" @click="editRowHandler(data)">
                  <span v-if="!types[data.index].isEdit">Edit</span>
                  <span v-else>Done</span>
                </b-button>
             </template>
            <template #cell(install)="data">
                <b-button v-if="types[data.index].isDone" variant="outline-primary" v-b-modal="types[data.index].id">Install</b-button>
                <pixel-modal
                    :key="types[data.index].id"
                    :conversion_type="types[data.index]"
                    :website="websiteName"
                    :code="convertCode()"
                ></pixel-modal>
            </template>
            <template #cell(archive)="data">
                <b-button v-if="types[data.index].isDone" variant="outline-primary" @click="ArchiveType(data)">Archive</b-button>
            </template>
        </b-table>
        </div>
    </div>
    `
})

export {ConversionType};
