let PlanToggles = {
    name: "PlanToggles",
    props: {
        state: Object,
        selectedType: Object,
        selectedCurrency: Object,
        showTypeDropdown: {
            type: Boolean,
            default: true
        },
        showBillingPeriodToggle: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        switchingDisplay(type, selected) {
            this.$emit("switchingDisplay", type, selected)
        }
    },
    template: `
    <div class="row justify-content-between no-gutters">
        <div class="billing-period-toggle-container mb-4 border p-1 rounded" v-if="showBillingPeriodToggle">
            <b-button v-for="(billingPeriod, key) in state.billingPeriods" :class="{'active': billingPeriod.display}" class="billing-period-toggle text-capitalize font-weight-bold bg-transparent eggplant-color" :key="key" @click="switchingDisplay('billingPeriods', key)">{{ billingPeriod.title }}</b-button>
        </div>
        <div class="type-and-currency-dropdown-container col-3 d-flex" :class="showBillingPeriodToggle ? 'justify-content-end' : 'justify-content-start'">
            <div v-if="showTypeDropdown" class="type-dropdown-container mr-2">
                <b-dropdown class="type-dropdown" variant="outline-primary" toggle-class="font-weight-bold">
                    <template #button-content>
                        {{ selectedType.title }}
                    </template>
                    <b-dropdown-item v-for="(type, key) in state.types" :key="key" @click="switchingDisplay('types', key)">
                        {{ type.title }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="currency-dropdown-container" :class="{'mb-2' : !showBillingPeriodToggle && !showTypeDropdown}">
                <b-dropdown class="type-dropdown mr-0" variant="outline-primary" toggle-class="font-weight-bold">
                    <template #button-content>
                        {{ selectedCurrency.title }}
                    </template>
                    <b-dropdown-item v-for="(type, key) in state.currencies" :key="key" @click="switchingDisplay('currencies' ,key)">
                        {{ type.title }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </div>
    `,
}

export { PlanToggles }