import Vue from 'vue'
import VueRouter from 'vue-router'
import { ThemesList } from './ThemesList.js'
import { ThemeDetails } from './ThemeDetails.js'
import { VersionDetails } from './VersionDetails.js'
import { ThemePreview } from './ThemePreview.js'
import { store as themePreviewStore } from '../store/ThemePreviewStore.js'
import { AssetsService } from "../common/api.service.js";

Vue.use(VueRouter);

// TODO: For future implementation, if we updated Admin Tools into using Vue Router, we should referencing AdvertiserSettingsRouter.js to move the routes under Admin Tools' router and update breadcrumb to use ThemesBreadcrumb.js, as the result, this Themes.js should be removed after that.
const routes = [
    {
        path: '', component: ThemesList, name: 'theme-versions-list', props: true, meta: {
            pageTitle: '', // Will update in mounted() base on entry point
            breadcrumb: [
                { name: 'Themes List' }
            ]
        }
    },
    {
        path: '/theme/:themeId', component: ThemeDetails, name: 'theme-details', meta: {
            breadcrumb: [
                { name: 'Theme List', link: 'theme-versions-list' },
                { name: 'Theme Details' }
            ]
        }
    },
    // Need themeId for breadcrumb to work
    { path: '/theme/:themeId/version/:versionId', component: VersionDetails, name: 'version-details', meta: {
        breadcrumb: [
            { name: 'Theme List', link: 'theme-versions-list' },
            { name: 'Theme Details', link: 'theme-details' },
            { name: 'Version Details' }
        ]
    } },
    { path: '/preview/:id', component: ThemePreview, name: 'theme-preview', meta: { breadcrumb: 'Theme Preview' } },
  ]

const router = new VueRouter({
    routes
})

let Themes = Vue.extend({
    name: "Themes",
    router,
    props:{
        website: String,
        website_id: String,
        item_type: String,
        preset_image_thumbnail: String,
        preset_logo_url: String,
        filterSchema: Array,
        entry_point: String,
        staticUrl: String,
        accountId: String
    },
    watch: {
        '$route'() {
            this.updateBreadcrumb()
            this.updatePageTitle(this.$route.meta.pageTitle)
        }
    },
    computed: {
        componentProps: function () {
            return {
                entry_point: this.entry_point,
                website: this.website,
                website_id: this.website_id,
                preset_image_thumbnail: this.preset_image_thumbnail,
                preset_logo_url: this.preset_logo_url,
                filterSchema: this.filterSchema,
                publishFileUploadURL: this.publishFileUploadURL,
                staticUrl: this.staticUrl,
                accountId: this.accountId
            }
        }
    },
    mounted () { 
        this.updateBreadcrumb()
        this.updatePageTitle(this.basePageTitle[this.entry_point])
        if(this.entry_point !== 'admin_tools') this.getLogo()
     },
    methods: {
        routeTo(breadcrumbIdx) {
            if (this.breadcrumbList[breadcrumbIdx].link) this.$router.push({ name: this.breadcrumbList[breadcrumbIdx].link })
        },
        updateBreadcrumb () { 
            this.breadcrumbList = this.$route.meta.breadcrumb
        },
        updatePageTitle (title) {
            this.pageTitle = title
        },
        //Get the logo for theme list and theme preview page to use
        getLogo() {
            AssetsService.init(this.website).query({ only: themePreviewStore.logoDetailFields, type: 'logo', default: true })
                .then(response => {
                    if(!response.results.length){
                        // Use default logo url if no uploaded logo
                        this.themePreviewStore.updateLogo( {asset:  this.preset_logo_url });
                    }else{
                        this.themePreviewStore.updateLogo(response.results[0]);
                    }
                });
        },
    },
    data: function () {
        return ({
            themePreviewStore: themePreviewStore(),
            breadcrumbList: [],
            pageTitle: '',
            basePageTitle: { // Use corresponding page title according to base entry point
                admin_tools: 'Admin Tools',
                advertiser_setting: '',
            },
            publishFileUploadURL : this.entry_point === 'admin_tools' ? `/api/theme_version_upload/` : `/api/${this.website}/theme_version_upload/`
        })
    },
    template: `
    <div class="themes-management" :class="entry_point">
        <div v-if="breadcrumbList.length > 1" class="breadcrumb position-absolute">
            <ul>
                <li
                    v-for="(breadcrumb, idx) in breadcrumbList"
                    :key="idx"
                    @click="routeTo(idx)"
                    class="list-unstyled d-inline font-weight-600"
                    :class="{'linked': !!breadcrumb.link}">
                    <span :class="idx == breadcrumbList.length - 1 ? 'spruce-color pointer-event-none' : 'eggplant-color'">{{ breadcrumb.name }}</span>
                    <span v-if="idx !== breadcrumbList.length - 1" class="text-muted opacity-50">&nbsp;/&nbsp;</span>
                </li>
            </ul>
        </div>
        <div class="page-header position-absolute">
            <h4>{{ pageTitle }}</h4>
        </div>
        <router-view
            class="themes-router-view"
            :key="$route.path"
            :filterSchema="filterSchema"
            :themePreviewStore="themePreviewStore"
            v-bind="{...componentProps}"
            v-on:updatePageTitle="updatePageTitle"
        ></router-view>
    </div>
    `
});

export {Themes}

