import {GTMInstructionsModal} from "./GTMInstructions.js";

let InstallPixelModal = {

    props: {
        conversion_type: Object,
        website: String,
        code: String,
    },
    components: {
        "gtm-modal": GTMInstructionsModal
    },
    template: `
        <b-modal
        :id="conversion_type.id"
        size="lg"
        scrollable
        :title="'Install Tracking for ' + conversion_type.name"
        body-class="p-4"
        hide-footer>
            <div class="container">
                <h4>Method 1: Google Tag Manager (Recommended)</h4>
                <p>Follow these steps to track the <b>{{ conversion_type.name }}</b> conversion using Google Tag Manager:</p>                
                <ol>
                    <li><b-link v-b-modal.gtm-modal-2>Install the AdRetriever pixel in Google Tag Manager</b-link><gtm-modal :id="'2'" :code="code"></gtm-modal></li>
                    <li>In Google Tag Manager, create a new tag.</li>
                    <li>Name the tag (e.g. <b>{{ conversion_type.name }}</b>).</li>
                    <li>For the Tag Configuration, choose Custom HTML.</li>
                    <li>Copy and paste this HTML:
                        <textarea onClick="this.select();" class="w-100 bg-light" readonly> &lt;script&gt;adretriever("pixelEvent", "{{ conversion_type.name }}", "{{conversion_type.id}}");&lt;/script&gt;</textarea>
                    </li>
                    <li>Under Triggering, create a trigger for your conversion, or choose an existing one.
                        <p> -For example, trigger your conversion on a form submit where the Click ID equals 'sign-up-form-submit'.</p>
                    </li>
                </ol>
                <h4>Method 2: Manual</h4>
                <p>Follow these steps to track the <b>{{ conversion_type.name }}</b> conversion manually:</p>
                <ol>
                    <li>In the source HTML of your website, locate the element that you would like to use to track the conversion.
                        <p>-For example, find the &lt;form&gt; element for your sign-up form</p>
                    </li>
                    <li>Add this code to the appropriate JavaScript event handler:
                        <textarea onClick="this.select();" class="w-100 bg-light" readonly>  adretriever("pixelEvent", "{{ conversion_type.name }}", "{{conversion_type.id}}");</textarea>
                        <p> -For example in your form tag, add onsubmit="analytics.trackCustomEvents()"</p>
                    </li>
                </ol>
            </div>
        </b-modal>
`
}

export {InstallPixelModal};
