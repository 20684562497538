import Vue from 'vue';
import {ItemDetailsModal} from "./ItemDetails/ItemDetailsModal.js";

const ItemCard = {
    props: {
        item: Object,
        showName: {
            type: Boolean,
            default: true
        },
        overlay: Boolean,
        presetImageThumbnail: String
    },
    data: function () {
        return {
            isError: false,
        };
    },
    computed: {
        thumbnailUrl: function () {
            return this.isError ? this.presetImageThumbnail : this.item.thumbnail_urls[0]
        }
    },
    template: `
    <div class="item-card my-1">
        <div class="content__product">
            <img class="content__product-image" :src="thumbnailUrl" @error="isError = true">
            <div v-if="showName" class="content__product-description"> {{ item.name }} </div>
            <div v-if="overlay" class="overlay"></div>
        </div>  
    </div>
    `,
};

//TODO can be removed, previously use this in inventory
const ItemCardSearch = Vue.component('search-card', {
    props: {
        item: Object,
        website: String,
        extraClasses: String
    },
    components: {
        'item-card': ItemCard
    },
    template: `
        <a v-bind:href="'/' + website + '/items/' + item.id" :class="extraClasses">
            <item-card :item="item" :website="website"></item-card>
        </a>
    `
});

const ItemCardFilter = Vue.component('filter-card', {
    data: function() {
        return {
            hover: false
        }
    },
    computed: {
        selected: {
            get() {
                let itemIds = new Set(this.store.state.filter.item_ids?.split(',') || []);
                return itemIds.has(this.item.id);
            },
            set(value) {
                if (value) {
                    this.store.addItemIdFilter(this.item.id)
                }
                else {
                    this.store.removeItemIdFilter(this.item.id)
                }
            }
        }
    },
    methods: {
        onUpdateItemVisibility() {
            this.$emit('onUpdateItemVisibility')
        }
    },
    props: {
        modalKey: String,
        item: Object,
        website: String,
        websiteType: String,
        pinned: Boolean,
        extraClasses: String,
        store: Object,
        hidePin: Boolean,
        disablePin: Boolean,
        isVideoAdUser: String,
        websiteId: String,
        presetAssetThumbnail: String,
        presetImageThumbnail: String
    },
    components: {
        "item-card": ItemCard,
        "item-modal": ItemDetailsModal
    },
    template: `
        <div class="filter position-relative" @mouseover="hover = true" @mouseleave="hover = false">
            <item-card :item="item" :showName=false :overlay="hover" :presetImageThumbnail="presetImageThumbnail"></item-card>
            <label v-if="!hidePin" class="fancy-checkbox">
                <input type="checkbox" v-model="selected" class="checkbox_filter" :disabled="disablePin"/>
                <font-awesome-icon class="white-thumbtack unchecked checkbox_filter" :icon="['fas', 'thumbtack']" size="xl" />
                <font-awesome-icon class="checked checkbox_filter" :icon="['fas', 'thumbtack']" size="xl" />
            </label>
            <label class="fancy-checkbox">
                <b-button type="button" class="btn_zoom fas fa-search text-white" v-b-modal="item.id+modalKey" style="visibility: hidden"/>
                <font-awesome-icon class="text-white unchecked btn_zoom" :icon="['fas', 'search']" size="xl" />
                <item-modal 
                    :modalKey="modalKey"
                    :item="item"
                    :item_type="websiteType"
                    :website="website"
                    :websiteId="websiteId"
                    :presetAssetThumbnail="presetAssetThumbnail"
                    :presetImageThumbnail="presetImageThumbnail"
                    :isVideoAdUser="isVideoAdUser"
                    v-on:onUpdateItemVisibility="onUpdateItemVisibility"
                ></item-modal>
            </label>
        </div>
    `
});

Vue.filter("capitalize", function(input = "") {
    let text = input.toString();
    return text.charAt(0).toUpperCase() + text.slice(1);
});

export { ItemCardSearch, ItemCardFilter }