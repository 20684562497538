import {ItemSetService} from '../common/api.service.js';
import { ItemCardFilter } from '../ItemCards.js';
import {ItemsetEditMixin} from "../mixins/ItemsetEditMixin.js";
import {ItemSearchMixin} from "../mixins/ItemSearchMixin.js";

let ItemsetEditLearning = {
    mixins: [ItemSearchMixin, ItemsetEditMixin],
    data: function() {
        return {
            isSaving: false,
            isValidating: false,
            currentPage: 1,
            options: [
                { value: null, text: 'Please select an option' },
                { value: 'newest', text: 'Newest' },
                { value: 'most_viewed', text: 'Most Viewed' },
            ]
        };
    },
    props: {
        website: String,
        websiteType: String,
        websiteId: String,
        filterSchema: Array,
        newItemsetType: String,
        store: Object,
        isVideoAdUser: String,
        presetAssetThumbnail: String,
        presetImageThumbnail: String
    },
    computed: {
        buttonText: function() {
            return this.itemset ? 'Save' : 'Create'
        },
        isReadOnly: function() {
            return !!this.itemset
        },
        numberOfItems: {
            get() {
                return this.store.state.filter?.item_count
            },
            set(value) {
                let newValue = parseInt(value !== '' ? value : '0');
                this.store.setItemLimit(newValue)
            }
         }
    },
    watch: {
        'store.state.filter.page': {
            handler() {
                this.fetchItems()
            },
        },
        currentPage: {
            handler: function (value) {
                this.store.setPageFilter(value)
            }
        }
    },
    created: function() {
        if (this.$route.params.id) {
            this.fetchItemSet().then(() => this.fetchItems());
        }
    },
    methods: {
        saveItemset() {
            this.isSaving = this.isValidating = true;
            if (!this.nameIsValid) {
                this.isSaving = false;
                return;
            }

            let formData = {
                'name': this.itemsetName,
                'type': this.selectedType,
                'item_filter': this.store.state.filter,
            };

            let itemsetService = ItemSetService.init(this.website);
            if(this.itemset) {
                itemsetService.update(`${this.itemset.id}/`, formData)
                    .then(this.handleItemset)
                    .catch(this.handleItemsetError);
            } else {
                itemsetService.create(formData)
                    .then(this.handleItemset)
                    .catch(this.handleItemsetError);
            }
        },
        handleItemset(response) {
            if (this.$route.name !== 'itemsets-learn-edit') {
                this.$router.push({name: 'itemsets-learn-edit', params: {id: response.data.id}})
            }
            if(this.itemset) {
                this.$nextTick(() => {
                    this.$router.push({ name: 'itemsets', query: { hl: response.data.id } })
                })
            }
            this.itemset = response.data;
            // Update the itemset on the store to trigger learning set query params :P :S
            this.store.updateItemset(this.itemset);
            this.fetchItems();
            this.isSaving = this.isValidating = false;
        },
        handleItemsetError() {
            this.isSaving = false;
        },
        returnLink() {
            this.$router.push({name: 'itemsets', query: {type: 'most_viewed,newest'}})
        },
        goToPixelAndConversion() {
            window.location.href = window.location.origin + "/" + this.website +  "/settings#/pixel-and-conversions";
        }
    },
    components: {
        'filter-card': ItemCardFilter
    },
    template:
     `
     <div class="learning-set-container">
        <div class="custom-header">
            <ol class="breadcrumb pl-0 mb-0 font-weight-600">
                <li class="breadcrumb-item">
                    Sets
                </li>
                <li class="breadcrumb-item">
                    <a href="#" @click="returnLink" class="pseudolink">Learning</a>
                </li>
            </ol>
            <h3 v-if="itemset" class="font-weight-600 eggplant-color">{{itemset.name}}</h3>
            <h3 v-else class="font-weight-600 eggplant-color">New Learning Set</h3>
        </div>
        <div>
            <b-alert show variant="info" class="mt-3 bg-info text-white">
                <font-awesome-icon class="mr-1" :icon="['fas', 'circle-info']" />
                <span>Some of these features will not work unless you install the
                  <a class="text-white font-weight-bold underline" href="#" v-on:click.prevent.stop="goToPixelAndConversion()">
                    AdRetriever pixel
                  </a>.</span>
            </b-alert>
        </div>
        <div class="item-sets-content d-flex flex-wrap">
            <div class="col-sm-3 p-3 bg-white default-theme-border-radius mr-3">
                <div id="div_id_name" class="form-group">
                    <label :class="{requiredField: true}">Name*</label>
                    <b-form-input placeholder="Learning set name" :state="isValidating ? nameIsValid : null" v-model="itemsetName" id="id_name"/>
                    <b-form-invalid-feedback :state="isValidating ? nameIsValid : null">Please name this set.</b-form-invalid-feedback>
                </div>
                <div id="div_id_type" class="form-group" :class="{'was-validated': isValidating}">
                    <label :class="{requiredField: true}">Type*</label>
                    <b-form-select v-model="selectedType" :options="options" :disabled="isReadOnly" :required="true"></b-form-select>
                </div>
                <div id="div_id_number" class="form-group" :class="{'was-validated': isValidating}">
                    <label :class="{requiredField: true}">Number of Items*</label>
                    <input placeholder="Number of items to be included" class="form-control" v-model="numberOfItems" type="number" id="id_number" required />
                </div>
                <div>
                    <button type="submit" id="category-form-submit" class="btn btn-primary text-light float-right" v-on:click="saveItemset()" :disabled="isSaving">{{buttonText}}</button>
                </div> 
            </div>
            <div class="d-flex flex-column col bg-white default-theme-border-radius p-3">
                <div class="container p-0">
                    <b-row align-h="end" class="align-items-end col-12 p-0 m-0">
                        <b-col cols="4" class="actions p-0 d-flex justify-content-end">
                            <b-pagination
                            v-if="!this.store.isOnlyPinnedFilterActive()"
                            v-model="currentPage"
                            :total-rows="itemCount"
                            :per-page="24"
                            first-text="First"
                            last-text="Last"
                            hide-ellipsis
                            limit="4"
                            ></b-pagination>
                        </b-col>
                    </b-row>
                </div>
                <div class="items-container" v-if="itemset">
                    <div class="product-grid pr-2 mt-2">
                        <filter-card
                            v-for="item in items"
                            :key="item.id"
                            :item="item"
                            :website="website"
                            :websiteType="websiteType"
                            :websiteId="websiteId"
                            :presetAssetThumbnail="presetAssetThumbnail"
                            :presetImageThumbnail="presetImageThumbnail"
                            :class="'d-inline-block align-top mr-2'"
                            :store="store"
                            :isVideoAdUser="isVideoAdUser"
                            :hidePin="true"
                            v-on:onUpdateItemVisibility="fetchItems"
                        />
                    </div>
                    <b-alert show variant="primary" class="mr-2 mt-3">You'll see the updated list of items after you save your settings.</b-alert>
                </div>
                <b-alert v-else show variant="primary" class="mt-3">You'll be able to browse the items in your learning set after it's created.</b-alert>
            </div>
        </div>
    </div>
     `
}

export { ItemsetEditLearning }