let ThemesBreadcrumb = {
    name: "ThemesBreadcrumb",
    data() {
        return {
            breadcrumbList: [],
        }
    },
    methods: {
        routeTo(breadcrumbIdx) {
            if (this.breadcrumbList[breadcrumbIdx].link) this.$router.push({ name: this.breadcrumbList[breadcrumbIdx].link })
        },
        updateBreadcrumb() {
            this.breadcrumbList = this.$route.meta.breadcrumb
        },
    },
    computed: {
        propsByRouteName() {
            return this.$props[this.$route.name] || this.$props[this.$route.meta.propDataKey] || null
        },
        isCustomTheme() {
            return this.$route.meta.propDataKey === 'customThemes'
        }
    },
    mounted() {
        if (this.isCustomTheme) {
            this.updateBreadcrumb()
        }
    },
    watch: {
        '$route'() {
            if (this.isCustomTheme) {
                this.updateBreadcrumb()
            }
        }
    },
    template: `
    <div v-if="breadcrumbList.length > 1" class="breadcrumb justify-content-start">
        <ul class="p-0 mb-1">
            <li
                v-for="(breadcrumb, idx) in breadcrumbList"
                :key="idx"
                @click="routeTo(idx)"
                class="list-unstyled d-inline font-weight-600"
                :class="{'linked': !!breadcrumb.link}">
                <span :class="idx == breadcrumbList.length - 1 ? 'spruce-color pointer-event-none' : 'eggplant-color'">{{ breadcrumb.name }}</span>
                <span v-if="idx !== breadcrumbList.length - 1" class="text-muted opacity-50">&nbsp;/&nbsp;</span>
            </li>
        </ul>
    </div>
    `,
}

export { ThemesBreadcrumb }