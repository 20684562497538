import FontPicker from "font-picker";

let FontPickerCustom = {
    data: function () {
        return {
            fontPicker: null,
        }
    },
    props: {
        defaultValue: String,
        fontList: Array,
        pickerId: String, // For differentiate each font picker
        customFieldKey: String, // Corresponding to the store.ad.theme_config.custom_fields key name
        googleFontsApiKey: String
    },
    methods: {
        setUpFontPicker() {
            new FontPicker(
                this.googleFontsApiKey, // Google API key
                this.defaultValue, // Default font
                {
                    pickerId: this.pickerId,
                    families: this.fontList // font list
                },
                this.fontChanged // onChange function
            );
        },
        fontChanged(font) {
            this.$emit('updateFont', font, this.customFieldKey)
        }
    },
    mounted() {
        this.setUpFontPicker();
    },
    computed: {
        divName () {
            return `font-picker-${this.pickerId}`
        },
        className() { // For apply font family in the selection button, remove the className to remove apply font family to the selection button
            return `apply-font-${this.pickerId}`
        }
    },
    template:
    `
    <div class="font-picker-custom w-75">
        <div :id="divName" :class="className" class="font-picker w-100" :onChange="fontChanged"></div>
    </div>
    `
}

export { FontPickerCustom };
