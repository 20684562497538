// Common component can be use to display success or error message for API calls
let StatusAlert = {
    name: "StatusAlert",
    computed: {
        displayMessage() {
            return this.isSuccess ? this.successMessage : this.errorMessage
        }
    },
    props: {
        isShowAlert: Boolean,
        isSuccess: Boolean,
        successMessage: String,
        errorMessage: {
            type: String,
            default: "We are sorry that something went wrong. Please try again later. If this persists please contact support."
        }
    },
    template: `
    <b-alert :variant="isSuccess ? 'success' : 'danger'" :show="isShowAlert">
        <font-awesome-icon v-if="isSuccess" class="mr-2" :icon="['fas', 'circle-check']" />
        <font-awesome-icon v-else  class="mr-2" :icon="['fas', 'circle-xmark']" />
        {{ displayMessage }}
    </b-alert>
    `,
}

export { StatusAlert }