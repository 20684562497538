import { ItemSearchMixin } from "../mixins/ItemSearchMixin.js";
import { ItemCardFilter } from "../ItemCards.js";
import { ShowFilterPills } from "../ItemSearchFilterPanel.js"

let ItemsetDetails = {
    mixins: [ItemSearchMixin],
    components: {
        "item-cards": ItemCardFilter,
        "filter-pills" : ShowFilterPills
    },
    props: {
        isLearning: Function,
        website: String,
        store: Object,
        websiteType: String,
        isVideoAdUser: String,
        websiteId: String,
        presetAssetThumbnail: String,
        presetImageThumbnail: String
    },
    data() {
        return {
            pinnedItems: null,
            dataReady: false,
            currentPage: 1,
            isFetchingItems: false,
            showExpandFiltersToggle: false,
            isAppliedFiltersExpanded: false
        }
    },
    watch: {
        "store.state.itemset": {
            handler() {
                if(this.store.state.itemset === null) return
                this.isAppliedFiltersExpanded = false // Reset
                this.$refs.pinnedItemsContainer?.scrollTo(0,0)
                this.currentPage = 1
                this.store.setPageFilter(this.currentPage)
                this.$nextTick(() => {
                    this.fetchItems()
                })
                this.handleAppliedFilterHeight();
            },
            deep: true
        },
        currentPage: {
            handler: function (value) {
                this.store.setPageFilter(value)
                this.$nextTick(() => {
                    this.fetchItems()
                })
            }
        }
    },
    methods: {
        fetchItems: function () {
            if (this.isFetchingItems) return
            this.isFetchingItems = true
            // Use the mixin for the basic fetch, then handle pinned items
            ItemSearchMixin.methods.fetchItems.apply(this)
                .then(this.handlePinnedItemsResponse)
                .catch(this.handleNotFoundWithPageFilter)
        },
        handlePinnedItemsResponse(response) {
            this.pinnedItems = response.pinned_items || null;
            this.itemCount = this.getItemCount(response.count, response.pinned_items_count, this.store.isOnlyPinnedFilterActive());
            this.repairItemIds(response.pinned_items_count);
            this.isFetchingItems = false
        },
        handleNotFoundWithPageFilter(error) {
            // Handle cases where we're using a page offset and we change the filter so that we have fewer pages
            if (error?.response.status === 404 && this.store.state.filter.page > 1) {
                // Go back to the first page
                this.store.setPageFilter(1);
                this.isFetchingItems = false
            }
        },
        getItemCount(itemsCount, pinnedCount, onlyPinnedFilterActive) {
            pinnedCount = pinnedCount || 0;
            return onlyPinnedFilterActive ? pinnedCount : itemsCount + pinnedCount;
        },
        repairItemIds(pinnedCount) {
            // Update the itemids if the pinned items returned match the param
            // this inconsistency could happen due to items being removed from the website
            if (typeof pinnedCount !== 'undefined' && pinnedCount !== this.store.getItemIdFilterCount()) {
                const selectedIds = this.pinnedItems.map(item => item.id);
                this.store.resetItemIdFilter(selectedIds.join(','));
            }
        },
        isShowOtherItems() {
            if(this.store.state.itemset.item_count === 0) return false
            if(this.store.isOnlyPinnedFilterActive()) return false
            return true
        },
        handleAppliedFilterHeight() {
            this.$nextTick(() => {
                let containerHeight = this.$refs.appliedFiltersContainer?.clientHeight
                let filtersHeight = this.$refs.appliedFilters?.clientHeight
                if (!containerHeight || !filtersHeight) return
                if (filtersHeight > containerHeight) {
                    this.showExpandFiltersToggle = true
                } else {
                    this.showExpandFiltersToggle = false
                }
            })
        }
    },
    mounted: function () {
        this.$nextTick(() => {
            this.fetchItems() // Need to wait until store.state.filter is updated
            this.handleAppliedFilterHeight();
        })
    },
    template: `

    <div class="itemset-details-section h-100" v-if="store.state.itemset">
        <b-container fluid class="itemset-details-header d-flex align-items-center p-0 flex-column mb-2">
            <b-row align-h="between" class="align-items-start col-12 p-0 mb-1">
                <b-col cols="8" class="itemset-details p-0 d-flex justify-content-end flex-column">
                    <h5 class="mb-0">{{ store.state.itemset.name }}</h5>
                    <p class="opacity-50 m-0">
                        <span>{{ store.state.itemset.item_count }} items</span>
                        <span v-if="isLearning(store.state.itemset)"><font-awesome-icon :icon="['fas', 'circle']" class="separator mx-1 mr-2" />Learning Set</span>
                    </p>
                </b-col>
                <b-col cols="4" class="actions p-0 d-flex justify-content-end">
                    <b-button class="mr-4" variant="outline-primary" @click="$emit('edit', store.state.itemset)">Edit Set</b-button>
                    <b-button variant="outline-danger" @click="$emit('delete', store.state.itemset)">Delete Set</b-button>
                </b-col>
            </b-row>
            <b-row align-h="between" class="align-items-start col-12 p-0">
                <b-col cols="7" class="itemset-details p-0 d-flex justify-content-start">
                    <div v-if="store.isAppliedAnyFilters() && store.state.itemset.item_count || isLearning(store.state.itemset)" class="item-filter-or-type grass-border grass-color default-theme-border-radius p-2 w-100">
                        <div v-if="isLearning(store.state.itemset)">
                            <span class="font-weight-600 align-top">Type : </span>
                            <b-badge pill class="set-type-pill filter-pill align-text-bottom text-left pl-3 pr-3 py-1 mt-1 mr-1" pill variant="secondary">
                                <span class="text-capitalize">{{ store.state.itemset.type }}</span>
                            </b-badge>
                        </div>
                        <div class="applied-filters-container" :class="{expanded: isAppliedFiltersExpanded}" ref="appliedFiltersContainer" v-else>
                            <b-button 
                            class="bg-transparent border-0" 
                            :class="{ hide: !showExpandFiltersToggle, rotated: isAppliedFiltersExpanded}" @click="isAppliedFiltersExpanded = !isAppliedFiltersExpanded">
                                <font-awesome-icon class="grass-color" :icon="['fas', 'caret-down']" />
                            </b-button>
                            <div class="applied-filters mb-0" ref="appliedFilters">
                                <span class="font-weight-600 align-top pt-1">Applied Filters : </span>
                                <div class="w-70 d-inline-block">
                                    <filter-pills :store="store" :hideRemoveBtn="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col v-if="store.state.itemset.item_count" cols="4" class="actions p-0 d-flex justify-content-end">
                    <b-pagination
                    v-if="!this.store.isOnlyPinnedFilterActive()"
                    v-model="currentPage"
                    :total-rows="store.state.itemset.item_count"
                    :per-page="24"
                    first-text="First"
                    last-text="Last"
                    hide-ellipsis
                    limit="4"
                    ></b-pagination>
                </b-col>
            </b-row>
        </b-container>
        <div v-if="!store.state.itemset.item_count" class="w-100 text-center my-5">There are no items in this set.</div>
        <div v-else class="itemset-items d-flex flex-wrap p-0">
            <div v-if="pinnedItems" class="pinned-items-container horizontal-scroller d-flex p-0 mb-2" ref="pinnedItemsContainer">
                <item-cards
                v-for="pinnedItem in pinnedItems"
                :key="pinnedItem.id"
                :item="pinnedItem"
                :website="website"
                :websiteType="websiteType"
                :store="store"
                :pinned="true"
                :hidePin="false"
                :disablePin="true"
                :isVideoAdUser="isVideoAdUser"
                :websiteId="websiteId"
                :presetAssetThumbnail="presetAssetThumbnail"
                :presetImageThumbnail="presetImageThumbnail"
                v-on:onUpdateItemVisibility="fetchItems"
                />
            </div>
            <div class="other-items-container d-flex flex-wrap p-0"">
                <item-cards
                v-if="isShowOtherItems()"
                v-for="item in items"
                :key="item.id"
                :item="item"
                :website="website"
                :websiteType="websiteType"
                :store="store"
                :hidePin="true"
                :isVideoAdUser="isVideoAdUser"
                :websiteId="websiteId"
                :presetAssetThumbnail="presetAssetThumbnail"
                :presetImageThumbnail="presetImageThumbnail"
                v-on:onUpdateItemVisibility="fetchItems"
                />
            </div>
        </div>
    </div>
    `
}

export { ItemsetDetails }