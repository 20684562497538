import Vue from 'vue';
import ApiService, { ReportService } from "../common/api.service.js"
import {ItemDetailsModal} from "../ItemDetails/ItemDetailsModal.js";

let TopAdsByCTRWidget = {
    data: function () {
        return ({
            ads: [],
            items: [], // items of selected ads
            fields: {
                'ads': ['ad_name', { key: 'ctr', label: 'CTR' }, { key: 'total_impressions', label: 'Impressions' }, { key: 'total_clicks', label: 'Clicks' },],
                'items': ['item_name', { key: 'ctr', label: 'CTR (30 days)' }, 'impressions', 'clicks'],
            },
            busy: false,
            selectedAd: null,
            selectedAdUrl: null,
            itemDetailModalKey: 'top-ads-by-CTR-widget'
        })
    },
    props: {
        website: String,
        websiteName: String,
        websiteType: String,
        isVideoAdUser: String,
        presetAssetThumbnail: String,
        presetImageThumbnail: String
    },
    computed: {
        componentProps: function () {
            return {
                website: this.website,
                websiteName: this.websiteName,
                websiteType: this.websiteType,
                presetAssetThumbnail: this.presetAssetThumbnail,
                presetImageThumbnail: this.presetImageThumbnail,
                isVideoAdUser: this.isVideoAdUser,
                itemDetailModalKey: this.itemDetailModalKey
            }
        },
    },
    watch: {
        selectedAd: {
            handler() {
                if (this.selectedAd) this.getTopItems();
            },
            deep: true
        },
    },
    methods: {
        getTopAds() {
            this.busy = true
            ReportService.get("WEBSITE_ADS_TOP_PERFORMER", this.website).then(response => {
                this.ads = response.data
                this.busy = false
            }).catch(err => {
                this.busy = false
            })
        },
        getTopItems() {
            this.busy = true
            // Handle if url has different format
            let queryUrl = this.selectedAdUrl.replace('/api/', '');

            ApiService.query2(queryUrl).then(response => {
                this.items = response.data
                this.busy = false
            }).catch(err => {
                this.busy = false
            })
        },
        onAdClick(adName, adLink) {
            this.selectedAd = adName
            this.selectedAdUrl = adLink
        },
        resetSelectedAd() {
            this.selectedAd = null
            this.selectedAdUrl = null
        }
    },
    mounted: function () {
        this.getTopAds();
    },
    template: `
        <div class="dashboard-component widget-height w-50 mr-3 top-ads-by-ctr shadow-sm bg-white">
            <div class="panel-header container">
                <h5 class="widget-title mb-3">
                    <b-row class="align-items-baseline">
                        <div v-if="selectedAd" class="d-flex">
                            <p @click="resetSelectedAd" class="border-0 p-0 pr-3 pl-0">
                                <font-awesome-icon :icon="['fas', 'chevron-left']" />
                            </p>
                            <p>{{ selectedAd }}</p>
                        </div>
                        <p v-else>Top ads by CTR <span class="opacity-50">(30 days)</span></p>
                    </b-row>
                </h5>
            </div>
            <div v-if="!selectedAd" class="ads-panel">
                <b-table class="stacked-data-source-label table-max-height" responsive stacked="xl" borderless hover :items="ads" :fields="fields['ads']" :busy="busy" thead-class="data-source-label" show-empty>
                        <template #table-busy>
                            <div class="d-flex justify-content-center my-5">
                                <b-spinner variant="secondary" label="Loading..."></b-spinner>
                            </div>
                        </template>
                        <template #empty="scope">
                        <h4 class="my-5 no-data text-center">No Data</h4>
                        </template>
                        <template #cell()="data">
                            <top-row-ctr :field="data.field" :value="data.value" rowType="ad" :rowData="data.item" v-bind="{...componentProps}" v-on:onClick="onAdClick"></top-row-ctr>
                        </template>
                </b-table>
            </div>

            <div v-if="selectedAd" class="items-panel">
                <b-table class="stacked-data-source-label table-max-height" responsive stacked="xl" borderless hover :items="items" :fields="fields['items']" :busy="busy" thead-class="data-source-label" show-empty>
                    <template #table-busy>
                        <div class="d-flex justify-content-center my-5">
                            <b-spinner variant="secondary" label="Loading..."></b-spinner>
                        </div>
                    </template>
                    <template #empty="scope">
                    <h4 class="my-5 no-data text-center">No Data</h4>
                    </template>
                    <template #cell()="data">
                        <top-row-ctr :field="data.field" :value="data.value" rowType="item" :rowData="data.item" v-bind="{...componentProps}"></top-row-ctr >
                    </template>
                </b-table>
            </div>
          </div>
    `
}

Vue.component("top-row-ctr", {
    props: {
        rowData: Object,
        rowType: String,
        website: String,
        websiteName: String,
        field: Object,
        value: [Number, String],
        websiteType: String,
        isVideoAdUser: String,
        presetAssetThumbnail: String,
        presetImageThumbnail: String,
        itemDetailModalKey: String
    },
    methods: {
       formatToTwoDecimal (val) {
            return val != 0 ? parseFloat(val).toFixed(2) + "%" : val
        },
        formatCtrPercent (val) {
            return val !== 0 && Math.abs(val) !== Infinity ? Math.round(Math.abs(val) * 100) + "%" : ""
        },
        getBadgeColor(val) {
            if (val > 0) {
                return "data-source-pill-positive"
            } else if (val < 0) {
                return "data-source-pill-negative"
            } else {
                return "data-source-pill-neutral"
            }
        },
        getPercentIcon (val) {
            let iconName, rotation;
            if (val >  0) {
                iconName = 'long-arrow-alt-up';
                rotation =  45;
            } else if (val <  0) {
                iconName = 'long-arrow-alt-down';
                rotation = -45;
            } else {
                iconName = 'slash';
                rotation = -38;
            }
            return { icon: ['fas', iconName], rotation };
        },
        onAdNameClick(adName) {
            if (this.rowType === 'item') {
                window.location.href = `${this.websiteName}/items/${this.rowData.item_id}`
            }else if(this.rowType === 'ad'){
                this.$emit('onClick', adName, this.adLink)
            }
        }
    },
    computed: {
        key_name: function(){
            return this.field['key']
        },
        improvement: function() {
            return this.rowData.improvement
        },
        badgeColor: function() {
            return this.isCtr ? this.getBadgeColor(this.improvement) : null;
        },
        percentIcon: function() {
            return this.isCtr ? this.getPercentIcon(this.improvement) : null;
        },
        ctrPercent: function() {
            return this.isCtr ? this.formatCtrPercent(this.improvement) : null;
        },
        isCtr: function() {
            return this.key_name == 'ctr'
        },
        adLink: function() {
            return this.rowData['ad_url'] ? this.rowData['ad_url'] : ''
        },
        name_key_name: function () {
            return this.rowType === 'ad' ? 'ad_name' : 'item_name'
        },
    },
    components: {
        "item-modal": ItemDetailsModal
    },
    template: `
        <div class="topColumn" :class="key_name === name_key_name ? 'name' : ''">
            <div v-if="key_name === name_key_name">
                <a v-if="key_name === 'ad_name'" href="#/" @click="onAdNameClick(rowData[name_key_name])" class="ellipsis border-0 text-left" :data-text="rowData[name_key_name]">{{ rowData[name_key_name] }}</a>
                <p v-if="key_name == 'item_name'">
                    <a :class="rowData.item_id" href="#" v-b-modal="rowData.item_id + itemDetailModalKey" >{{ rowData.item_name }}</a>
                    <item-modal 
                        :modalKey="itemDetailModalKey"
                        :item="rowData"
                        :item_type="websiteType"
                        :websiteName="websiteName"
                        :website="websiteName"
                        :websiteId="website"
                        :presetAssetThumbnail="presetAssetThumbnail"
                        :isVideoAdUser="isVideoAdUser"
                    ></item-modal>
                </p>
            </div>
            <div v-else-if="isCtr">{{ formatToTwoDecimal(rowData.ctr) }} 
                <b-badge pill :class="badgeColor">{{ ctrPercent }} 
                    <font-awesome-icon :icon="percentIcon.icon" :style="{ transform: 'rotate(' + percentIcon.angle + 'deg)' }" />
                </b-badge>
            </div>
            <div v-else>{{ value }}</div>
        </div>
    `
})

export {TopAdsByCTRWidget}