/* Define which fields will be shown under item detail modal's item tab, based on existing `ItemType`
** `keyName` based on the object returned from backend, 
** e.g. `objectFromBackend.price` = enter 'price' in `keyName` 
*/
let displayFieldsObjects = {
    'product': [
        {
            'displayName': 'Price',
            'keyName': 'price'
        },
        {
            'displayName': 'Brand',
            'keyName': 'brand'
        },
        {
            'displayName': 'Category',
            'keyName': 'category'
        },
        {
            'displayName': 'Product Number',
            'keyName': 'product_number'
        },
        {
            'displayName': 'Availability',
            'keyName': 'availability'
        },
        {
            'displayName': 'Original Page',
            'keyName': 'item_details.Original Page',
            'isUrl': true
        }
    ],
    'property': [
        {
            'displayName': 'Price',
            'keyName': 'price'
        },
        {
            'displayName': 'Square Feet',
            'keyName': 'square_feet'
        },
        {
            'displayName': 'Baths',
            'keyName': 'item_details.Baths'
        },
        {
            'displayName': 'Beds',
            'keyName': 'item_details.Beds'
        },
        {
            'displayName': 'Address',
            'keyName': 'item_details.Address'
        },
        {
            'displayName': 'Original Page',
            'keyName': 'item_details.Original Page',
            'isUrl': true
        }
    ],
    'vehicle': [
        {
            'displayName': 'Price',
            'keyName': 'price'
        },
        {
            'displayName': 'Manufacturer',
            'keyName': 'item_details.Manufacturer'
        },
        {
            'displayName': 'Model',
            'keyName': 'model'
        },
        {
            'displayName': 'Year',
            'keyName': 'item_details.Year'
        },
        {
            'displayName': 'Condition',
            'keyName': 'item_details.Condition'
        },
        {
            'displayName': 'Trim',
            'keyName': 'item_details.Trim'
        },
        {
            'displayName': 'Type',
            'keyName': 'item_details.Type'
        },
        {
            'displayName': 'VIN',
            'keyName': 'item_details.VIN'
        },
        {
            'displayName': 'Original Page',
            'keyName': 'item_details.Original Page',
            'isUrl': true
        }
    ],
    'event': [
        {
            'displayName': 'Price',
            'keyName': 'price'
        },
        {
            'displayName': 'Start Time',
            'keyName': 'item_details.Start time'
        },
        {
            'displayName': 'End Time',
            'keyName': 'item_details.End time'
        },
        {
            'displayName': 'Original Page',
            'keyName': 'item_details.Original Page',
            'isUrl': true
        }
    ],
    'job': [
        {
            'displayName': 'Company',
            'keyName': 'item_details.Company'
        },
        {
            'displayName': 'Title',
            'keyName': 'item_details.Title'
        },
        {
            'displayName': 'City',
            'keyName': 'item_details.City'
        },
        {
            'displayName': 'Province',
            'keyName': 'item_details.Province'
        },
        {
            'displayName': 'Country',
            'keyName': 'item_details.Country'
        },
        {
            'displayName': 'Original Page',
            'keyName': 'url',
            'isUrl': true
        }
    ],
    'lottery': [
        {
            'displayName': 'Name',
            'keyName': 'item_details.Name'
        },
        {
            'displayName': 'Prize Value',
            'keyName': 'item_details.Prize Value'
        },
        {
            'displayName': 'Draw Date',
            'keyName': 'item_details.Draw Date',
            'isDate': true
        },
        {
            'displayName': 'Landing Page',
            'keyName': 'item_details.Landing Page',
            'isUrl': true
        },
        {
            'displayName': 'MaxMillions',
            'keyName': 'item_details.MaxMillions'
        }
    ]
}

export default displayFieldsObjects;