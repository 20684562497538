let LogoCard = {
    props: {
        logo: Object,
        preset_image_thumbnail: String,
        currentSelection: String,
    },
    computed: {
        activeClass: function() {
            return this.currentSelection === this.logo.id ? "active-selection" : ""
    },
        defaultClass: function () {
            return this.logo.default ?  "star-button-default" : ""
        }
    },
    template:
        `
    <div class="m-2">
        <div class="content__theme bg-logo" :class="activeClass" v-on:click="$emit('updateSelection', logo)">
            <img class="content__product-image" v-bind:src="logo.asset ? logo.asset : preset_image_thumbnail">
            <div class="card-titleonly"> {{ logo.name }} </div>
            <!-- The star -->   
            <button @click.stop="$emit('updateDefault', logo)" :class="['star-button', defaultClass]" ></button>
        </div>
    </div>
    `,
}

export {LogoCard}
