import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

const DatePickerFormGroup = {
    props: {
        required: Boolean,
        field_name: String,
        label: String,
        value: String,
        help_text: String,
        errorText: String
    },
    template: `
  <div :id="'div_id_' + field_name" class="form-group">
    <label :for="'id_' + field_name" :class="{requiredField: required, 'is-invalid': errorText}" class="font-weight-bold">{{ label }}<span v-if="required" class="asteriskField">*</span></label>
    <datepicker :name="field_name"
            :placeholder="'Choose a date'"
            :inputClass="'form-control mb-0 bg-white' + (errorText ? ' is-invalid': '')"
            :format="formatDate"
            :typeable="false"
            :required="true"
            :value="value"
            @selected="$emit('updateDate',$event)"
            :disabled-dates="disabledDates()"
            :id="'id_' + field_name">
    </datepicker>
    <small class="form-text text-muted">{{help_text}}</small>
    <p v-if="errorText" :id="'error_1_id_' + field_name" class="invalid-feedback"><strong>{{ errorText }}</strong></p>
  </div>`,
    components: {
        datepicker: Datepicker
    },
    methods: {
        formatDate: function (date) {
            return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
        },
        disabledDates: function () {
            return {
                to: new Date(moment().subtract(1, 'days'))
            }
        }
    }
}


const DatePickerFormGroupForReport = {
    props: {
        required: Boolean,
        field_name: String,
        label: String,
        value: String
    },
    template: `
  <div :id="'div_id_' + field_name" class="form-group mb-3">
    <label :for="'id_' + field_name" :class="{requiredField: required}" class="font-weight-bold mr-2">{{ label }}<span v-if="required" class="asteriskField">*</span></label>
    <datepicker :name="field_name"
            :placeholder="'Choose a date'"
            :inputClass="'form-control bg-white'"
            :format="formatDate"
            :typeable="false"
            :required="true"
            :value="value"
            :id="'id_' + field_name">
    </datepicker>
  </div>`,
    components: {
        datepicker: Datepicker
    },
    methods: {
        formatDate: function (date) {
            return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
        }
    }
}

export { DatePickerFormGroup, DatePickerFormGroupForReport };