import {ItemsetCard} from "./ItemsetCard.js";
import {ItemsetDetails} from "./ItemsetDetails.js";
import {ItemSetService} from "../common/api.service.js";
import { debounce, isEqual } from 'lodash';

let ItemsetList = {
    components: {
        "itemset-card": ItemsetCard,
        "itemset-details": ItemsetDetails,
    },
    props: {
        resource: String,
        website: String,
        default_image: String,
        showLearningSets: Boolean,
        hideDefaultSet: Boolean,
        store: Object,
        websiteType: String,
        isVideoAdUser: String,
        websiteId: String,
        presetAssetThumbnail: String,
        presetImageThumbnail: String
    },
    data: function () {
        return ({
            itemsets: [],
            sortBy: 'name',
            sortDesc: false,
            loading: false,
            dataReady: false,
            pageSize: "15", // Use string type to make sure matched to current route query's type
            pageNumber: 1, // For item sets paging, default is 1 as api param "page" starts at 1
            totalPages: 0,
            isLastPageShown: false, // Indicate if last page of item sets is showing
            allSetType: ["custom","learning"],
            learningSetTypes: ["newest", "most_viewed"],
            searchQuery: "", // Search set,
            isLastSetsPageShown: false,
            scrollDebounce: true,  // Prevent `detectScrolledToBottom` to have multiple detection
            isDetectScrollInit: false,
            highlightSetId: '', // To store itemset id from edit / create set page, to highlight that in sets list
            preSelectedSetId: '' // To store itemset id from router (middle click)
        })
    },
    computed: {
        itemsetType: {
            get() { return this.$route.query.type || 'default' },
            // Prevent the b-form-radio-group from setting this when using v-model="itemsetType"
            set(value) {}
        }
    },
    watch: {
        itemsetType: function () {
            this.getItemsets();
        },
        searchQuery: debounce(function (newVal, oldVal) {
            this.searchResults = []; // Clear search result
            if(newVal.length) {
                this.getItemsets();
            }else{
                this.getItemsets(true); // fresh start
            }
        }, 1000),
        sortBy: debounce(function (newURL, oldURL) {
            this.searchResults = []; // Clear search result
            this.pageNumber = 1;
            this.getItemsets(true);
        }, 1000),
    },
    methods: {
        getParams(isFreshStart = false) {
            let params = {};
            if (isFreshStart) {
                params.page = 1
            }
            else if (this.pageNumber > 1 && !this.searchQuery.length) {
                params.page = this.pageNumber
            }
            params.ordering = this.sortBy
            params.page_size = this.pageSize
            return params;
        },
        isDefault(item) {
            return item.type === "default"
        },
        isLearning(itemset) {
            return this.learningSetTypes.indexOf(itemset.type) > -1
        },
        getItemsets(isFreshStart = false) {
            let itemsetService = ItemSetService.init(this.website);
            let params = this.getParams(isFreshStart)
            if(this.searchQuery.length) params.search = this.searchQuery
            itemsetService.query(params)
            .then(response => {
                if(this.totalPages !== response.total_pages) this.totalPages = response.total_pages
                if(this.pageNumber > 1 && !this.searchQuery.length) {
                    this.itemsets = this.itemsets.concat(...response.results) // Concat new page itemsets into old itemsets array
                }else{
                    this.itemsets = response.results;
                }
                if(this.hideDefaultSet) this.itemsets = this.removeDefaultSet(this.itemsets) // Remove default set in list
                if(response.next) {
                    this.isLastPageShown = false
                }else{
                    this.isLastPageShown = true // Hide "Show More" button
                }
                this.dataReady = true
                if (!this.store.state.itemset && !this.highlightSetId && !this.preSelectedSetId && !this.searchQuery.length) {
                    this.switchItemset(this.itemsets[0]) // Default select the first item set to display
                }else if(!this.searchQuery.length) {
                    if(this.highlightSetId) {
                        this.handleHighlightSet(response.results, this.highlightSetId, "highlightedSetDiv")
                    } else if (this.preSelectedSetId) {
                        this.handleHighlightSet(response.results, this.preSelectedSetId, "preSelectedSetDiv")
                    }
                }
                if(isFreshStart && !this.preSelectedSetId && !this.highlightSetId){
                    this.$refs.setsContainer.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
                // Handle set list detection of scroll to bottom
                let _this = this;
                setTimeout(function () {
                    if (!_this.scrollDebounce) {
                        _this.scrollDebounce = true
                    }
                }, 500); // To prevent multiple call
            })
        },
        handleHighlightSet(responseResult, setId, setDivName){
            let highlightedSet = responseResult.find(set => set.id === setId)
            if(!highlightedSet){
                if(this.pageNumber !== this.totalPages) {
                    // Load more sets to get the newly edited / created set
                    this.showMoreItemsets();
                    return
                }else{
                    // Searched through all pages and cannot found the highlight set
                    // Most likely will happen when deleted a set from list
                    this.switchItemset(this.itemsets[0]) // Default select the first item set to display
                    this.$refs.setsContainer.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            }else{
                this.switchItemset(highlightedSet)
                this.$nextTick(() => {
                    this.$refs[setDivName][0].scrollIntoView({ behavior: 'smooth' });
                })
            }
        },
        removeDefaultSet(itemsets) {
            return itemsets.filter(function (itemset) {
                return itemset.type !== 'default';
            });
        },
        async refresh(ctx = {}) {
            this.loading = true;
            this.pageNumber = 1; // Reset to page 1
            this.isLastPageShown = false;
            this.store.updateItemset(null) // clean store's itemset
            this.getItemsets();
            this.loading = false
        },
        updateRoute(params) {
            let query = {...this.$route.query};
            Object.assign(query, params);
            if (!isEqual(query, this.$route.query)) {
                this.$router.push({query});
            }
        },
        newSetLink(type = '') {
            if (type === 'learning') {
                this.$router.push({ name: 'itemsets-learn-create' })
                return
            }
            this.$router.push({ name: 'itemsets-create' })
        },
        confirmDelete(itemset) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this set?').then((result) => {
                if (result) {

                    ItemSetService.init(this.website).destroy(itemset.id).then((response) => {
                        this.refresh();
                    })
                }
            })
        },
        editItemset(itemset) {
            if(itemset.type === 'custom') {
                this.$router.push({name: 'itemsets-edit', params: {id: itemset.id}});
            } else {
                this.$router.push({name: 'itemsets-learn-edit', params: {id: itemset.id}});
            }
        },
        showMoreItemsets() {
            if(this.pageNumber === this.totalPages) return
            this.pageNumber++;
            this.getItemsets();
        },
        switchItemset(itemset){
            if(!itemset) return
            this.store.updateFilter(itemset.item_filter)
            this.store.updateItemset(itemset)
            // Update highlighted item if `hl` is in query
            if (this.$route?.query.hl) {
                const updatedQuery = {
                    ...this.$route.query,
                    hl: itemset.id
                };
                this.$router.replace({ query: updatedQuery }).catch(err => {
                    // To silent the error: 'NavigationDuplicated'
                });
            }
        },
        onSetsListScroll({ target: { scrollTop, scrollHeight, offsetHeight } }) {
            if ((Math.ceil(scrollTop + offsetHeight) >= scrollHeight - 10) && this.scrollDebounce) {
                this.scrollDebounce = false;
                this.showMoreItemsets();
            }
        },
        onItemsetMiddleClick(itemset_id) {
            var route = this.$router.resolve({ name: 'itemsets', query: { setId: itemset_id } })
            window.open(route.href, '_blank')
        },
    },
    mounted: function () {
        if (this.$route?.params.hightlightedSet || this.$route?.query.hl) {
            this.highlightSetId = this.$route?.params.hightlightedSet ? this.$route?.params.hightlightedSet : this.$route?.query.hl
        }
        if (this.$route?.query.setId) {
            this.preSelectedSetId = this.$route?.query.setId
        }
        this.updateRoute(this.getParams());
        this.refresh();
    },
    template: `
    <div class="item-sets">
        <div v-if="!dataReady" class="d-flex vh-100 justify-content-center align-items-center">
            <b-spinner class="" variant="secondary" label="Loading..."></b-spinner>
        </div>
        <div v-else class="item-sets-container d-flex flex-column">
            <div class="item-sets-header d-flex justify-content-between align-items-center mb-4 py-2">
                <h4 class="page-title m-0">Sets</h4>
                <b-dropdown variant="primary" text="Create" class="">
                    <b-dropdown-item @click="newSetLink">Set</b-dropdown-item>
                    <b-dropdown-item @click="newSetLink('learning')">Learning Set</b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="item-sets-content d-flex flex-row">
                <div class="sets-list-container pt-3 mr-4 default-theme-border-radius">
                    <div class="sets-list-header d-flex justify-content-between px-3">
                        <b-input-group class="search-input-group border w-75 rounded">
                            <b-input-group-prepend>
                                <span class="input-group-text border-0 bg-transparent"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></span>
                            </b-input-group-prepend>
                            <b-form-input v-model="searchQuery" class="search-input border-0" size="md" placeholder="Search set" ref="searchInputField"></b-form-input>
                        </b-input-group>
                        <b-dropdown variant="muted" class="sort-by-dropdown" toggle-class="text-decoration-none" no-caret v-model="sortBy">
                            <template #button-content>
                                <font-awesome-icon class="opacity-50" :icon="['fas', 'arrow-down-wide-short']" size="lg" />
                            </template>
                            <b-dropdown-text>Sort by:</b-dropdown-text>
                            <b-dropdown-item :class="{selected: sortBy === 'name'}" value="name" @click="sortBy = 'name'">Name</b-dropdown-item>
                            <b-dropdown-item :class="{selected: sortBy === 'type'}" value="type" @click="sortBy = 'type'">Type</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="sets-container mt-4" v-if="itemsets.length > 0" ref="setsContainer" @scroll="onSetsListScroll">
                        <a 
                        v-for="itemset in itemsets" 
                        v-if="!isDefault(itemset) && (isLearning(itemset) ? showLearningSets ? true : false : true)"
                        @click="switchItemset(itemset)"
                        @click.middle="onItemsetMiddleClick(itemset.id)"
                        :class="{ selected: itemset.id === store.state.itemset?.id  }"
                        >
                            <div 
                            class="sets pl-3 py-2" 
                            :key="itemset.id" 
                            :ref="itemset.id === highlightSetId ? 'highlightedSetDiv' : itemset.id === preSelectedSetId ? 'preSelectedSetDiv' : ''">
                                <p class="mb-0 itemset-name">{{ itemset.name }}</p>
                                <p class="mb-0 itemset-item-count">
                                    <span class="smaller-font-size">{{ itemset.item_count }} items</span>
                                    <b-badge class="set-type-pill" v-if="itemset.type !== 'custom'" pill variant="secondary">
                                        <span v-if="isLearning(itemset)">Learning</span>
                                    </b-badge>
                                </p>
                                <div v-if="itemset.id === highlightSetId" class="highlight-cover position-absolute"></div>
                            </div>
                        </a>
                    </div>
                    <div v-else class="h-100 d-flex align-items-center justify-content-center">
                        <p>You have no sets</p>
                    </div>
                </div>
                <div class="set-details-container w-100 p-3 bg-white default-theme-border-radius">
                    <itemset-details 
                    :isLearning="isLearning"
                    v-on:edit="editItemset"
                    v-on:delete="confirmDelete"
                    :website="website"
                    :store="store"
                    :websiteType="websiteType"
                    :isVideoAdUser="isVideoAdUser"
                    :websiteId="websiteId"
                    :presetAssetThumbnail="presetAssetThumbnail"
                    :presetImageThumbnail="presetImageThumbnail"
                    />
                </div>
            </div>
        </div>
    </div>
    `

}

export {ItemsetList}