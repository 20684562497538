import vue2Dropzone from 'vue2-dropzone'
import Cookies from "js-cookie";

let SourceFileDropzone = {
    props: {
        website: String,
        collapsible: Boolean,
        pageName: String,
        themeVersionId: String,
        isShowHelp: {
            default: true,
            type: Boolean
          }
    },
    data: function () {
        return ({
            isDropzoneExpanded: false,
            tempAttachments: [], // For display uploaded files and progress
            maxProgressValue: 100,
            files: [], // Store original dropped file, use for abort upload function and upload to endpoint
            statusList: {
                default: 'processing', // Default asset's status, each asset status will keep updating after uploaded to endpoint, only used in frontend
                /* Status from backend */
                created: 'created', // Control if enable delete function, deletion is available after the video is uploaded to S3 and created record in DB
                success: 'validated', // Final stage status of asset, to determine if all process in backend is finished
                fail: 'failed' // Error status, show errors from backend if asset has this status
            },
            isUploading: false,
            isShowDropzoneErrorState: false, // Control showing error state display
            hasDropzoneError: false, // Control to stop proceed when error occurs, reset in nextTick after detected error
            dropzoneErrorMessage: "",
            // TODO update file URL
            demoThemeFile: { // Show in help popover for user to download, varies based on advertiser's inventory type
                'themesList' : 'url',
                'themeDetails' : 'url',
            }, 
            dropzoneOptions: {
                // The Url Where Dropped or Selected files will be sent
                url:  'api/', // Will be update when theme version id is ready
                headers: {
                    "X-CSRFToken": Cookies.get('csrftoken')
                },
                // Param name for the file when send file to endpoint 
                paramName: function(n) {
                    return "source_file";
                },
                maxFiles: 1, // Allow only one file to upload
                maxFilesize: 100, // File Size allowed in MB
                acceptedFiles: ".zip",
                includeStyling: true,
                previewsContainer: false, // Use default preview container
                autoProcessQueue: false, // Stop auto upload so we can stop proceed if having errors
                uploadMultiple: false, // Send multiple files in one request
                addRemoveLinks: true,
                timeout: 0, // Milliseconds, set to null / 0 to disable timeout
                method: "patch"
            }
        })
    },
    watch: {
        isDropzoneExpanded: {
            handler: function (value) {
                this.$emit("updateDropzoneExpandedStatus", this.isDropzoneExpanded)
            }
        },
        themeVersionId: {
            handler() {
                this.$refs.sourceFileDropzone?.setOption('url', `/api/theme_versions/${this.themeVersionId}?is_temporary=true`);
            },
        },
    },
    methods: {
        resetDropzone(){
            this.isShowDropzoneErrorState = false
            this.hasDropzoneError = false
            this.dropzoneErrorMessage = ""
            // Clear files
            if(this.$refs.sourceFileDropzone) this.$refs.sourceFileDropzone.removeAllFiles()
            if(this.tempAttachments) this.tempAttachments = []
        },
        /* Drop zone flow and functions */
        // function called for every file dropped or selected
        fileAdded(file) {
            // Reset error state
            this.isShowDropzoneErrorState = false

            // Store the file blob in array
            this.files = [...this.files, file]

            // Construct file object to render in the UI
            let attachment = {};
            attachment._id = file.upload.uuid;
            attachment.title = file.name;
            attachment.type = "file";
            attachment.size = file.size;
            attachment.isLoading = true;
            attachment.progress = null;
            attachment.status = this.statusList.default;
            attachment.isError = false
            attachment.error = []
            attachment.dimension = ""

            this.tempAttachments = [...this.tempAttachments, attachment];

            // Check if having any error
            this.$nextTick(() => {
                if(!this.hasDropzoneError) {
                    this.$refs.sourceFileDropzone.processQueue()
                    this.isUploading = true
                }
            })
            
        },
        // function where we get the upload progress
        uploadProgress(file, progress, bytesSent) {
            this.tempAttachments.map(attachment => {
                if (attachment._id === file.upload.uuid) {
                    attachment.progress = `${Math.floor(progress)}`;
                }
            });
        },
        // called on successful upload of a file
        uploadSuccess(file, response){
            this.isUploading = false
            const tempAttachmentsIdx = this.getTempAttachmentIdxByUploadUUID(file)
            if (response === undefined) {
                this.tempAttachments[tempAttachmentsIdx].status = this.statusList.fail
            }
            this.removeFile(this.tempAttachments[tempAttachmentsIdx]);
            this.$emit('uploadSourceFileCallback', response)
        },
        dropzoneError(file, message, xhr){
            if(this.isUploading) this.isUploading = false
            this.isShowDropzoneErrorState = true // Display error state
            this.hasDropzoneError = true
            this.dropzoneErrorMessage = message.error || message

            // Clear files after detected error
            const tempAttachmentsIdx = this.getTempAttachmentIdxByUploadUUID(file)
            this.$refs.sourceFileDropzone.removeAllFiles()
            this.removeFile(this.tempAttachments[tempAttachmentsIdx])

            this.$nextTick(() => {
                // Reset at next tick so when user put correct file again, processQueue() will be called
                this.hasDropzoneError = false
            })
        },
        removeFile(fileToRemove){
            // Remove files in list in display
            const tempAttachmentsIndex = this.getTempAttachmentsIndex(fileToRemove)
            if (tempAttachmentsIndex > -1) {
                // Will only call api to delete file if asset status is or after created
                if(this.tempAttachments[tempAttachmentsIndex].status !== this.statusList.default && this.tempAttachments[tempAttachmentsIndex].assetId) {
                    this.store.assetsService.destroy(this.tempAttachments[tempAttachmentsIndex].assetId)
                    .then(response => {
                        this.tempAttachments.splice(tempAttachmentsIndex, 1)
                    });
                }else{
                    this.tempAttachments.splice(tempAttachmentsIndex, 1)
                }
            }
            // Get the file in files list, remove and abort request
            const fileIndex = this.files.map(f => f.upload.uuid).indexOf(fileToRemove._id);
            if(fileIndex >= 0 && this.files[fileIndex].xhr) this.files[fileIndex].xhr.abort() // Stop posting request
            this.files.splice(fileIndex, 1) // Remove file from file list
        },
        /* Utils */
        getTempAttachmentsIndex(targetAttachment){
            return this.tempAttachments.indexOf(targetAttachment)
        },
        checkProgress(attachment) {
            return attachment.progress === null ? false : true;
        },
        getProgressBarVariant(status) {
            switch (status) {
                case this.statusList.success:
                  return 'success'
                case this.statusList.fail:
                    return 'danger'
                default:
                    return 'info'
            } 
        },
        getTempAttachmentIdxByUploadUUID(file){
            return this.tempAttachments.map(attachment => attachment._id).indexOf(file.upload.uuid);
        },
    },
    computed: {
        getTempAttachments() {
          return this.tempAttachments;
        },
        hasAttachments: function () {
            return this.tempAttachments.length
        },
        isAllAssetsInFinalStage: function () {
            return this.tempAttachments.every((attachment) => attachment.status === this.statusList.fail || attachment.status === this.statusList.success );
        },
        isAllAssetsValidated: function () {
            return this.tempAttachments.every((attachment) => attachment.status === this.statusList.success );
        },
    },
    components: {
        vueDropzone: vue2Dropzone
    },
    template: `
    <div class="source-file-dropzone file-dropzone w-100 d-flex flex-column position-relative">
        <div v-if="tempAttachments.length > 0 && !isShowDropzoneErrorState"  class="file-list w-100 px-3 py-5 position-absolute bg-white">
            <ul v-if="tempAttachments.length > 0" class="list-unstyled">
                <li v-for="tempAttachment in tempAttachments" :key="tempAttachment._id" class="mb-3">
                    <p>
                        <font-awesome-icon class="text-secondary" :icon="['fas', 'spinner']" spin />
                        Uploading: <b>{{ tempAttachment.title }}</b>
                    </p>
                    <b-progress 
                    v-if="checkProgress(tempAttachment)" 
                    :value="tempAttachment.progress" 
                    :max="maxProgressValue" 
                    :animated="tempAttachment.status === statusList.success || tempAttachment.status === statusList.fail ? false : true"
                    :variant="getProgressBarVariant(tempAttachment.status)"></b-progress>
                    <p class="text-center mt-2">Please hold on while uploading, you will be redirected soon.</p>
                </li>
            </ul>
        </div>

        <vue-dropzone
        class="rounded"
        :class="{'error bg-danger text-white darken-hover' : isShowDropzoneErrorState }"
        ref="sourceFileDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        @vdropzone-file-added="fileAdded"
        @vdropzone-upload-progress="uploadProgress"
        @vdropzone-success="uploadSuccess"
        @vdropzone-error="dropzoneError"
        >
            <div class="file-selector">
                <div v-if="!isShowDropzoneErrorState" class="default-view">
                    <font-awesome-icon :icon="['far', 'file']" class="mb-4" size="2xl" />
                    <p class="m-0">Upload a source file</br>Browse files, or drop here</p>
                </div>
                <div v-else class="error-view">
                    <font-awesome-icon class="mb-4" :icon="['fas', 'circle-exclamation']" size="2xl" />
                    <p class="m-0">Error: {{ dropzoneErrorMessage }} 
                    </br>Please try uploading again.
                    </p>
                </div>
            </div>
        </vue-dropzone>

    </div>
    `
}

export { SourceFileDropzone }

