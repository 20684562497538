import displayFieldsObjects from './DisplayFieldsObjects.js'

let ItemDetailTabContent = {
    props: {
        item: Object,
        item_type: String
    },
    computed: {
        displayFields: function () {
            return displayFieldsObjects[this.item_type]
        },
    },
    methods: {
        getValueByKeyName(obj, keyName) {
            let keySplit = keyName.split('.');
            if (keySplit.length > 1) {
                return this.getValueByKeyName(obj[keySplit[0]], keySplit.slice(1, keySplit.length).join("."));
            }
            if (keySplit.length == 1) {
                return obj[keyName];
            } else {
                return obj;
            }
        }
    },
    template: `
    <div class="">
        <div v-for="field in displayFields" class="row mb-3" :key="field.displayName">
            <div class="col-sm font-weight-bold">{{field.displayName}}:</div>
            <div v-if="field.isUrl" class="col-sm"><a :href="getValueByKeyName(item, field.keyName)" target="_blank" class="wrapped_td">{{ getValueByKeyName(item, field.keyName) }}</a></div>
            <div v-else-if="field.isDate" class="col-sm" :title="new Date(getValueByKeyName(item, field.keyName)).toUTCString()">{{ new Date(getValueByKeyName(item, field.keyName)).toLocaleString() }}</div>
            <div v-else class="col-sm text-truncate">{{ getValueByKeyName(item, field.keyName) }}</div>
        </div>
        <div class="row mb-1" v-if="item.description">
            <div class="col font-weight-bold">
                Description:
            </div>
        </div>
        <div class="row mb-1">
            <div class="col" v-if="item.description?.length<256">
                {{item.description}}
            </div>
            <div class="col" v-else>
                <span v-if="item.description">{{item.description?.substring(0, 256)+"..."}}</span>
            </div>
        </div>
    </div>
`
};

export { ItemDetailTabContent };
