const CALLBACK_NAME = 'gmapsCallback';

// !! here converts window.google to boolean
let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export default function init() {

}

let GoogleMap = {
    data: function () {
        return ({
            map: null,
            circle: null,
            radius: this.crop.radius,
            currentMarker: null,
            loadingAddress: false
        })
    },
    props: {
        crop: Object,
        googleMapsApiKey: String,
        exporting: Boolean,
        edited: Boolean
    },
    watch: {
        crop: function(newCrop, oldCrop) {
            this.radius = newCrop.radius
            if (this.edited) {
                this.removeMarker(this.currentMarker)
                this.removeCircle(this.circle)
            }
            this.addMapMarker(newCrop.latitude, newCrop.longitude, this.map, newCrop.radius, newCrop.address)
            this.loadingAddress = false

        }
    },
    methods: {
        initMap() {
            // If Google Maps already is initialized
            // the `initPromise` should get resolved
            // eventually.
            if (initialized) return initPromise;
            initialized = true;
            // The callback function is called by
            // the Google Maps script if it is
            // successfully loaded.
            window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

            // We inject a new script tag into
            // the `<head>` of our HTML to load
            // the Google Maps script.
            const script = document.createElement('script');
            script.async = true;
            script.defer = true;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsApiKey}&callback=${CALLBACK_NAME}`;
            script.onerror = rejectInitPromise;
            document.querySelector('head').appendChild(script);

            return initPromise;
        },
        addMapMarker(lat, lon, map, radius, address) {
            this.currentMarker = new google.maps.Marker({
                position: {lat: lat, lng: lon}, map, title: address
            })
            map.setCenter({lat: lat, lng: lon});
            this.circle = new google.maps.Circle({
                map,
                center: {lat: lat, lng: lon},
                radius: radius,
            });

        },
        removeMarker(marker){
            marker.setMap(null)
        },
        removeCircle(circle){
            circle.setMap(null)
        },
        updateRadius(radius) {
            let intRadius = parseInt(radius, 10)
            this.circle.setRadius(intRadius)
        },
        nextLocation() {
            this.loadingAddress = true
            // send up the current crop + radius
            this.$emit("updateCrop", this.crop, parseInt(this.radius))
            // send up request to go to next address
            this.$emit("nextAddress")
        },
        editAddress() {
            this.$emit("editAddress")
        }

    },
    mounted: async function () {
        try {
            const google = await this.initMap();
            let lat = this.crop.latitude
            let lon = this.crop.longitude
            this.map = new google.maps.Map(this.$refs.map);
            this.map.setZoom(17);
            this.addMapMarker(lat, lon, this.map, this.radius, this.crop.address)
        } catch (error) {
            console.error(error);
        }
        },

    template:
        `
        <div class="row">
            <div class="col" id="map" ref="map" style="width: 75vw;height: 70vh;"></div>
            <div class="col" v-if="!exporting">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">{{ crop.address }}</h5>
                        <p class="card-text"></p>
                  
                <form>
                    <div class="form-group">
                        <label for="radius">Radius (in meters)</label>
                        <input type="number" class="form-control" id="radius" v-model="radius" @input="updateRadius(radius)">
                    </div>
                </form>
                <button class="btn btn-primary" @click="nextLocation" :disabled="loadingAddress">Next location</button>
                <button class="btn btn-secondary" @click="editAddress" :disabled="loadingAddress">Edit address</button>
                        </div>
                </div>
            </div>
        </div>
        `,
}

export { GoogleMap }