import Vue from 'vue';
import ApiService from "../../common/api.service.js";

let VideoManagementTab = {
    data(){
        return {
            currentPage: 1
        }
    },
    props: {
        store: Object
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.store.updatePageNumber(value)
                this.$nextTick(() => {
                    this.getPaginatedVideoAssetGroups()
                })
            }
        }
    },
    mounted: function () {
        this.$emit('getPaginatedVideoAssetGroups');
    },
    methods: {
        selectTempAssetGroup(assetGroup) {
            this.store.updateTempAssetGroup(assetGroup);
        },
        getPaginatedVideoAssetGroups() {
            this.$emit('getPaginatedVideoAssetGroups')
        },
    },
    template: 
    ` 
    <div class="container d-flex flex-column justify-content-between">
        <div v-if="store.state.dataReady" class="video-management-tab-content row pb-5 px-3">
            <div class="col" v-if="store.videoAssetGroupsExist">
                <div class="row mb-3 mt-3">
                    <div class="input-group d-flex col-6 pl-0">
                        <input v-on:keyup.enter="getPaginatedVideoAssetGroups()" v-model="store.search" placeholder="Search by name" type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" style="flex-grow: 1;">
                        <div class="input-group-append align-self-start">
                            <button @click="getPaginatedVideoAssetGroups()" type="button" class="btn btn-primary">
                                <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                            </button>
                        </div>
                    </div>
                    <div class="d-flex paginator-wrapper justify-content-end" style="flex-grow: 1;">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="store.assetGroupsCount"
                        :per-page="12"
                        first-text="First"
                        last-text="Last"
                        hide-ellipsis
                        limit="4"
                        ></b-pagination>
                    </div>
                </div>  
                <div v-if="store.assetGroups.length" class="row justify-content-start">
                    <component 
                    :is="'video-asset-card'"
                    v-for="assetGroup in store.assetGroups"
                    :assetGroup="assetGroup"
                    :preset_asset_thumbnail="store.preset_asset_thumbnail"
                    :key="assetGroup.id"
                    :store="store"
                    v-on:update="selectTempAssetGroup"
                    v-on:refresh="getPaginatedVideoAssetGroups"
                    />
                </div>
                <div class="mx-2 mt-2" v-else>
                    <p>No video assets with the name "{{ store.searchQuery }}"</p>
                </div>
            </div>
            <div class="w-100 text-center align-self-center mt-5" v-else>
                <p>No video assets exist for video background</p>
            </div>
        </div>
        <div v-else>
            <p>Loading...</p>
        </div>
        <div class="button-row row justify-content-end my-4 mr-2">
            <b-button
            v-if="store.tempAssetGroup" 
            class="select-asset-group-btn mr-3"
            variant="primary"
            @click="store.onSelectBackgroundVideo()"
            :disabled="!store.isTempAssetGroupDifferentFromAssetGroup()"
            >
                Select
            </b-button>
            <b-button
            variant="outline-secondary"
            @click="store.cancelAndCloseModal()"
            >
                Cancel
            </b-button>
        </div>
    </div>
   `
}

// Assets display in modal
Vue.component("video-asset-card", {
    props: {
        assetGroup: Object,
        preset_asset_thumbnail: String,
        store: Object
    },
    computed: {
        activeClass() {
          const { tempAssetGroup, assetGroup } = this.store;
          const isActive = tempAssetGroup?.id === this.assetGroup.id || (!tempAssetGroup?.id && assetGroup?.id === this.assetGroup.id);
          return isActive ? 'active-selection' : '';
        }
    },
    methods: {
        confirmDelete(asset_group_id, event) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this asset?').then((result) => {
                if (result) {
                    ApiService.delete(`${this.store.website}/asset_groups`, asset_group_id).then((response) => {
                        this.$emit("refresh")
                    })
                }
            })
          },
    },
    template:
        `
        <div class="content__asset d-flex flex-column col-3 my-2 mx-1 align-items-center rounded border" :class="[activeClass, {'disabled': assetGroup.deleted}]" v-on:click="$emit('update', assetGroup)">
            <span class="circled-number-top-right">{{assetGroup.asset_count}}</span>
            <img class="content__product-image pt-3" :src="store.getThumbnail(assetGroup) || preset_asset_thumbnail">
            <div class="asset-group-title py-2 text-left align-self-start font-weight-bold"> {{ assetGroup.name }} </div>
            <button @click="confirmDelete(assetGroup.id,$event)" class="transparent-btn fas fa-trash"></button>
        </div>
    `,
});

export { VideoManagementTab }