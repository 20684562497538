import ApiService from '../../common/api.service.js'

let ContactForm = {
    data() {
        return {
          form: {
            email: '',
            name: '',
            description:'',
          },
          isSubmitted: false,
          message: ''
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            let formData= {
                "email": this.form.email,
                "name": this.form.name,
                "description": this.form.description,
            }
            if (this.email) {
                formData.email = this.email
                formData.name = this.name
            }

            ApiService.post("contact-us", formData).then(response => {
                this.isSubmitted = true
            })

        },
    },
    props: {
        email: String,
        name: String,
        staticUrl: String
    },
    computed: {
        isUser() {
            return this.email
        }
    },
    template: `
    <div id="contact-form" class="d-flex flex-nowrap align-items-center" :class="isSubmitted ? 'mt-5 pt-5' : ''">
        <div class="w-50 pr-5">
            <img :src="staticUrl + 'img/adretriever-dog-full.png'" class="hero-image">
        </div>
        <div class="w-50 pr-3">
            <div class="form-wrapper bg-white default-theme-border-radius mt-0 px-5" :class="isUser ? 'mt-5 p-5' : 'pt-2 pb-1'">
                <b-form v-if="!isSubmitted" @submit="onSubmit">
                    <h1 class="text-center eggplant-color font-weight-bold mb-0 mt-5">Contact Us</h1>
                    <p class="form-subtitle eggplant-color text-center mt-0 mb-5">Questions or comments?</br>Send us a message and we'll get back to you.</p>
                    <b-form-group>
                        <div v-if="!isUser">
                            <label for="name">Name</label>
                            <b-form-input
                            id="name"
                            class="mb-3"
                            v-model="form.name"
                            label="Name"
                            required
                            trim
                            ></b-form-input>
                            <label for="email">Email</label>
                            <b-form-input
                            id="email"
                            class="mb-3"
                            v-model="form.email"
                            type="email"
                            required
                            ></b-form-input>
                        </div>
                        <label for="description">Message</label>
                        <b-form-textarea
                        id="description"
                        class="mb-3"
                        v-model="form.description"
                        rows="2"
                        required
                        trim
                        ></b-form-textarea>
                    </b-form-group>
                    <b-button type="submit" size="sm" variant="primary" class="mt-4 px-5 py-2 d-block mx-auto mb-5">Submit</b-button>
                </b-form> 
                <div v-else class="py-5 px-2 mt-5">
                    <h4 class="text-center">Thanks! We'll be in touch soon!</h4>
                    <p class="text-center"><a href="/">Go back home</a></p>
                </div>
            </div>
        </div>
    </div>
    `
    }
    
    export {ContactForm}