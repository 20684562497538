import Vue from 'vue';
import VueFormulate from "@braid/vue-formulate";
import {AdsService, ThemeVersionsService} from './common/api.service.js';
import Masonry from 'masonry-layout';
import { debounce } from 'lodash';

Vue.use(VueFormulate);

let AdEditHtmlAdPreview = {
    data: function () {
        return {
            isSaving: false,
            // The true template ID, selected in the UI
            template_id: undefined,
            // A mediated template ID that we update selectively, because most of our themes don't actually change
            // markup between sizes, so there's no need to trigger a full refresh.
            previewTemplateId: null,
            sizes: {},
            previewURL: '',
            secondPreviewURL: '',
            _width: null,
            timeout: 1000,
            showingFirstIframe: true,
            static_url: adretriever.STATIC_URL,
            isLoadingAd: false,
            clearOldIframeTimeoutFunction: null
        }
    },

    props: {
        store: Object,
        base_preset_preview_url: String,
        returnURL: String,
        website: String,
        isShowTitleAndRefresh: {
            type: Boolean,
            default: true
        }
    },

    mounted: function () {
        // Initialize Masonry
        var masonry = new Masonry('#masonry-container', {
          itemSelector: '.ad-iframe',
          columnWidth: 160,
          horizontalOrder: true
        });
        this.getSizes();

    },

    methods: {
        async getSizes() {
            this.toggleLoadingAd();
            this.resetSizes();
            this.populateSizes(this.store.ad.theme_config.theme_version);
            this.sortSizes();
        },

        toggleLoadingAd() {
            if (!this.isLoadingAd) this.isLoadingAd = true;
        },

        isVersionMismatch(theme) {
            return theme.number !== theme.latest_version;
        },


        resetSizes() {
            if (this.sizes != {}) this.sizes = {};
        },

        populateSizes(theme) {
            if(theme == null){
                return
            }
            this.sizes = {};

            theme.templates.forEach(template => {
                this.sizes[template.id] = template.size;
            });
            this.template_id = this.previewTemplateId =  theme.templates[0]?.id;
        },

        sortSizes() {
            this.sizes = Object.fromEntries(
                Object.entries(this.sizes).sort((a, b) => a[1].localeCompare(b[1]))
            );
        },

        forceRefresh() {
            // Ensure the template ID for the preview URL is up to date with the UI
            this.previewTemplateId = this.template_id;
            // Add a random custom field which should be enough to change the preview URL
            this.store.updateCustomField('random', (Math.random()*100000).toFixed(0).toString());
            this.isLoadingAd = true
        },
        height: function(size) {
            return size.split('X')[1].trim();
        },
        width: function(size) {
            return size.split('X')[0].trim();
        },

        // TODO Improve the logic to prevent unstable logic
        // and we should set up store to deceied if the url should update or not

        // update preview iframe
        updatePreviewIframe(newVal, oldVal) {
            //check if only preview size changed
            // let newTempID = newVal.split("&")
            // let oldTempID = oldVal ? oldVal.split("&") : null

            //only trigger loading if not only size changing
            // if(!oldTempID || newTempID[0] == oldTempID[0]) {
            // trigger loading animation
            if(!this.isLoadingAd) this.isLoadingAd = true;
            // }
            if(this.showingFirstIframe)
            {
                // if the first iframe is showing, assign newVal to secondPreviewURL
                this.updateIframeDisplay("secondPreviewURL", newVal, "previewURL");
            }else{
                // otherwise we will show first iframe by assigning newVal to previewURL
                this.updateIframeDisplay("previewURL", newVal, "secondPreviewURL");
            }
        },
        // update the display of iframe
        updateIframeDisplay(targetPreviewUrlName, newValForTarget, anotherPreviewUrlName){
            // prevent multiple refresh before setTimeout done
            // if a timeout is running already, cancel the old one
            if(this.clearOldIframeTimeoutFunction) {
                clearTimeout(this.clearOldIframeTimeoutFunction);
                // reset varibale to null
                this.clearOldIframeTimeoutFunction = null;
            }

            this[targetPreviewUrlName] = newValForTarget;

            // delay update to have approximate wait for content to load and keep old preview
            this.clearOldIframeTimeoutFunction = setTimeout(function(){
                this.displayIframe();
                this.isLoadingAd = false;
                // reset timeout to null as finished timeout will still have value
                this.clearOldIframeTimeoutFunction = null;
                // make sure the new iframe is shown before clearing url to prevent few sec blank
                // setTimeout(()=>{
                //     this.clearPreviewUrlValue(anotherPreviewUrlName);
                // }, 10000);
            }.bind(this), this.timeout);
        },
        // trigger transition to new preview iframe
        displayIframe() {
            this.showingFirstIframe = !this.showingFirstIframe
        },
        // reset any previewURL that has value to clear hidden iframe to save resources
        clearPreviewUrlValue(previewUrlName) {
            this[previewUrlName] = ""
        },
    },

    watch: {
        presetPreviewURL: debounce(function (newURL, oldURL) {
            this.updatePreviewIframe(newURL, oldURL);
        }, 500),
        'store.ad.theme_config.theme_version.id': function(newVal, oldVal) {
            this.getSizes();
        },
        template_id: function (newVal, oldVal) {
            if (this.previewTemplateId === null) {
                this.previewTemplateId = newVal;
            }
        },
    },

    computed: {
        presetPreviewURL: function() {
		    return this.previewTemplateId ? this.store.createPreviewURL(this.base_preset_preview_url, this.previewTemplateId, this.store.ad.theme_config.theme_version.id) : null
        },
        // Only add margin for large size ad preview will blocking by the size selection area
        isHeightExceed: function () {
            if(this.$refs.parentContainer)  {
                return this.height > this.$refs.parentContainer.clientHeight
            }else{
                //give a default value in case the parent container is not loaded
                return this.height > 650
            }
        },
        // Control class when ad preview width is wider than parent container
        isWidthExceed: function () {
            if(this.$refs.parentContainer)  {
                return this.width > this.$refs.parentContainer.clientWidth
            }else{
                //give a default value in case the parent container is not loaded
                return this.width > 650
            }
        },
    },

    template:
        `
    <div ref="parentContainer">
      <div v-if="isShowTitleAndRefresh" class="w-75 h-100 ml-1 p-2 eggplant-color">
                <h5 class="p-0 d-inline-block">Ad Preview</h5>
                <button type="button" class="btn refresh-button p-0 mb-2 ml-3" @click="forceRefresh()" :disabled="isLoadingAd">
                    <font-awesome-icon class="eggplant-color" :icon="['fas', 'arrows-rotate']" />
                </button>
      </div>
      <div class="row mx-2 pr-3" id="masonry-container">
        <div class="p-2 masonry-item" v-for="(size, template_id) in sizes" :key="template_id">
          <div class="iframe-container" :style="'width: '+ width(size) + 'px; height: '+ height(size) + 'px'">      
            <iframe class="ad-iframe position-absolute"
                :class="showingFirstIframe ? 'show' : 'hide'"
                v-bind:width="width(size)"
                v-bind:height="height(size)"
                v-bind:src="previewURL"
                scrolling="no"
                frameborder="0">
                </iframe>
            <iframe class="ad-iframe position-absolute"
                :class="!showingFirstIframe ? 'show' : 'hide'"
                v-bind:width="width(size)"
                v-bind:height="height(size)"
                v-bind:src="secondPreviewURL"
                scrolling="no"
                frameborder="0">
                </iframe>
            <div  
            :class="isLoadingAd ? 'show' : 'hide'" 
            class="ad-overlay my-2 position-absolute d-flex align-items-center justify-content-center" 
            :style="'width: '+ width(size) + 'px; height: '+ height(size) + 'px'"
            >
                <div class="spinner-grow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    `
}

export {AdEditHtmlAdPreview};
